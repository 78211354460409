import React from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';
import { workspaceSelectedFromTable } from 'Screens/Workspaces/actions/Actions';
import { ColWorkspaceName } from 'Screens/Workspaces/components/Table/styled';
import useDoubleClick from 'Hooks/useDoubleClick';

const ColumnName = ({
  workspaceName, vulns, handleSingleClick, workspaceActive
}) => {
  const dispatch = useDispatch();

  const workspaceSelected = () => {
    if (workspaceActive) dispatch(workspaceSelectedFromTable(workspaceName, vulns));
  };

  const singleClickCallback = (e) => {
    handleSingleClick(e);
  };

  const [refCallback] = useDoubleClick(workspaceSelected, singleClickCallback);

  return (
    <ColWorkspaceName ref={ refCallback }>
      {workspaceName}
    </ColWorkspaceName>

  );
};

ColumnName.propTypes = {
  workspaceName: PropTypes.string.isRequired,
  vulns: PropTypes.number.isRequired,
  handleSingleClick: PropTypes.func.isRequired,
  workspaceActive: PropTypes.bool.isRequired
};

export default ColumnName;
