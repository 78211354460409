import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 192px;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  right: -192px;
  text-align: left;
  padding: 8px 0;
  z-index: 99;

  border-radius: 3px;
  box-shadow: 0 2px 50px 0 rgba(28, 34, 67, 0.1);
`;

export const SideBarSubMenuItem = styled.div`
  cursor: pointer;
  height: 34px;
  padding-left: 23px;
  display: ${(props) => (props.hidden ? 'hidden' : 'flex')};
  align-items: center;
  font-size: 13.5px;
  
  &:hover {
  background-color: #d9e4ef;
  }

  ${({ selected }) => selected &&
  `
      font-weight: 600;
  `}
`;

export const SubMenuHeader = styled(SideBarSubMenuItem)`
 background-color: #63758d;
 color: #fafbfc;
 font-weight: bold;
 margin-top: -8px;
 cursor: default;
 
 &:hover {
  background-color: #63758d;
 }
`;
