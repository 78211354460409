import styled from 'styled-components';
import colors from 'Styles/colors';
import edit from 'Images/icon-edit.png';

export const Wrapper = styled.div`
  font-size: 29px;
  font-weight: 600;
  line-height: 0.97;
  color: ${colors.dark2};
  text-align: left;
  margin: 17px 0;
  display: flex;
  align-items: center;
  justify-content: left;
`;
Wrapper.displayName = 'Wrapper';

export const HostIp = styled.div`
  max-width: 92%;
  overflow-wrap: break-word;
`;
HostIp.displayName = 'HostIp';

export const Edit = styled.div`
  width: 19px;
  height: 18px;
  background: url(${edit}) no-repeat;
  cursor: pointer;
  margin: 0 10px;
`;
Edit.displayName = 'Edit';
