import React from 'react';
import {
  Wrapper, Title, Description, Logo, Content
} from './styled';

const Card = ({
  image, title, description, toolbar
}) => (
  <Wrapper>
    <Logo src={ image } />
    <Content>
      <Title>{ title }</Title>
      <Description>{ description }</Description>
      { toolbar }
    </Content>
  </Wrapper>
);
export default Card;
