import React from 'react';
import {
  Wrapper, Critical, High, Medium
} from './styled';

const Severity = ({ value, className }) => {
  const critical = value.critical || 0;
  const high = value.high || 0;
  const medium = value.med || 0;

  return (
    <Wrapper className={ className }>
      <Critical width={ critical }>{critical}</Critical>
      <High width={ high }>{high}</High>
      <Medium width={ medium }>{medium}</Medium>
    </Wrapper>
  );
};

export default Severity;
