import React from 'react';
import ModalWrapper from 'Common/Components/ModalWrapper';
import { PropTypes } from 'prop-types';
import {
  ModalTitle, Title, BodyText, ModalContent, ButtonsContainer,
  ConfirmButton, CancelButton, Warning, ModalBody
} from './styled';

const ModalConfirmation = ({
  show, handleSubmit, handleClose, entity, count
}) => {
  const upperCaseTitle = entity.charAt(0).toUpperCase() + entity.slice(1);

  if (!show) return null;
  return (
    <ModalWrapper>
      <ModalContent>
        <ModalTitle>
          <Warning />
          <Title>{`Delete ${upperCaseTitle}(s)`}</Title>
        </ModalTitle>
        <ModalBody>
          <BodyText>{`You’re deleting ${count} ${upperCaseTitle}(s). This action cannot be undone. Are you sure you want to proceed?`}</BodyText>
        </ModalBody>
        <ButtonsContainer>
          <CancelButton onClick={ handleClose }>
            Cancel
          </CancelButton>
          <ConfirmButton onClick={ handleSubmit }>
            Delete
          </ConfirmButton>
        </ButtonsContainer>
      </ModalContent>
    </ModalWrapper>
  );
};

ModalConfirmation.propTypes = {
  show: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  entity: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired
};

ModalConfirmation.defaultProps = {
  show: true
};

export default ModalConfirmation;
