import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FILE_SELECTOR } from 'Screens/Wizard/constants';
import {
  Title, SubTitle
} from 'Common/Components/MainContainer/styled';
import CustomCheckbox from 'Common/Components/CustomCheckbox';
import { workspaceSelectedForWorking } from 'Screens/Faraday/actions/Actions';
import { selectAutoSelectWs } from 'store/Preferences/workspace/selectors';
import { redirect } from 'store/Router/actions';
import SearchWs from './components/SearchWs';
import { selectWorkspace, toggleAutoSelectWs } from '../../actions/Actions';
import {
  Wrapper,
  WsSelect,
  WsItem,
  CheckWrapper,
  CheckText,
  WsName
} from './styled';

const StepWorkspaceSelection = ({ onStep }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.sesion.user);
  const autoSelectWs = useSelector((state) => selectAutoSelectWs(state));
  const workspaces = useSelector((state) => state.faraday.workspaces);

  const selectWorkSpace = (ws) => {
    dispatch(selectWorkspace(ws.name));
    if (ws.stats.total_vulns > 0) {
      dispatch(workspaceSelectedForWorking(ws.name));
      dispatch(redirect(`/feed/${ws.name}`));
    } else {
      dispatch(workspaceSelectedForWorking(ws.name));
      onStep(FILE_SELECTOR);
    }
  };

  const wsList = workspaces.filter((ws) => ws.active);
  const isWsListLong = wsList.length > 3;

  return (
    <Wrapper>
      <SubTitle>{`Welcome ${user}`}</SubTitle>
      <Title className="margin-top-14 margin-bottom-24">Select a Workspace</Title>
      <SearchWs data={ wsList } onSelect={ selectWorkSpace } />
      {
        wsList.length > 0
          ? (
            <WsSelect isWsListLong={ isWsListLong }>
              {
                wsList.map((ws) => (
                  <WsItem key={ `ws_${ws.name}` } onClick={ () => { selectWorkSpace(ws); } }>
                    <WsName title={ ws.name }>{ws.name}</WsName>
                  </WsItem>
                ))
              }
            </WsSelect>
            )
          : null
      }

      <CheckWrapper>
        <CustomCheckbox isChecked={ autoSelectWs } handleCheckboxChange={ () => { dispatch(toggleAutoSelectWs(!autoSelectWs)); } } label="" id="test_id_auto_select_we" />
        <CheckText onClick={ () => { dispatch(toggleAutoSelectWs(!autoSelectWs)); } }>Always log me into my last used Workspace</CheckText>
      </CheckWrapper>
    </Wrapper>
  );
};

export default StepWorkspaceSelection;
