import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import {
  StyledCheckbox
} from './styled';

const Checkbox = ({
  onChange,
  state,
  theme,
  partialChecked
}) => {
  const [check, setCheck] = useState(state);
  useEffect(() => {
    setCheck(state);
  }, [state]);
  const handleChange = (e) => {
    onChange && onChange(e);// eslint-disable-line
    setCheck(!check);
  };
  return (
    // Since DOM rejects boolean values of not recognized props, added $ on '$partialChecked' to convert it to a transient prop to the styled component
    <StyledCheckbox readOnly $partialChecked={ partialChecked } checked={ check } theme={ theme } onClick={ handleChange } />
  );
};

Checkbox.propTypes = {
  onChange: PropTypes.func,
  state: PropTypes.bool,
  theme: PropTypes.string,
  partialChecked: PropTypes.bool
};

Checkbox.defaultProps = {
  onChange: null,
  state: false,
  theme: 'green',
  partialChecked: false
};

export default Checkbox;
