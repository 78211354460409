import React from 'react';
import { Title, Text, Br } from './styled';

export const manageTourConfig = [
  {
    selector: '.sjs',
    content: ' '
  },
  {
    selector: '[data-tour="Add Vulnerability"]',
    content: () => (
      <div>
        <Title>Create or Upload Vulnerabilities </Title>
        <Text>Upload vulnerabilities from your favourite tools with a .csv file or create them manually.</Text>
      </div>
    ),
    position: 'bottom',
    style: {
      boxShadow: '0 2px 50px 0 rgba(28, 34, 67, 0.15)'
    }
  },
  {
    selector: '[data-tour="Assets"]',
    content: () => (
      <div>
        <Title>Manage Vulnerabilities and Assets</Title>
        <Text>
          You can easily
          {' '}
          <Br>add, organize and edit</Br>
          {' '}
          vulnerabilities and assets by switching between tabs.
        </Text>
      </div>
    ),
    position: 'right'
  },
  {
    selector: '.test-table-selector',
    content: () => (
      <div>
        <Title>Vulnerabilities</Title>
        <Text>
          Click to select
          <br />
          Double click to see its details
          <br />
          Cmd/Ctrl or Shift + click to select multiple.
          <br />
          Right click to display the quick access to actions menu.
        </Text>
      </div>
    ),
    position: 'bottom'
  },
  {
    selector: '[data-tour="Automation"]',
    content: () => (
      <div>
        <Title>Create Automations for your work</Title>
        <Text>
          Create
          {' '}
          <Br>Workflows</Br>
          {' '}
          and
          {' '}
          <Br>Agents</Br>
          {' '}
          to speed up repetitive tasks and
          {' '}
          <Br>Schedule</Br>
          {' '}
          Scans and Jobs.
        </Text>
      </div>
    ),
    position: 'right'
  },
  {
    selector: '[data-tour="Reports"]',
    content: () => (
      <div>
        <Title>Reporting made easy</Title>
        <Text>Create professional reports thanks to our Knowledge Base and export all your findings into compliance-ready formats.</Text>
      </div>
    ),
    position: 'right'
  }
];

export const dashboardTourConfig = [
  {
    selector: '.sjs',
    content: ' '
  },
  {
    selector: '[data-tour="Activity Dashboard"]',
    content: () => (
      <div>
        <Title>Welcome to Faraday’s Feed!</Title>
        <Text>
          You can see all your vulnerabilities and statistics here.
          If you haven’t uploaded or scanned anything yet, start
          by  uploading some files in our Manage tab.
        </Text>
      </div>
    ),
    position: 'right',
    style: {
      boxShadow: '0 2px 50px 0 rgba(28, 34, 67, 0.15)'
    }
  }, {}
];
