import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ActionBar from 'Common/Components/ActionBar';
import CreateActions from 'Screens/Manage/components/CreateActions';
import EditColumnContext from 'Common/Components/EditColumnContext';
import IconButton from 'Common/Components/IconButton';
import ContextMenuButton from 'Common/Components/ContextMenuButton';
import selectModal from 'store/modals/selectors';
import { MODAL_CUSTOM_ATTRIBUTES, MODAL_UPGRADE_LICENSE_ACTION_BAR } from 'store/modals/modals';
import { resetDefault, setFieldVisibility, setNewOrderColumns } from 'store/Preferences/manage/actions';
import {
  showVulnModalDelete,
  hideVulnModalDelete,
  deleteSelectedVulns,
  downloadVulns,
  redirectToVulnDetail
} from 'store/Manage/actions';
import { selectPage, selectRowsPerPage, selectGroupByField } from 'store/Filters/selectors';
import { selectRoutes } from 'store/Manage/selectors';
import { selectManageFields } from 'store/Preferences/manage/selectors';
import VulnFlagFilter from 'Common/Components/VulnFlagFilter';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { setPage } from 'store/Manage/filterActions';
import get from 'lodash/get';
import CustomAttributesModal from 'Screens/Manage/components/CustomAttributesModal';
import OpenContextMenuButton from './components/OpenContextMenu';
import GroupFieldSelector from './components/GroupFieldSelector';
import FilterSideBarIcon from './components/FilterSidebarIcon';
import {
  EditColumns, Trash, Edit, Download
} from './styled';
import DuplicateButton from './components/DuplicateButton';
import TagButton from './components/TagButton';
import ReduxModal from 'Common/Components/ReduxModal';
import UpgradeLicense from 'Common/Components/UpgradeLicense';

const CustomActionBar = () => {
  const [FFImage, setFFImage] = useState(null);
  const [FFDescription, setFFDescription] = useState('');
  const [showContext, toggleContext] = useState(false);
  const dispatch = useDispatch();
  const workspaceSelected = useSelector(selectCurrentWorkspace);
  const readonly = useSelector((state) => get(state, 'faraday.readonly', false));
  const fields = useSelector(selectManageFields);
  const routeList = useSelector(selectRoutes);
  const vulnsSelected = useSelector((state) => get(state, 'manage.vulnsSelected', []));
  const showVulnDetail = useSelector((state) => get(state, 'manage.showVulnDetail', false));
  const showDeleteConfirmation = useSelector((state) => get(state, 'manage.showDeleteConfirmation', false));
  const vulnsCount = useSelector((state) => get(state, 'manage.vulnsCount', 0));
  const page = useSelector((state) => selectPage('vulns', state));
  const rowsPerPage = useSelector((state) => selectRowsPerPage('vulns', state));
  const isGrouping = useSelector((state) => selectGroupByField('vulns', state));
  const showCustomAttributesModal = useSelector((state) => selectModal(state, MODAL_CUSTOM_ATTRIBUTES));

  let message = '';

  if (showDeleteConfirmation) {
    if (vulnsSelected.length === 1) { message = 'A vulnerability will be deleted. This operation cannot be undone. Are you sure you want to proceed?'; } else { message = `${vulnsSelected.length} vulnerabilities will be deleted. This operation cannot be undone. Are you sure you want to proceed?`; }
  }

  const addRemoveColumns = (
    <EditColumnContext
      trigger="action-button-column"
      fields={ fields }
      toggle={ () => toggleContext(!showContext) }
      setFieldVisibility={ (fieldObj, value) => dispatch(setFieldVisibility(fieldObj, value)) }
      setNewOrderColumns={ (columns) => dispatch(setNewOrderColumns(columns)) }
      resetDefault={ () => dispatch(resetDefault()) }
    />
  );

  // TODO: Separate in components appart
  const showButton = (!readonly && vulnsSelected.length > 0 && !showVulnDetail);
  const deleteButton = showButton ? <IconButton icon={ <Trash /> } title="Delete" onClick={ () => dispatch(showVulnModalDelete()) } /> : null;
  const editButton = showButton ? <IconButton icon={ <Edit /> } title="Edit" onClick={ () => dispatch(redirectToVulnDetail(vulnsSelected[0]._id)) } /> : null;
  const downloadButton = <IconButton icon={ <Download /> } title="Download Filtered Workspace" onClick={ () => dispatch(downloadVulns()) } />;
  const moreOptionsButton = <OpenContextMenuButton display={ showButton && vulnsSelected.length > 0 && !showVulnDetail } />;

  const addRemoveColumnsButton = (
    <>
      { showCustomAttributesModal && <CustomAttributesModal /> }
      <ContextMenuButton
        opened={ showContext }
        menu={ addRemoveColumns }
        trigger="action-button-column"
        display={ !showVulnDetail }
        icon={ <IconButton icon={ <EditColumns /> } title="Columns" /> }
      />
    </>
  );

  const setFF = (image, description) => {
    setFFImage(image);
    setFFDescription(description);
  };

  return (
    <>
      <ReduxModal id={ MODAL_UPGRADE_LICENSE_ACTION_BAR }>
        <UpgradeLicense id={ MODAL_UPGRADE_LICENSE_ACTION_BAR } image={ FFImage } description={ FFDescription } />
      </ReduxModal>
      <ActionBar
        isGrouping={ isGrouping }
        showDeleteConfirmation={ showDeleteConfirmation }
        messageDeleteConfirmation={ message }
        handleSubmitDelete={ () => dispatch(deleteSelectedVulns()) }
        handleCloseDelete={ () => dispatch(hideVulnModalDelete()) }
        workspaceSelected={ workspaceSelected }
        groupFieldSelector={ vulnsSelected.length === 0 && !showVulnDetail ? <GroupFieldSelector /> : null }
        createActions={ !readonly && <CreateActions /> }
        deleteButton={ deleteButton }
        editButton={ editButton }
        moreOptionsButton={ moreOptionsButton }
        duplicateButton={ <DuplicateButton setFF={ setFF } /> }
        addRemoveColumns={ addRemoveColumnsButton }
        downloadButton={ downloadButton }
        routeList={ routeList }
        entity="vulnerability"
        count={ vulnsSelected.length }
        tagButton={ <TagButton setFF={ setFF } /> }
        totalRows={ vulnsCount }
        vulnFilter={ <VulnFlagFilter /> }
        page={ page }
        rowsPerPage={ rowsPerPage }
        onPrev={ () => dispatch(setPage(page - 1)) }
        onNext={ () => dispatch(setPage(page + 1)) }
        sidebarFilters={ <FilterSideBarIcon entity="vulns" /> }
      />
    </>
  );
};

export default CustomActionBar;
