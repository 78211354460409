/* eslint-disable react/jsx-key */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hideErrorMessageAttachement } from 'store/Manage/actions';
import {
  selectReadOnly, selectPermissions, selectEvidences,
  selectAttachments, selectAttachmentError
} from 'store/Manage/selectors';
import UploadEvidence from './components/UploadEvidence';
import Attachment from './components/Attachment';
import Wrapper from './styled';

const EvidenceTab = ({ isEditing }) => {
  const dispatch = useDispatch();
  const evidences = useSelector((state) => (isEditing ? selectAttachments(state) : selectEvidences(state)));
  const readOnly = useSelector(selectReadOnly);
  const permissions = useSelector(selectPermissions);
  const allowed = isEditing || permissions.update.allowed;
  const attachmentError = useSelector(selectAttachmentError);

  useEffect(() => {
    if (attachmentError && !isEditing) {
      setTimeout(() => {
        dispatch(hideErrorMessageAttachement());
      }, 4000);
    }
  }, [dispatch, attachmentError, isEditing]);

  return (
    <Wrapper>
      {!readOnly && allowed ? <UploadEvidence isEditing={ isEditing } /> : null}
      {Object.keys(evidences).map((key) => <Attachment evidence={ evidences[key] } name={ key } isEditing={ isEditing } />)}
    </Wrapper>
  );
};

export default EvidenceTab;
