import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import { selectVulnDetail } from 'store/Manage/selectors';
import ExpandableWrapper from '../ExpandableWrapper';
import OwnerWrapper from './styled';

const Owner = () => {
  const intl = useIntl();
  const currentVuln = useSelector(selectVulnDetail);
  const owner = get(currentVuln, 'owner', '');

  return (
    <ExpandableWrapper id="owner" title={ intl.formatMessage({ id: 'manage.detail.tab.general.owner' }) }>
      <OwnerWrapper children={ owner } />
    </ExpandableWrapper>
  );
};

export default Owner;
