import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
    width: 100%;
    height: 84px;
    display: flex;
    justify-content: center;
    background-color: ${colors.white};
    padding: 34px 314px 34px 352px;
    border-radius: 2px;
    box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.03);
    margin-top: 20px;
`;
Wrapper.displayName = 'Wrapper';

export const Text = styled.p`
    font-size: 13.5px;
    color: #a6bbce;
`;
Text.displayName = 'Text';

export const Link = styled.a`
    font-size: 13.5px;
    color: ${colors.blueCerulean};
    cursor: pointer;
    text-decoration: none;
`;
Text.displayName = 'Text';
