import React from 'react';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectTools } from 'store/Agents/selectors';
import { Help, Here } from './styled';

const Website = ({ currentTool }) => {
  const tools = useSelector(selectTools);
  const currentToolName = get(currentTool, 'name', '');
  const storedTool = tools.find((t) => t.name === currentToolName);
  const toolWebsite = get(storedTool, 'website', '');
  const intl = useIntl();
  const helpMessage = intl.formatMessage({ id: 'agents.triggerAgent.tool.helpMessage' });
  const hereMessage = intl.formatMessage({ id: 'agents.triggerAgent.tool.webMessage' });

  if (!toolWebsite) return null;
  return (
    <Help>
      {helpMessage}
      <Here target="_blank" href={ toolWebsite }>{hereMessage}</Here>
    </Help>
  );
};

export default Website;
