/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from 'Screens/Host/components/Table';
import ActionBar from 'Common/Components/ActionBar';
import HostModalEditCreate from 'Screens/HostEditCreate/components/HostModalEditCreate';
import {
  toggleModalEditCreate,
  showHostModalDelete,
  hideHostModalDelete,
  deleteHostsSelected,
  setPageNumberAssets,
  getData
} from 'store/Host/actions';
import {
  showHostDetail,
  hideHostDetail,
  showHostDetailById
} from 'store/HostDetail/actions';

import {
  setFieldVisibility,
  setNewOrderColumns,
  resetDefault
} from 'store/Preferences/host/actions';
import { connect } from 'react-redux';
import EditColumnContext from 'Common/Components/EditColumnContext';
import HostDetail from 'Screens/Host/components/HostDetail';
import GroupBy from 'Screens/Host/components/ActionBar/components/GroupBy';
import { selectShowHostDetail } from 'store/HostDetail/selectors';
import { selectRoutes } from 'store/Manage/selectors';
import {
  selectHostCount,
  selectHostsSelected,
  selectShowDeleteConfirmation,
  selectAddColumnsOpened,
  selectShowModalEditCreate,
  selectShowHostLeftFilters
} from 'store/Host/selectors';
import CreateActions from 'Screens/Host/components/CreateActions';
import ContextMenuButton from 'Common/Components/ContextMenuButton';
import DeleteButton from 'Common/Components/DeleteButton';
import EditButton from 'Common/Components/EditButton';
import get from 'lodash/get';
import { selectFields } from 'store/Preferences/host/selectors';
import IconButton from 'Common/Components/IconButton';
import { selectPage, selectRowsPerPage } from 'store/Filters/selectors';
import FilterSideBar from 'Screens/Manage/components/FilterSideBar';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import FilterSideBarIcon from 'Screens/Manage/components/CustomActionBar/components/FilterSidebarIcon';
import { closeModal } from 'store/modals/actions';
import ReduxModal from 'Common/Components/ReduxModal';
import { MODAL_CREATE_SERVICE } from 'store/modals/modals';
import AddEditService from './components/HostDetail/Services/components/AddEditService';
import { Wrapper, EditColumns, TableContainer } from './styled';

class Host extends Component {
  constructor (props) {
    super(props);
    this.state = {
      addRemoveColumnsOpened: false
    };
    this.actionBar = React.createRef();
    this.getTableContainerHeight = this.getTableContainerHeight.bind(this);
  }

  handleParamId () {
    const id = get(this, 'props.params.id', 0);
    if (parseInt(id, 10) > 0) this.props.showHostDetailById(id);
    else this.props.hideHostDetail();
  }

  componentDidMount () {
    this.handleParamId();
  }

  componentWillUnmount () {
    this.handleParamId();
  }

  componentDidUpdate (prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.handleParamId();
      this.props.getData();
    }
  }

  getTableContainerHeight () {
    const actionBarHeight = get(this.actionBar, 'current.clientHeight', 34);
    const tableContainerTopMargin = 0;
    return `calc(100% - ${actionBarHeight + tableContainerTopMargin}px)`;
  }

  toggleAddColumns () {
    this.setState((prevState) => ({
      addRemoveColumnsOpened: !prevState.addRemoveColumnsOpened
    }));
  }

  render () {
    const {
      hostsSelected,
      workspaceSelected,
      readonly,
      permissions,
      addColumnsOpened,
      fields,
      routeList,
      showModalEditCreate,
      showDeleteConfirmation,
      isHostDetailVisible,
      setFieldVisibility,
      setNewOrderColumns,
      resetDefault,
      totalRows,
      showLeftFilters
    } = this.props;

    const addRemoveColumns = (
      <EditColumnContext
        trigger="action-button-column"
        fields={ fields }
        toggle={ () => { this.toggleAddColumns(); } }
        setFieldVisibility={ setFieldVisibility }
        setNewOrderColumns={ setNewOrderColumns }
        resetDefault={ resetDefault }
      />
    );

    const addRemoveColumnsButton = (
      <ContextMenuButton
        opened={ this.state.addRemoveColumnsOpened }
        menu={ addRemoveColumns }
        trigger="action-button-column"
        display
        icon={ <IconButton icon={ <EditColumns /> } title="More" /> }
      />
    );

    const showButton = (!readonly && hostsSelected.length > 0 && !isHostDetailVisible);
    const deleteButton = showButton ? <DeleteButton permissions={ permissions } readonly={ readonly } entity="host" selectedEntities={ hostsSelected } display={ hostsSelected.length > 0 && !isHostDetailVisible } openConfirmationModal={ this.props.showHostModalDelete } /> : null;
    const editButton = showButton ? <EditButton editFunction={ this.props.showHostDetail } selectedEntities={ hostsSelected } display={ hostsSelected.length === 1 && !isHostDetailVisible } /> : null;
    return (
      <Wrapper>
        <div ref={ this.actionBar }>
          <ActionBar
            selectedEntities={ hostsSelected }
            entity="host"
            workspaceSelected={ workspaceSelected }
            readonly={ readonly }
            defaultRoute="/host/workspaceSelected/"
            addColumnsOpened={ addColumnsOpened }
            toggleAddColumns={ this.props.toggleAddColumns }
            addRemoveColumns={ addRemoveColumnsButton }
            routeList={ routeList }
            permissions={ permissions }
            groupFieldSelector={ (hostsSelected.length === 0) && !isHostDetailVisible ? <GroupBy /> : null }
            createActions={ !readonly && <CreateActions setVisibilityCreateModal={ this.props.toggleModalEditCreate } showModalCreate={ showModalEditCreate } /> }
            deleteButton={ deleteButton }
            editButton={ editButton }
            showDeleteConfirmation={ showDeleteConfirmation }
            handleSubmitDelete={ this.props.deleteHostsSelected }
            handleCloseDelete={ this.props.hideHostModalDelete }
            count={ hostsSelected.length }
            totalRows={ totalRows }
            page={ this.props.page }
            rowsPerPage={ this.props.rowsPerPage }
            onPrev={ () => this.props.setPageNumber(this.props.page - 1) }
            onNext={ () => this.props.setPageNumber(this.props.page + 1) }
            sidebarFilters={ <FilterSideBarIcon entity="assets" /> }
          />
        </div>
        <TableContainer height={ this.getTableContainerHeight() }>
          <Table showLeftFilters={ showLeftFilters } />
          <FilterSideBar entity="assets" />
        </TableContainer>
        <HostDetail />
        <HostModalEditCreate />
        <ReduxModal id={ MODAL_CREATE_SERVICE }>
          <AddEditService onClose={ () => this.props.closeModal() } />
        </ReduxModal>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  workspaceSelected: selectCurrentWorkspace(state),
  fields: selectFields(state),
  addColumnsOpened: selectAddColumnsOpened(state),
  hostsSelected: selectHostsSelected(state),
  isHostDetailVisible: selectShowHostDetail(state),
  permissions: state.faraday.permissions.hosts,
  showModalEditCreate: selectShowModalEditCreate(state),
  routeList: selectRoutes(state),
  readonly: state.faraday.readonly,
  showDeleteConfirmation: selectShowDeleteConfirmation(state),
  totalRows: selectHostCount(state),
  page: selectPage('assets', state),
  rowsPerPage: selectRowsPerPage('assets', state),
  showLeftFilters: selectShowHostLeftFilters(state)
});

const mapDispatchToProps = (dispatch) => ({
  showHostDetail: (host) => {
    dispatch(showHostDetail(host));
    // dispatch(getVulns(host.ip));
  },
  toggleModalEditCreate: (value) => {
    dispatch(toggleModalEditCreate(value));
  },
  setFieldVisibility: (fieldObj, value) => {
    dispatch(setFieldVisibility(fieldObj, value));
  },
  setNewOrderColumns: (colums) => {
    dispatch(setNewOrderColumns(colums));
  },
  showHostModalDelete: () => {
    dispatch(showHostModalDelete());
  },
  hideHostModalDelete: () => {
    dispatch(hideHostModalDelete());
  },
  deleteHostsSelected: () => {
    dispatch(deleteHostsSelected());
  },
  showHostDetailById: (host) => {
    dispatch(showHostDetailById(host));
  },
  resetDefault: () => {
    dispatch(resetDefault());
  },
  setPageNumber: (page) => {
    dispatch(setPageNumberAssets(page));
  },
  hideHostDetail: () => {
    dispatch(hideHostDetail());
  },
  getData: () => {
    dispatch(getData());
  },
  closeModal: () => {
    dispatch(closeModal(MODAL_CREATE_SERVICE));
  }
});

Host.propTypes = {
  workspaceSelected: PropTypes.string.isRequired,
  addColumnsOpened: PropTypes.bool.isRequired,
  isHostDetailVisible: PropTypes.bool.isRequired,
  readonly: PropTypes.bool.isRequired,
  showHostModalDelete: PropTypes.func.isRequired,
  hideHostModalDelete: PropTypes.func.isRequired,
  deleteHostsSelected: PropTypes.func.isRequired,
  showHostDetailById: PropTypes.func.isRequired,
  setPageNumber: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Host);
