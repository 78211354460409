import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { selectLocation } from 'store/Router/selectors';
import { redirect } from 'store/Router/actions';
import Text from './styled';

const SubPage = ({ showFF, FF, name, url, hidden }) => {
  const dispatch = useDispatch();
  const location = useSelector(selectLocation);
  const isSelected = (loc) => location.pathname === loc;

  const onClick = () => {
    if (url) dispatch(redirect(url));
    else showFF(FF);
  };

  return (
    <Text onClick={ onClick } selected={ isSelected(url) } hidden={ hidden }>
      {name}
    </Text>
  );
};

SubPage.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  hidden: PropTypes.bool
};

SubPage.defaultProps = {
  hidden: false
};

export default SubPage;
