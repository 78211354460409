import get from 'lodash/get';
import { selectParsedCustomAttributes } from 'store/CustomAttributes/selectors';
import defaultFields from 'store/Manage/fields';

export const selectManageFields = (state) => {
  const fields = get(state, 'preferences.manage_table', defaultFields);

  Object.keys(defaultFields).forEach((field) => {
    if (!fields[field]) {
      fields[field] = defaultFields[field];
    }
  });

  const customFields = selectParsedCustomAttributes(state);

  return {
    ...customFields,
    ...fields
  };
};

export const selectIsFieldExpanded = (state, fieldId) => get(state, `preferences.vuln_detail.${fieldId}.expanded`, true);
