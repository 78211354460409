import React from 'react';
import { FilePicker } from 'react-file-picker';
import { useDispatch, useSelector } from 'react-redux';
import { setAttachment } from 'Screens/ManageEditCreate/actions/Actions';
import { addEvidence } from 'store/Manage/actions';
import { selectVulnDetail } from 'store/Manage/selectors';
import {
  ImageWrapper, EvidenceDefault, BlueText
} from './styled';

const UploadEvidence = ({ isEditing }) => {
  const dispatch = useDispatch();
  const vuln = useSelector(selectVulnDetail);
  const onUpload = (FileObject) => {
    if (isEditing) dispatch(setAttachment(FileObject));
    else dispatch(addEvidence(FileObject, vuln._id));
  };
  return (
    <FilePicker onChange={ onUpload }>
      <div>
        <ImageWrapper>
          <EvidenceDefault />
        </ImageWrapper>
        <BlueText>Add Evidence</BlueText>
      </div>
    </FilePicker>
  );
};

export default UploadEvidence;
