import React from 'react';
import { useDispatch } from 'react-redux';
import FlagIcon from 'Common/Components/FlagIcon';
import { confirm as confirmVuln } from 'store/Manage/actions';

export const Confirm = ({ vuln }) => { // eslint-disable-line import/prefer-default-export
  const dispatch = useDispatch();
  const confirm = (vuln, value) => dispatch(confirmVuln(vuln, value));
  return (vuln.status && vuln.name && vuln.confirmed
    ? (
      <FlagIcon
        theme="confirmed"
        alt="Confirmed"
        title="Confirmed"
        onClick={ () => confirm(vuln, false) }
        aria-label="Confirmed"
      />
      )
    : (
      <FlagIcon
        theme="notConfirmed"
        alt="Not-Confirmed"
        title="Not Confirmed"
        onClick={ () => { confirm(vuln, true); } }
        aria-label="Not Confirmed"
      />
      )
  );
};
