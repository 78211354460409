import * as types from './types';

const initialState = {
  redirectPathname: '',
  redirectSearch: '',
  redirectSaveHistory: false,
  redirectHasPendingChanges: false
};

function global (state = initialState, action) {
  switch (action.type) {
    case types.REDIRECT_START: {
      return {
        ...state,
        redirectPathname: action.pathname,
        redirectSearch: action.search,
        redirectSaveHistory: action.saveHistory
      };
    }
    case types.REDIRECT_CLEAR: {
      return {
        ...state,
        redirectPathname: initialState.redirectPathname,
        redirectSearch: initialState.redirectSearch,
        redirectSaveHistory: initialState.redirectSaveHistory
      };
    }
    case types.SET_HAS_PENDING_CHANGES: {
      return {
        ...state,
        redirectHasPendingChanges: action.hasPendingChanges
      };
    }
    default:
      return state;
  }
}

export default global;
