import React from 'react';
import { useDispatch } from 'react-redux';
import {
  getVulns,
  showHostDetail
} from 'store/HostDetail/actions';
import { selectRow, unSelectAll } from 'store/Host/actions';
import { Ip } from '../styled';

export const IpColumn = ({ host, children }) => { // eslint-disable-line import/prefer-default-export
  const dispatch = useDispatch();
  const handleClick = (e) => {
    dispatch(unSelectAll());
    dispatch(showHostDetail(host));
    dispatch(getVulns(host.ip));
    dispatch(selectRow(e, host));
  };
  return (
    <Ip onClick={ handleClick }>{children}</Ip>
  );
};
