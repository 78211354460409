import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { selectTemplateEditCreate } from 'store/KnowledgeBase/selectors';
import { resetState, createTemplate } from 'Screens/KnowledgeBaseEditCreate/actions/Actions';
import { Cancel } from 'Common/Components/Button/styled';
import ModalWrapper from 'Common/Components/ModalWrapper';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import General from './components/General';
import TechnicalDetails from './components/TechnicalDetails';
import {
  Wrapper, Title, ErrorMessage, CreateButton, Header, ButtonWrapper, ModalBody, ModalIcon, Tabs, Tab, NextIcon
} from './styled';

const ManualCreationModal = ({ handleClose, show }) => {
  const {
    errorMessage, exploitation, id, name
  } = useSelector(selectTemplateEditCreate);
  const [activeTab, setActiveTab] = useState('general');
  const dispatch = useDispatch();
  const onHandleClose = () => {
    handleClose();
    setActiveTab('general');
    dispatch(resetState());
  };
  const onHandleSubmitClose = async () => {
    dispatch(createTemplate());
    onHandleClose();
  };
  const RenderTab = {
    general: <General />,
    technicalDetails: <TechnicalDetails />
  };

  const saveDisabled = isEmpty(name) || isEmpty(exploitation);

  return show && (
  <ModalWrapper>
    <Wrapper>
      <Header>
        <Title>{ id > 0 ? 'Edit Vulnerability Template' : 'Create New Vulnerability Template'}</Title>
        <ButtonWrapper>
          <Cancel onClick={ onHandleClose }>Cancel</Cancel>
          <CreateButton
            disabled={ saveDisabled }
            onClick={ saveDisabled ? undefined : onHandleSubmitClose }
          >
            <span>{ id ? 'Save' : 'Create'}</span>
            <NextIcon disabled={ saveDisabled } />
          </CreateButton>
          <ModalIcon />
        </ButtonWrapper>
      </Header>
      <ModalBody>
        <Tabs>
          <Tab onClick={ () => setActiveTab('general') } active={ activeTab === 'general' }>General</Tab>
          <Tab onClick={ () => setActiveTab('technicalDetails') } active={ activeTab === 'technicalDetails' }>Technical Details</Tab>
        </Tabs>
        {RenderTab[activeTab]}
      </ModalBody>
      {errorMessage.length > 0 ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
    </Wrapper>
  </ModalWrapper>
  );
};

ManualCreationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default ManualCreationModal;
