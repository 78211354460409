import React, { useEffect } from 'react';
import { setVulnerabilityDetailTab, getEvidence } from 'store/Manage/actions';
import { selectVulnDetailSelectedTab, selectVulnDetail } from 'store/Manage/selectors';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import TABS from './tabs';
import { TabSelectorWrapper, TabButton } from './styled';

const Tabs = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const selectedTab = useSelector(selectVulnDetailSelectedTab);

  const getLabel = (tab) => intl.formatMessage({ id: tab.intlId });
  const isActive = (tab) => tab.id === selectedTab.id;
  const vuln = useSelector(selectVulnDetail);

  useEffect(() => {
    dispatch(getEvidence(vuln._id));
  }, [dispatch, vuln._id]);

  return (
    <TabSelectorWrapper>
      { TABS.map((tab) => (
        <TabButton
          active={ isActive(tab) }
          key={ tab.id }
          onClick={ () => dispatch(setVulnerabilityDetailTab(tab.id)) }
          children={ getLabel(tab) }
        />
      ))}
    </TabSelectorWrapper>
  );
};

export default Tabs;
