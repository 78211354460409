import clone from 'lodash/clone';
import {
  SET_TARGET_MANAGE_CREATE_UPDATE,
  REMOVE_TARGET_MANAGE_CREATE_UPDATE,
  ADD_REFERENCE_MANAGE_CREATE_UPDATE,
  REMOVE_REFERENCE_MANAGE_CREATE_UPDATE,
  ADD_CUSTOM_FIELDS_FROM_TEMPLATE,
  ADD_POLICY_MANAGE_CREATE_UPDATE,
  REMOVE_POLICY_MANAGE_CREATE_UPDATE,
  SELECT_CHECK_BOX_MANAGE_CREATE_UPDATE,
  SET_FIELD_NAME_MANAGE_CREATE_UPDATE,
  ADD_FILE_MANAGE_CREATE_UPDATE,
  REMOVE_FILE_MANAGE_CREATE_UPDATE,
  SET_CUSTOM_FIELD_MANAGE_CREATE_UPDATE,
  SET_ATTACHMENT_MANAGE_CREATE_UPDATE,
  DELETE_ATTACHMENT_MANAGE_CREATE_UPDATE,
  MANAGE_CREATE_UPDATE_START,
  MANAGE_CREATE_UPDATE_SUCCESS,
  MANAGE_CREATE_UPDATE_FAIL,
  RESET_STATE_MANAGE_CREATE_UPDATE,
  MISSING_HOST_TARGET_MANAGE_CREATE_UPDATE,
  ADD_REPORT_MANAGE_CREATE_UPDATE,
  REMOVE_REPORT_MANAGE_CREATE_UPDATE,
  IMPORT_REPORT_MANAGE_CREATE_UPDATE_START,
  IMPORT_REPORT_MANAGE_CREATE_UPDATE_SUCCESS,
  IMPORT_REPORT_MANAGE_CREATE_UPDATE_FAIL,
  SET_REDIRECT_MANAGE_CREATE_UPDATE,
  MISSING_SERVICE_DATA_MANAGE_CREATE_UPDATE,
  SET_MANAGE_CREATE_UPDATE,
  GET_TEMPLATES_MANAGE_EDIT_CREATE,
  GET_TEMPLATES_MANAGE_EDIT_SUCCESS,
  GET_TEMPLATES_MANAGE_EDIT_FAIL,
  SET_VALUES_FROM_TEMPLATE_MANAGE_CREATE,
  SET_VALUE_FIELD_PREVIEW_MANAGE_EDIT_CREATE,
  GET_HOSTS_REQUEST,
  GET_HOSTS_SUCCESS,
  GET_HOSTS_FAIL,
  GET_SERVICES_REQUEST,
  GET_SERVICES_SUCCESS,
  GET_SERVICES_FAIL,
  HOST_CREATE_REQUEST,
  HOST_CREATE_SUCCESS,
  HOST_CREATE_FAIL,
  ADD_TARGET,
  REMOVE_ASSET,
  ADD_CUSTOM_FIELD_ITEM,
  REMOVE_CUSTOM_FIELD_ITEM,
  SET_FILES_TO_UPLOAD_MANAGE,
  SET_UPLOAD_STATE_MANAGE,
  UPDATE_FILES_TO_UPLOAD,
  UPLOAD_REPORT_MANAGE_UPDATE_PERCENT,
  SET_MANAGE_COMMAND_ID,
  RESET_FILE_STATE_MANAGE,
  CANCEL_REQUEST_MANAGE,
  SET_FILE_INDEX_MANAGE,
  UPLOAD_REPORT_ERROR_MANAGE,
  SET_NAME_INTO_FILE_ERROR_LIST,
  CREATE_SERVICES_REQUEST,
  CREATE_SERVICES_SUCCESS,
  CREATE_SERVICES_FAIL
} from '../../Screens/ManageEditCreate/actions/Actions';
import { copyArray, copy } from '../../Common/Functions/Copy';

const initialState = {
  id: undefined,
  isFetching: false,
  errorMessage: undefined,
  redirect: false,
  easeOfResolution: undefined,
  severity: '',
  confirmed: true,
  host: '',
  port: '',
  protocol: '',
  service: '',
  externalId: '',
  reference: '',
  policy: '',
  targets: [],
  references: [],
  policies: [],
  description: '',
  resolution: '',
  accountability: false,
  availability: false,
  confidentiality: false,
  integrity: false,
  data: '',
  request: '',
  response: '',
  params: '',
  paramName: '',
  method: '',
  path: '',
  statusCode: '',
  query: '',
  website: '',
  files: [],
  customFields: {},
  missingHost: false,
  report: [],
  mode: '',
  importSuccess: false,
  missingServiceData: false,
  vuln: undefined,
  templates: [],
  services: [],
  hosts: [],
  filesToUpload: [],
  uploadState: '',
  fileIndex: -1,
  errorFilesList: [],
  currentUploadState: '',
  currentUploadPercent: 0,
  currentUploadError: '',
  _attachments: {}
};

function manageEditCreate (state = initialState, action) {
  switch (action.type) {
    case SET_TARGET_MANAGE_CREATE_UPDATE: {
      const targets = copyArray(state.targets);
      targets.push({
        host: state.host, port: state.port, protocol: state.protocol, name: state.service
      });

      return {
        ...state,
        targets,
        host: '',
        service: '',
        port: '',
        protocol: '',
        missingHost: false
      };
    }

    case ADD_TARGET: {
      const targets = copyArray(state.targets);
      targets.push(action.data);
      return {
        ...state,
        targets
      };
    }
    case REMOVE_ASSET: {
      const targets = copyArray(state.targets);
      const index = targets.findIndex((item) => item.type === action.data.type && item.id === action.data.id);
      if (index > -1) {
        targets.splice(index, 1);
      }
      return {
        ...state,
        targets
      };
    }

    case MISSING_HOST_TARGET_MANAGE_CREATE_UPDATE: {
      return {
        ...state,
        missingHost: true
      };
    }

    case REMOVE_TARGET_MANAGE_CREATE_UPDATE: {
      const targets = copyArray(state.targets);
      targets.splice(action.index, 1);

      return {
        ...state,
        targets
      };
    }

    case ADD_CUSTOM_FIELDS_FROM_TEMPLATE: {
      return {
        ...state,
        [action.field]: action.values
      };
    }

    case ADD_REFERENCE_MANAGE_CREATE_UPDATE: {
      const references = [...state.references];
      references.push(state.reference);

      return {
        ...state,
        references,
        reference: ''
      };
    }

    case REMOVE_REFERENCE_MANAGE_CREATE_UPDATE: {
      const references = [...state.references];
      references.splice(action.index, 1);

      return {
        ...state,
        references
      };
    }

    case ADD_POLICY_MANAGE_CREATE_UPDATE: {
      const policies = clone(state.policies);
      policies.push(state.policy);

      return {
        ...state,
        policies,
        policy: ''
      };
    }

    case REMOVE_POLICY_MANAGE_CREATE_UPDATE: {
      const policies = clone(state.policies);
      policies.splice(action.index, 1);

      return {
        ...state,
        policies
      };
    }

    case SELECT_CHECK_BOX_MANAGE_CREATE_UPDATE: {
      return {
        ...state,
        [action.checkName]: !state[action.checkName]
      };
    }

    case SET_FIELD_NAME_MANAGE_CREATE_UPDATE: {
      return {
        ...state,
        [action.fieldName]: action.value
      };
    }

    case ADD_FILE_MANAGE_CREATE_UPDATE: {
      const files = copyArray(state.files);
      files.push(action.file);

      return {
        ...state,
        files
      };
    }

    case REMOVE_FILE_MANAGE_CREATE_UPDATE: {
      const files = copyArray(state.files);
      files.splice(action.index, 1);

      return {
        ...state,
        files
      };
    }

    case SET_CUSTOM_FIELD_MANAGE_CREATE_UPDATE: {
      const customFields = copy(state.customFields);
      customFields[action.key] = action.value;

      return {
        ...state,
        customFields
      };
    }

    case SET_ATTACHMENT_MANAGE_CREATE_UPDATE: {
      return {
        ...state,
        _attachments: {
          ...state._attachments, // eslint-disable-line no-underscore-dangle
          [action.name]: {
            content_type: action.content_type,
            data: action.data
          }
        },
        files: [action.file]
      };
    }

    case DELETE_ATTACHMENT_MANAGE_CREATE_UPDATE: {
      const { [action.key]: deleted, ...attachments } = state._attachments; // eslint-disable-line no-underscore-dangle
      return {
        ...state,
        _attachments: {
          ...attachments
        }
      };
    }

    case ADD_CUSTOM_FIELD_ITEM: {
      const customFields = clone(state.customFields);
      customFields[action.key] = [...customFields[action.key], { value: action.value }];

      return {
        ...state,
        customFields
      };
    }

    case REMOVE_CUSTOM_FIELD_ITEM: {
      const customFields = clone(state.customFields);
      // const index = customFields[action.key].findIndex((item) => item.value === action.value);
      const index = action.value;
      if (index > -1) {
        customFields[action.key].splice(index, 1);
      }

      return {
        ...state,
        customFields
      };
    }

    case MANAGE_CREATE_UPDATE_START: {
      return {
        ...state,
        isFetching: true
      };
    }

    case MANAGE_CREATE_UPDATE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        redirect: true
      };
    }

    case MANAGE_CREATE_UPDATE_FAIL: {
      return {
        ...state,
        isFetching: false,
        errorMessage: action.error
      };
    }

    case RESET_STATE_MANAGE_CREATE_UPDATE: {
      return {
        ...initialState,
        references: []
      };
    }

    case ADD_REPORT_MANAGE_CREATE_UPDATE: {
      const report = copyArray(state.report);
      report.push(action.file);

      return {
        ...state,
        report
      };
    }

    case REMOVE_REPORT_MANAGE_CREATE_UPDATE: {
      const report = copyArray(state.report);
      report.splice(action.index, 1);

      return {
        ...state,
        report
      };
    }

    case IMPORT_REPORT_MANAGE_CREATE_UPDATE_START: {
      return {
        ...state,
        isFetching: true
      };
    }

    case IMPORT_REPORT_MANAGE_CREATE_UPDATE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        importSuccess: true
      };
    }

    case IMPORT_REPORT_MANAGE_CREATE_UPDATE_FAIL: {
      return {
        ...state,
        isFetching: false,
        errorMessage: action.error
      };
    }

    case SET_REDIRECT_MANAGE_CREATE_UPDATE: {
      return {
        ...state,
        redirect: true,
        importSuccess: false
      };
    }

    case MISSING_SERVICE_DATA_MANAGE_CREATE_UPDATE: {
      return {
        ...state,
        missingServiceData: true
      };
    }

    case SET_MANAGE_CREATE_UPDATE: {
      return {
        ...state,
        id: action.vuln._id,
        isFetching: false,
        name: action.vuln.name ? action.vuln.name : '',
        desc: action.vuln.desc ? action.vuln.desc : '',
        description: action.vuln.desc ? action.vuln.desc : '',
        externalId: action.vuln.external_id ? action.vuln.external_id : '',
        references: action.vuln.refs ? action.vuln.refs : [],
        resolution: action.vuln.resolution ? action.vuln.resolution : '',
        policies: action.vuln.policyviolations ? action.vuln.policyviolations : [],
        accountability: action.vuln.impact.accountability,
        availability: action.vuln.impact.availability,
        confidentiality: action.vuln.impact.confidentiality,
        integrity: action.vuln.impact.integrity,
        data: action.vuln.data ? action.vuln.data : '',
        request: action.vuln.request ? action.vuln.request : '',
        response: action.vuln.response ? action.vuln.response : '',
        method: action.vuln.method ? action.vuln.method : '',
        parameter_name: action.vuln.parameter_name ? action.vuln.parameter_name : '',
        params: action.vuln.params ? action.vuln.params : '',
        path: action.vuln.path ? action.vuln.path : '',
        statusCode: action.vuln.status_code ? action.vuln.status_code : '',
        query: action.vuln.query ? action.vuln.query : '',
        website: action.vuln.website ? action.vuln.website : '',
        customFields: action.vuln.custom_fields,
        easeOfResolution: action.vuln.easeofresolution ? action.vuln.easeofresolution : '',
        severity: action.vuln.severity,
        vuln: action.vuln
      };
    }

    case GET_TEMPLATES_MANAGE_EDIT_CREATE: {
      return {
        ...state,
        isFetching: true
      };
    }

    case GET_TEMPLATES_MANAGE_EDIT_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        templates: action.data
      };
    }

    case GET_TEMPLATES_MANAGE_EDIT_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: 'Failed to get templates'
      };
    }

    case SET_VALUES_FROM_TEMPLATE_MANAGE_CREATE: {
      return {
        ...state,
        name: action.template.doc.name,
        customFields: action.template.doc.customfields,
        references: action.template.doc.refs,
        desc: action.template.doc.desc,
        description: action.template.doc.desc,
        easeOfResolution: action.template.doc.easeofresolution,
        severity: action.template.doc.exploitation,
        policies: action.template.doc.policyviolations,
        externalId: action.template.doc.external_id,
        resolution: action.template.doc.resolution,
        accountability: action.template.doc.impact.accountability,
        availability: action.template.doc.impact.availability,
        confidentiality: action.template.doc.impact.confidentiality,
        integrity: action.template.doc.impact.integrity
      };
    }

    case SET_VALUE_FIELD_PREVIEW_MANAGE_EDIT_CREATE: {
      return {
        ...state,
        [action.field]: action.value
      };
    }

    case GET_HOSTS_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }

    case GET_HOSTS_SUCCESS: {
      return {
        ...state,
        hosts: action.data.rows
      };
    }

    case GET_HOSTS_FAIL: {
      return {
        ...state,
        isFetching: false,
        errorMessage: action.error
      };
    }

    case GET_SERVICES_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }

    case GET_SERVICES_SUCCESS: {
      return {
        ...state,
        services: action.data.services
      };
    }

    case GET_SERVICES_FAIL: {
      return {
        ...state,
        isFetching: false,
        errorMessage: action.error
      };
    }

    case HOST_CREATE_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }

    case HOST_CREATE_SUCCESS: {
      return {
        ...state,
        hosts: [...state.hosts, { id: action.data.id, key: action.data.id, value: action.data }]
      };
    }

    case HOST_CREATE_FAIL: {
      return {
        ...state,
        isFetching: false,
        errorMessage: action.error
      };
    }

    case CREATE_SERVICES_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }

    case CREATE_SERVICES_SUCCESS: {
      const index = state.targets.findIndex((t) => (t.id === action.service.parent));
      const tempTargets = state.targets;
      let host = state.hosts.find((item) => item.id === action.service.host_id);
      if (host) {
        host = host.value;
        tempTargets[index] = { ...action.service, hostname: host.name, hostnameData: host.hostnames.length > 0 ? host.hostnames : '' };
      }
      return {
        ...state,
        services: [...state.services, { id: action.service.id, key: action.service.id, value: action.service }],
        targets: tempTargets
      };
    }

    case CREATE_SERVICES_FAIL: {
      return {
        ...state,
        isFetching: false,
        errorMessage: action.error
      };
    }

    case SET_FILES_TO_UPLOAD_MANAGE: {
      return {
        ...state,
        filesToUpload: action.files
      };
    }

    case SET_UPLOAD_STATE_MANAGE: {
      return {
        ...state,
        uploadState: action.state
      };
    }

    case SET_FILE_INDEX_MANAGE: {
      return {
        ...state,
        fileIndex: action.index
      };
    }

    case UPLOAD_REPORT_ERROR_MANAGE: {
      return {
        ...state,
        currentUploadState: 'Error',
        currentUploadPercent: 0,
        currentUploadError: action.error
      };
    }

    case UPDATE_FILES_TO_UPLOAD: {
      const { filesToUpload } = state;
      const tempFiles = filesToUpload;
      tempFiles.shift();
      return {
        ...state,
        filesToUpload: tempFiles
      };
    }

    case SET_NAME_INTO_FILE_ERROR_LIST: {
      return {
        ...state,
        errorFilesList: [...state.errorFilesList, action.name]
      };
    }

    case UPLOAD_REPORT_MANAGE_UPDATE_PERCENT: {
      return {
        ...state,
        currentUploadState: 'Uploading',
        currentUploadError: ''
      };
    }

    case SET_MANAGE_COMMAND_ID: {
      return {
        ...state,
        commandId: action.commandId,
        currentUploadState: 'Success',
        currentUploadPercent: 100,
        currentUploadError: ''
      };
    }

    case RESET_FILE_STATE_MANAGE: {
      return {
        ...state,
        currentUploadState: '',
        currentUploadError: '',
        errorFilesList: []
      };
    }

    case CANCEL_REQUEST_MANAGE: {
      return {
        ...state,
        currentUploadState: 'Canceled',
        currentUploadPercent: 0,
        currentUploadError: ''
      };
    }

    default:
      return state;
  }
}

export default manageEditCreate;
