import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { getEvidence } from 'store/Manage/actions';
import { selectVulnDetailSelectedTab, selectVulnDetail } from 'store/Manage/selectors';
import { useDispatch, useSelector } from 'react-redux';
import TabWrapper from './styled';

const Tabs = ({ onScroll, tabRef }) => {
  const dispatch = useDispatch();
  const selectedTab = useSelector(selectVulnDetailSelectedTab);
  const vuln = useSelector(selectVulnDetail);

  useEffect(() => {
    dispatch(getEvidence(vuln._id));
  }, [dispatch, vuln._id]);

  return (
    <>

      <TabWrapper ref={ tabRef } onScroll={ onScroll }>
        { selectedTab.component }
      </TabWrapper>
    </>
  );
};

Tabs.propTypes = {
  onScroll: PropTypes.func.isRequired,
  tabRef: PropTypes.object.isRequired
};

export default Tabs;
