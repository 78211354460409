/* eslint-disable import/prefer-default-export */
import React from 'react';
import { oneOfType, PropTypes } from 'prop-types';
import get from 'lodash/get';
import Skeleton from 'Common/Components/Skeleton';
import { Wrapper, Link } from './styled';

export const CVE = ({ vuln, showSkeleton }) => {
  const cves = get(vuln, 'cve', []);

  if (showSkeleton) return <Skeleton />;
  return (
    <Wrapper>
      {cves.map((cve) => (
        <Link href={ `https://www.cve.org/CVERecord?id=${cve.toUpperCase()}` } children={ cve } key={ cve } />
      ))}
    </Wrapper>

  );
};

CVE.propTypes = {
  vuln: oneOfType([PropTypes.object, PropTypes.number]).isRequired,
  showSkeleton: PropTypes.bool.isRequired
};
