import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  selectUploadState, selectFileIndex, selectErrorFilesList,
  selectCurrentUploadState, selectCurrentUploadError
} from 'store/ManageEditCreate/selectors';
import ModalWrapper from 'Common/Components/ModalWrapper';
import DragFile from 'Common/Components/DragFile';
import FileUploader from 'Common/Components/FileUploader';
import {
  uploadFile, setFileIndex, setUploadState,
  setFilesCount, resetFileUploadState
} from 'Screens/ManageEditCreate/actions/Actions';
import { FaPlus as Plus } from 'react-icons/fa';
import {
  Button, ButtonsContainer, PullLeft, BackButton
} from 'Common/Components/MainContainer/styled';
import FilesList from 'Common/Components/FilesList';
import {
  Wrapper, Title, BackBtn,
  Click, AddMore, SubTitle
} from './styled';
import './styles.scss';
import UploadSummary from './components/UploadSummary';

const ImportFileCreationModal = ({ show, showMessageEmptyWorkspace, handleClose }) => {
  const [files, setFiles] = useState([]);
  const [step, setStep] = useState('DRAG');
  const dispatch = useDispatch();
  const intl = useIntl();
  const uploadState = useSelector((state) => selectUploadState(state));
  const fileIndex = useSelector((state) => selectFileIndex(state));
  const errorFilesList = useSelector((state) => selectErrorFilesList(state));
  const currentUploadState = useSelector((state) => selectCurrentUploadState(state));
  const currentUploadError = useSelector((state) => selectCurrentUploadError(state));

  useEffect(() => {
    if (step === 'UPLOAD' && uploadState === 'PROCESSED' && fileIndex >= 0) {
      dispatch(uploadFile(files[fileIndex]));
    }

    if (step === 'UPLOAD' && uploadState === 'FINISHED' && fileIndex <= -1 && (currentUploadError === '' || files.length > 1)) {
      setStep('SUMMARY');
    }
    if (currentUploadState === 'Error' && step === 'DRAG') dispatch(resetFileUploadState());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, step, uploadState]);

  const onHandleClose = () => {
    setFiles([]);
    setStep('DRAG');
    dispatch(resetFileUploadState());
    handleClose();
  };

  const handleDrop = (filesUploading) => {
    const fileList = files;
    for (let i = 0; i < filesUploading.length; i += 1) {
      if (!filesUploading[i].name) return;
      if (fileList.findIndex((item) => (item.name === filesUploading[i].name && item.size === filesUploading[i].size)) === -1) {
        fileList.push(filesUploading[i]);
      }
    }
    setFiles([...fileList]);
    if (fileList.length > 0) setStep('LIST');
    dispatch(setFileIndex(fileList.length - 1));
    dispatch(setUploadState());
  };

  const removeFileFromList = (file) => {
    const fileList = files.filter((f) => f.name !== file.name || f.size !== file.size);
    setFiles(fileList);
    dispatch(setFileIndex(fileList.length - 1));
    if (fileList.length === 0) setStep('DRAG');
  };

  const uploadFileManage = () => {
    dispatch(setFilesCount(files));
    if (fileIndex >= 0) dispatch(uploadFile(files[fileIndex]));
  };

  const handleNewFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
    }
  };

  const stepComponent = {
    DRAG: <DragFile onManage handleDrop={ handleDrop } />,
    LIST: <FilesList files={ files } removeFile={ removeFileFromList } handleDrop={ handleDrop } />,
    UPLOAD:
  <FileUploader
    onManage
    files={ files }
    uploadFile={ uploadFileManage }
    currentUploadState={ currentUploadState }
    currentUploadError={ currentUploadError }
    filenames={ files.map((f) => (f.name)).join(', ') }
  />,
    SUMMARY: <UploadSummary files={ files } errorFilesList={ errorFilesList } />
  };

  const defaultStepComponent = <DragFile onManage handleDrop={ handleDrop } />;

  return show && (
    <ModalWrapper>
      <Wrapper>
        <PullLeft><Title>{intl.formatMessage({ id: 'importFileCreationModal.title' })}</Title></PullLeft>
        {showMessageEmptyWorkspace && step === 'DRAG'
          ? (
          <SubTitle>{intl.formatMessage({ id: 'importFileCreationModal.subtitle' })}</SubTitle>
            )
          : null }
        { step ? stepComponent[step] : defaultStepComponent }

        { step === 'DRAG' && <Click href="https://docs.faradaysec.com/CSV-Exporter/" target="_black">{intl.formatMessage({ id: 'importFileCreationModal.info' })}</Click> }
        { step === 'LIST' &&
        (
        <AddMore>
          <Plus />
          <label htmlFor="upload">{intl.formatMessage({ id: 'importFileCreationModal.label' })}</label>
          <input multiple style={ { opacity: 0, position: 'absolute', zIndex: -1 } } id="upload" type="file" onChange={ (e) => handleNewFile(e.target.files) } />
        </AddMore>
        ) }

        <ButtonsContainer>
          { step === 'UPLOAD' && <BackButton className="margin-top-22" onClick={ () => { setStep('LIST'); } }>{intl.formatMessage({ id: 'importFileCreationModal.backButton' })}</BackButton> }
          { step === 'SUMMARY' ? <Button className="margin-top-22" onClick={ onHandleClose }>{intl.formatMessage({ id: 'importFileCreationModal.doneButton' })}</Button> : <Button className="margin-top-22" onClick={ () => { setStep('UPLOAD'); } } disabled={ step !== 'LIST' || files.length === 0 }>{intl.formatMessage({ id: 'importFileCreationModal.uploadButton' })}</Button> }
          <BackBtn className="margin-top-22" onClick={ onHandleClose }>{intl.formatMessage({ id: 'importFileCreationModal.cancelButton' })}</BackBtn>
        </ButtonsContainer>
      </Wrapper>
    </ModalWrapper>
  );
};

ImportFileCreationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default ImportFileCreationModal;
