import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteEvidence } from 'store/Manage/actions';
import { deleteAttachment } from 'Screens/ManageEditCreate/actions/Actions';
import { selectVulnDetail } from 'store/Manage/selectors';
import copyToClipboard from 'utils/clipboard';
import {
  EvidenceImg, ImageWrapper, FileName, Options, AttachmentWrapper, DeleteEvidenceIcon, Clipboard, ImagePlaceholder
} from './styled';

const Attachment = ({ evidence, name, isEditing }) => {
  const dispatch = useDispatch();
  const vuln = useSelector(selectVulnDetail);
  const imageSrc = `data:${evidence.content_type};base64,${evidence.data}`;
  const copyTextToClipboard = () => copyToClipboard(`(evidence:vulnerability:${vuln._id}:${name})`);
  const onDelete = () => {
    if (isEditing) dispatch(deleteAttachment(name));
    else dispatch(deleteEvidence(name, vuln._id));
  };
  let extention = name.split('.');
  extention = extention[extention.length - 1];
  const isAnImage = extention === 'png' || extention === 'jpg' || extention === 'gif' || extention === 'jpeg';
  const displayName = name.length > 45 ? `${name.slice(0, 45)}...${extention}` : name;
  return (
    <AttachmentWrapper key={ `attachement_vuln_detail_${name}` }>
      <ImageWrapper>
        {isAnImage ? <EvidenceImg src={ imageSrc } alt={ name } /> : <ImagePlaceholder />}
        <Options>
          {!isEditing && <Clipboard onClick={ copyTextToClipboard } title="Copy markdown reference" />}
          <DeleteEvidenceIcon onClick={ onDelete } />
        </Options>
      </ImageWrapper>

      <FileName>
        {displayName}
      </FileName>

    </AttachmentWrapper>
  );
};

export default Attachment;
