import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`

`;
Wrapper.displayName = 'Wrapper';

export const SpinnerIcon = styled.img`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;
SpinnerIcon.displayName = 'SpinnerIcon';
