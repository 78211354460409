import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import isValidPassword from 'Common/Components/Password/functions';
import { Accept } from 'Common/Components/Button/styled';
import { changePassword } from 'store/Settings/changePasswordActions';
import { clearLoginSettings } from 'store/Settings/loginSettingsActions';
import { selectError, selectMessage } from 'store/Settings/changePasswordSelectors';
import Error from 'Common/Components/Error';
import Success from 'Common/Components/Success';
import {
  Wrapper, Title, Rule, CustomPassword, PasswordSection
} from './styled';

const ChangePassword = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');

  useEffect(() => dispatch(clearLoginSettings()), [dispatch]);

  const props = useSelector((state) => ({
    error: selectError(state),
    message: selectMessage(state)
  }));

  const accept = () => {
    if (isValidPassword(newPassword) && isValidPassword(repeatNewPassword)) {
      dispatch(changePassword(currentPassword, newPassword, repeatNewPassword));
    }
  };

  const isPasswordMatch = () => newPassword === repeatNewPassword;

  return (
    <Wrapper>
      <Title>{intl.formatMessage({ id: 'preferences.account.changePassword.title' }) }</Title>
      <CustomPassword
        title={ intl.formatMessage({ id: 'preferences.account.changePassword.password.title' }) }
        placeholder={ intl.formatMessage({ id: 'preferences.account.changePassword.password.placeholder' }) }
        onChange={ (v) => { setCurrentPassword(v); } }
        defaultValue={ currentPassword }
      />
      <PasswordSection>
        <CustomPassword title={ intl.formatMessage({ id: 'preferences.account.changePassword.newPassword.title' }) } placeholder={ intl.formatMessage({ id: 'preferences.account.changePassword.newPassword.placeholder' }) } onChange={ (v) => { setNewPassword(v); } } valid={ isValidPassword(newPassword) } defaultValue={ newPassword } />
        <CustomPassword title={ intl.formatMessage({ id: 'preferences.account.changePassword.repeatNewPassword.title' }) } placeholder={ intl.formatMessage({ id: 'preferences.account.changePassword.repeatNewPassword.placeholder' }) } onChange={ (v) => { setRepeatNewPassword(v); } } valid={ isValidPassword(repeatNewPassword) } defaultValue={ repeatNewPassword } />
      </PasswordSection>

      { (newPassword && !isValidPassword(newPassword)) &&
      (
      <Rule>
        <FormattedMessage id="resetpassword.rule" />
      </Rule>
      )}
      { props && !isPasswordMatch() && repeatNewPassword &&
      (
      <Rule>
        <FormattedMessage id="resetpassword.doesntmatch" />
      </Rule>
      )}

      { props.error && props.message ? <Error>{props.message}</Error> : null}
      { !props.error && props.message ? <Success>{props.message}</Success> : null}

      <Accept onClick={ accept }>{intl.formatMessage({ id: 'preferences.account.changePassword.confirm' }) }</Accept>
    </Wrapper>
  );
};
export default ChangePassword;
