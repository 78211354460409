import styled from 'styled-components';
import { bpd } from 'utils/breakpoints';

export const Row = styled.div`
  
  ${({ from }) => bpd(from)`
    display: flex;
  `}
  justify-content: space-between;
  align-items: ${({ centered }) => (centered ? 'center' : undefined)};
`;
Row.displayName = 'Row';

export const Col = styled.div`
  flex: ${({ fit }) => (fit ? 'initial' : '1 1 auto')};
  text-align: ${({ textAlign }) => textAlign || 'initial'};
  height: ${(props) => (props.height ? `${props.height}` : undefined)};
  width: ${(props) => (props.width ? `${props.width}` : undefined)};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : undefined)};
`;
Col.displayName = 'Col';
