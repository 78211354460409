import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import {
  setField, addHostname, removeHostname, createUpdateHost, resetState
} from 'store/HostEditCreate/actions';
import ModalWrapper from 'Common/Components/ModalWrapper';
import CustomList from 'Common/Components/CustomList';
import { toggleModalEditCreate } from 'store/Host/actions';
import { selectShowModalEditCreate } from 'store/Host/selectors';
import {
  selectHostEditCreateId, selectHostEditCreateIp, selectHostEditCreateOs,
  selectHostEditCreateMac, selectHostEditCreateDescription, selectHostEditCreateOwned,
  selectHostEditCreateHostnames, selectHostEditCreateErrorMessage
} from 'store/HostEditCreate/selectors';
import CheckBox from 'Common/Components/Checkbox';
import {
  Title, TitleContainer, Wrapper, ModalBody,
  LeftSide, RightSide, CreateButton, CancelButton,
  ErrorMessage, WrapperField, Label, TextWrapper, TextField,
  ButtonsWrapper, Description, HostnameTitle, ModalIcon
} from './styled';

const HostModalEditCreate = () => {
  const dispatch = useDispatch();
  const id = useSelector((state) => (selectHostEditCreateId(state)));
  const ip = useSelector((state) => (selectHostEditCreateIp(state)));
  const os = useSelector((state) => (selectHostEditCreateOs(state)));
  const mac = useSelector((state) => (selectHostEditCreateMac(state)));
  const description = useSelector((state) => (selectHostEditCreateDescription(state)));
  const owned = useSelector((state) => (selectHostEditCreateOwned(state)));
  const hostnames = useSelector((state) => (selectHostEditCreateHostnames(state)));
  const errorMessage = useSelector((state) => (selectHostEditCreateErrorMessage(state)));
  const showModalEditCreate = useSelector((state) => (selectShowModalEditCreate(state)));
  const [isValidMacAddress, setIsValidMacAddress] = useState(true);
  const validateMacAddress = (str) => {
    // eslint-disable-next-line prefer-regex-literals
    const regex = new RegExp('^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([0-9a-fA-F]{4}\\.[0-9a-fA-F]{4}\\.[0-9a-fA-F]{4})$');
    setIsValidMacAddress(!!regex.test(str));
  };

  const onChange = (value) => {
    dispatch(setField('mac', value));
    if (value === '') setIsValidMacAddress(true);
    else validateMacAddress(value);
  };

  useEffect(() => () => dispatch(resetState()), [dispatch]);
  return showModalEditCreate && (
    <ModalWrapper>
      <Wrapper>
        <TitleContainer>
          <Title>{id ? <FormattedMessage id="edit" /> : <FormattedMessage id="host.creation.title" />}</Title>
          <ButtonsWrapper>
            <CancelButton onClick={ () => dispatch(toggleModalEditCreate(false)) } id="cancelButton">
              <FormattedMessage id="cancel" />
            </CancelButton>
            <CreateButton disabled={ ip.length === 0 || !isValidMacAddress } onClick={ () => dispatch(createUpdateHost()) } id="createButton">{id ? <FormattedMessage id="edit" /> : <FormattedMessage id="create" />}</CreateButton>
            <ModalIcon />
          </ButtonsWrapper>
        </TitleContainer>
        <ModalBody>
          <LeftSide>
            <TextWrapper>
              <TextField title="Asset*" name="ip" width="145px" placeholder="255.255.255.255" value={ ip } onChange={ (value) => dispatch(setField('ip', value)) } className="ip" />
              <TextField title="Operating System" name="os" width="190px" placeholder="Unix" value={ os } onChange={ (value) => dispatch(setField('os', value)) } />
            </TextWrapper>
            <TextField title="MAC Address" name="mac address" width="265px" placeholder="00:00:5e:00:53:af" value={ mac } onChange={ onChange } error={ !isValidMacAddress } errorMessage="Invalid MAC Address" />
            <Description height="145px" title="Description" name="description" placeholder="Click to add a description" value={ description } onChange={ (value) => dispatch(setField('description', value)) } isEmpty={ !description.length } />
            <WrapperField>
              <CheckBox checked={ owned } onChange={ () => dispatch(setField('owned', !owned)) } state={ owned } />
              <Label>Has been owned</Label>
            </WrapperField>
          </LeftSide>
          <RightSide>
            <HostnameTitle>Add Hostnames</HostnameTitle>
            <CustomList
              key="HOST_CREATION_HOST_NAMES"
              title="Hostname"
              placeholder="net.hostname.com"
              addItem={ (v) => dispatch(addHostname(v)) }
              removeItem={ (index) => dispatch(removeHostname(index)) }
              listData={ hostnames }
              height="34px"
            />
          </RightSide>
        </ModalBody>
        {errorMessage && errorMessage.length > 0 ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
      </Wrapper>
    </ModalWrapper>
  );
};

export default HostModalEditCreate;
