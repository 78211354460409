import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CUSTOM_ATTRIBUTE_TYPES } from 'Screens/Manage/components/CustomAttributesModal/constants';
import StandardDropdown from 'Common/Components/StandarDropdown/index';
import BlueButton from 'Common/Components/BlueButton';
import OutlinedButton from 'Common/Components/OutlinedButton';

import { Wrapper, Buttons, Content } from './styled';

const ChoiceCustomField = ({
  field, onCancel, onSave, vuln, initialValue
}) => {
  const [options, setOptionsList] = useState([{ desc: '', name: '' }]);
  const [selectedOption, setSelectedOption] = useState(initialValue);
  const [editMode, setEditMode] = useState(!initialValue);

  useEffect(() => {
    if (selectedOption.length > 0) {
      setEditMode(true);
    }
  }, [selectedOption]);

  useEffect(() => {
    // eslint-disable-next-line no-useless-escape
    const list = field && field.field_metadata ? field.field_metadata.replace(/[\[\]']+/g, '').replaceAll('"', '').split(',') : [];
    const formattedList = list.map((option) => ({ desc: option, name: option }));
    setOptionsList(formattedList);
  }, [field]);

  useEffect(() => {
    if (selectedOption !== initialValue) {
      setSelectedOption(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = () => {
    onSave(vuln, field.field_name, selectedOption);
    setEditMode(false);
  };

  const handleCancel = () => {
    setEditMode(false);
    if (initialValue || initialValue.length === 0) {
      setSelectedOption(initialValue);
    } else {
      onCancel();
    }
  };

  const onSelectType = (field, option) => {
    setSelectedOption(option);
  };

  return (
    <Wrapper>
      {(editMode || !initialValue) &&
      (
      <StandardDropdown
        field="option_field"
        options={ options }
        updateValue={ onSelectType }
        width="154px"
        defaultValue={ selectedOption }
        placeholder="Choose Option"
      />
      )}
      {!editMode && initialValue &&
        <Content onClick={ () => setEditMode(true) }>{initialValue}</Content>}
      {editMode && selectedOption &&
     (
     <Buttons>
       <OutlinedButton label="Cancel" onClick={ handleCancel } />
       <BlueButton label="Save" onClick={ handleSave } />
     </Buttons>
     )}
    </Wrapper>
  );
};

ChoiceCustomField.defaultProps = {
  initialValue: ''
};

ChoiceCustomField.propTypes = {
  field: PropTypes.shape({
    field_display_name: PropTypes.string,
    field_type: PropTypes.oneOf([CUSTOM_ATTRIBUTE_TYPES.CHOICE]),
    field_metadata: PropTypes.string.isRequired
  }).isRequired,
  vuln: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  initialValue: PropTypes.string
};

export default ChoiceCustomField;
