import styled from 'styled-components';
import { slideInRight } from 'Styles/effects';

const Text = styled.div`
  padding-left: 58px;
  font-size: 15px;
  color: #264d6d;
  height: 42px;
  display: ${(props) => (props.hidden ? 'hidden' : 'flex')};
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${slideInRight}

    &:hover {
    background-color: #d9e4ef;
  }
  
  ${({ selected }) => selected &&
  `
      background-color: #d9e4ef;
      font-weight: 600;
  `}
`;

export default Text;
