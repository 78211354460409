import React from 'react';
import ShowInactive from './components/ShowInactive';
import GridViewButton from './components/GridViewButton';
import ListViewButton from './components/ListViewButton';
import Wrapper from './styled';

const ExtraPreviousActions = () => (
  <Wrapper>
    <ShowInactive />
    <GridViewButton />
    <ListViewButton />
  </Wrapper>
);

export default ExtraPreviousActions;
