import styled from 'styled-components';

export const Wrapper = styled.div`

`;
Wrapper.displayName = 'Wrapper';

export const FilesContainer = styled.div`
  width: 100%;
  height: 201px;
  border-radius: 3px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  overflow-y: auto;
`;
FilesContainer.displayName = 'FilesContainer';

export const FilesItem = styled.div`
  width: 100%;
  height: 34px;
  padding-top: 8px;
  
  &:hover {
    background-color: #e6eff6;
  }
`;
FilesItem.displayName = 'FilesItem';

export const FileName = styled.span`
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #42719f;
  float: left;
  margin-left: 13px;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
FileName.displayName = 'FileName';

export const FileSize = styled.span`
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7a7e8f;
  float: right;
`;
FileSize.displayName = 'FileSize';

export const Icon = styled.span`
  float: right;
  bottom: 5px;
  position: relative;
  margin-left: 21px;
  margin-right: 11px;
`;
Icon.displayName = 'Icon';

export const DragableZoneActive = styled.div`
    width: ${(props) => (props.onManage ? '452px' : 'unset')};
    height: ${(props) => (props.onManage ? '425px' : 'unset')};
    border: 1px solid #0082ed;
    background-color: aliceblue;
    position: absolute;
    top: -10px;
    bottom: 0;
    left: ${(props) => (props.onManage ? '-2px' : '0')};
    right: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
`;
DragableZoneActive.displayName = 'DragableZoneActive';

export const DragableZoneActiveContent = styled.div`
    position: absolute;
    color: grey;
    font-size: 36px;
`;
DragableZoneActiveContent.displayName = 'DragableZoneActiveContent';

export const Text = styled.div`
    color: #0082ed;
    font-size: 14.5px;
    line-height: 21px;
`;
Text.displayName = 'Text';
