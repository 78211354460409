import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateVuln } from 'store/Manage/actions';
import { selectVulnDetail, selectReadOnly, selectPermissions } from 'store/Manage/selectors';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import MarkdownEditor from 'Common/Components/v2/MarkdownEditor';
import Wrapper from './styled';
import ExpandableWrapper from '../GeneralTab/components/ExpandableWrapper';

const TechnicalTab = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const vuln = useSelector(selectVulnDetail);
  const data = get(vuln, 'data', '');
  const request = get(vuln, 'request', '');
  const response = get(vuln, 'response', '');
  const isWeb = get(vuln, 'type', '') === 'VulnerabilityWeb';

  const updateValue = (field, value) => dispatch(updateVuln(vuln, field, value));

  const readOnly = useSelector(selectReadOnly);
  const permissions = useSelector(selectPermissions);
  const disabled = readOnly || !permissions.update.allowed;

  return (
    <Wrapper>
      <ExpandableWrapper id="data" title={ intl.formatMessage({ id: 'manage.detail.tab.technicalDetails.data' }) }>
        <MarkdownEditor
          value={ data }
          onBlur={ (value) => updateValue('data', value) }
          disabled={ disabled }
        />
      </ExpandableWrapper>
      {isWeb && (
        <>
          <ExpandableWrapper id="request" title={ intl.formatMessage({ id: 'manage.detail.tab.technicalDetails.request' }) }>
            <MarkdownEditor
              value={ request }
              onBlur={ (value) => updateValue('request', value) }
              disabled={ disabled }
            />
          </ExpandableWrapper>
          <ExpandableWrapper id="response" title={ intl.formatMessage({ id: 'manage.detail.tab.technicalDetails.response' }) }>
            <MarkdownEditor
              value={ response }
              onBlur={ (value) => updateValue('response', value) }
              disabled={ disabled }
            />
          </ExpandableWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default TechnicalTab;
