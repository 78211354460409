import humps from 'humps';
import api from 'services/api';
import get from 'lodash/get';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';

export const GET_VULNS_COUNT = 'GET_VULNS_COUNT';
export const GET_VULNS_COUNT_SUCCEED_DASHBOARD = 'GET_VULNS_COUNT_SUCCEED_DASHBOARD';
export const GET_VULNS_COUNT_FAILURE_DASHBOARD = 'GET_VULNS_COUNT_FAILURE_DASHBOARD';

export const GET_TOP_SERVICES = 'GET_TOP_SERVICES';
export const GET_TOP_SERVICES_FAILURE_DASHBOARD = 'GET_TOP_SERVICES_FAILURE_DASHBOARD';
export const GET_TOP_SERVICES_SUCCEED_DASHBOARD = 'GET_TOP_SERVICES_SUCCEED_DASHBOARD';

export const GET_TOP_HOSTS = 'GET_TOP_HOSTS';
export const GET_TOP_HOSTS_FAILURE_DASHBOARD = 'GET_TOP_HOSTS_FAILURE_DASHBOARD';
export const GET_TOP_HOSTS_SUCCEED_DASHBOARD = 'GET_TOP_HOSTS_SUCCEED_DASHBOARD';

export const GET_SUMMARY_DASHBOARD = 'GET_SUMMARY_DASHBOARD';
export const GET_SUMMARY_SUCCEED_DASHBOARD = 'GET_SUMMARY_SUCCEED_DASHBOARD';
export const GET_SUMMARY_FAILURE_DASHBOARD = 'GET_SUMMARY_FAILURE_DASHBOARD';

export const GET_ACTIVITY_FEED = 'GET_ACTIVITY_FEED';
export const GET_ACTIVITY_FEED_SUCCEED_DASHBOARD = 'GET_ACTIVITY_FEED_SUCCEED_DASHBOARD';
export const GET_ACTIVITY_FEED_FAILURE_DASHBOARD = 'GET_ACTIVITY_FEED_FAILURE_DASHBOARD';

export const GET_TIMELINE = 'GET_TIMELINE';
export const GET_TIMELINE_SUCCEED_DASHBOARD = 'GET_TIMELINE_SUCCEED_DASHBOARD';
export const GET_TIMELINE_FAILURE_DASHBOARD = 'GET_TIMELINE_FAILURE_DASHBOARD';

export const GET_LATEST_VULNS_START_DASHBOARD = 'GET_LATEST_VULNS_START_DASHBOARD';
export const GET_LATEST_VULNS_SUCCEED_DASHBOARD = 'GET_LATEST_VULNS_SUCCEED_DASHBOARD';
export const GET_LATEST_VULNS_FAILURE_DASHBOARD = 'GET_LATEST_VULNS_FAILURE_DASHBOARD';

export const GET_LATEST_TAGS_START_DASHBOARD = 'GET_LATEST_TAGS_START_DASHBOARD';
export const GET_LATEST_TAGS_SUCCEED_DASHBOARD = 'GET_LATEST_TAGS_SUCCEED_DASHBOARD';
export const GET_LATEST_TAGS_FAILURE_DASHBOARD = 'GET_LATEST_TAGS_FAILURE_DASHBOARD';

export const GET_VULNS_BY_STATUS_REQUEST = 'GET_VULNS_BY_STATUS_REQUEST';
export const GET_VULNS_BY_STATUS_SUCCESS = 'GET_VULNS_BY_STATUS_SUCCESS';
export const GET_VULNS_BY_STATUS_FAILURE = 'GET_VULNS_BY_STATUS_FAILURE';

export const GET_VULNS_BY_SEVERITY_REQUEST = 'GET_VULNS_BY_SEVERITY_REQUEST';
export const GET_VULNS_BY_SEVERITY_SUCCESS = 'GET_VULNS_BY_SEVERITY_SUCCESS';
export const GET_VULNS_BY_SEVERITY_FAILURE = 'GET_VULNS_BY_SEVERITY_FAILURE';

export const SHOW_MODAL_IMPORT_DASHBOARD = 'SHOW_MODAL_IMPORT_DASHBOARD';

export const RELOAD_FEED = 'RELOAD_FEED';

const getVulnsCountFailureCallback = (error) => ({
  type: GET_VULNS_COUNT_FAILURE_DASHBOARD, error
});

const getVulnsCountSuccedCallback = (data) => ({
  type: GET_VULNS_COUNT_SUCCEED_DASHBOARD, data
});

export function getVulnsCount () {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: GET_VULNS_COUNT });
      const workspace = selectCurrentWorkspace(getState());

      const response = await api.dashboard.getVulnsCountBySeverity(workspace);
      return dispatch(getVulnsCountSuccedCallback(response));
    } catch (e) {
      return dispatch(getVulnsCountFailureCallback(get(e, 'message', 'An error has occurred.')));
    }
  };
}

export function getTopServices () {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: GET_TOP_SERVICES });
      const workspace = selectCurrentWorkspace(getState());
      const response = await api.dashboard.getTopServicesByName(workspace);
      return dispatch({ type: GET_TOP_SERVICES_SUCCEED_DASHBOARD, data: response });
    } catch (e) {
      return dispatch({ type: GET_TOP_SERVICES_FAILURE_DASHBOARD, error: e.message || 'An error has occurred.' });
    }
  };
}

export function getTopHosts () {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: GET_TOP_HOSTS });
      const workspace = selectCurrentWorkspace(getState());
      const response = await api.dashboard.getTopHostsDashboard(workspace);
      return dispatch({ type: GET_TOP_HOSTS_SUCCEED_DASHBOARD, data: response });
    } catch (e) {
      return dispatch({ type: GET_TOP_HOSTS_FAILURE_DASHBOARD, error: e.message || 'An error has occurred.' });
    }
  };
}

export function getSummary () {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: GET_SUMMARY_DASHBOARD });
      const workspace = selectCurrentWorkspace(getState());
      const response = await api.workspace.getWsStats(workspace);
      return dispatch({ type: GET_SUMMARY_SUCCEED_DASHBOARD, data: response });
    } catch (e) {
      return dispatch({ type: GET_SUMMARY_FAILURE_DASHBOARD, error: e.message || 'An error has occurred.' });
    }
  };
}

const getLatestVulnsStartCallback = (data) => ({
  type: GET_LATEST_VULNS_START_DASHBOARD,
  data
});

const getLatestVulnsSuccedCallback = (data) => ({
  type: GET_LATEST_VULNS_SUCCEED_DASHBOARD,
  data
});

const getLatestVulnsFailureCallback = (error) => ({
  type: GET_LATEST_VULNS_FAILURE_DASHBOARD,
  error
});

export function getLatestVulns (confirmed) {
  return async (dispatch, getState) => {
    try {
      dispatch(getLatestVulnsStartCallback());
      const workspace = selectCurrentWorkspace(getState());
      const response = await api.dashboard.fetchLatestVulns(workspace, confirmed);
      return dispatch(getLatestVulnsSuccedCallback(humps.camelizeKeys(response)));
    } catch (e) {
      return dispatch(getLatestVulnsFailureCallback(get(e, 'message', 'Failed to obtain latest vulns')));
    }
  };
}

const getLatestTagsStartCallback = (data) => ({
  type: GET_LATEST_TAGS_START_DASHBOARD,
  data
});

const getLatestTagsSuccedCallback = (data) => ({
  type: GET_LATEST_TAGS_SUCCEED_DASHBOARD,
  data
});

const getLatestTagsFailureCallback = (error) => ({
  type: GET_LATEST_TAGS_FAILURE_DASHBOARD,
  error
});

export function getLatestTags () {
  return async (dispatch, getState) => {
    try {
      dispatch(getLatestTagsStartCallback());
      const workspace = selectCurrentWorkspace(getState());
      const response = await api.dashboard.fetchLatestTags(workspace);
      return dispatch(getLatestTagsSuccedCallback(response));
    } catch (e) {
      return dispatch(getLatestTagsFailureCallback(e));
    }
  };
}

export function getActivityFeed () {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: GET_ACTIVITY_FEED });
      const workspace = selectCurrentWorkspace(getState());
      const response = await api.dashboard.getActivitiesList(workspace);
      return dispatch({ type: GET_ACTIVITY_FEED_SUCCEED_DASHBOARD, data: response });
    } catch (e) {
      return dispatch({ type: GET_ACTIVITY_FEED_FAILURE_DASHBOARD, error: e.message || 'An error has occurred.' });
    }
  };
}

export function getTimeline () {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: GET_TIMELINE });
      const workspace = selectCurrentWorkspace(getState());
      const response = await api.dashboard.getVulnsTimeline(workspace);
      return dispatch({ type: GET_TIMELINE_SUCCEED_DASHBOARD, data: response });
    } catch (e) {
      return dispatch({ type: GET_TIMELINE_FAILURE_DASHBOARD, error: e.message || 'An error has occurred.' });
    }
  };
}
export function showModalImportDashboard (value) {
  return (dispatch) => {
    dispatch({ type: SHOW_MODAL_IMPORT_DASHBOARD, value });
  };
}

export function refreshDashboard () {
  return (dispatch) => {
    dispatch({ type: RELOAD_FEED });
  };
}

export function onGetVulnsByStatusFail (e) {
  return (dispatch) => {
    dispatch({ type: GET_VULNS_BY_STATUS_FAILURE, error: e });
  };
}

export function getVulnsCountByStatus () {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: GET_VULNS_BY_STATUS_REQUEST });
      const workspace = selectCurrentWorkspace(getState());
      const response = await api.dashboard.getVulnsCountByStatus(workspace);
      return dispatch({ type: GET_VULNS_BY_STATUS_SUCCESS, data: response });
    } catch (e) {
      return dispatch(onGetVulnsByStatusFail(get(e, 'message', 'Failed to obtain Vuln count by Status.')));
    }
  };
}

export function onGetVulnsBySeverityFail (e) {
  return (dispatch) => {
    dispatch({ type: GET_VULNS_BY_SEVERITY_FAILURE, error: e });
  };
}

export function getVulnsCountBySeverity () {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: GET_VULNS_BY_SEVERITY_REQUEST });
      const workspace = selectCurrentWorkspace(getState());
      const response = await api.dashboard.getVulnsCountBySeverity(workspace);
      return dispatch({ type: GET_VULNS_BY_SEVERITY_SUCCESS, data: response });
    } catch (e) {
      return dispatch(onGetVulnsBySeverityFail(get(e, 'message', 'Failed to obtain Vuln count by Severity.')));
    }
  };
}
