/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import ActionBar from 'Common/Components/ActionBar';
import ContextMenuWorkspaceMassiveUpdate from 'Screens/Workspaces/components/ContextMenuWorkspaceMassiveUpdate';
import { connect } from 'react-redux';
import selectRoutes, {
  selectWorkspacesCount,
  selectMoreOptionsOpened,
  selectRedirect,
  selectShowDeleteConfirmation,
  selectShowGridView,
  selectShowModalCreate,
  selectWorkspaceList,
  selectWorkspacesSelected
} from 'store/Workspace/selectors';
import { PropTypes } from 'prop-types';
import ContextMenuButton from 'Common/Components/ContextMenuButton';
import IconButton from 'Common/Components/IconButton';
import { ReactComponent as MoreIcon } from 'Images/icon-action-bar-more.svg';
import some from 'lodash/some';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { openModal } from 'store/modals/actions';
import {
  stopRedirect,
  deleteWorkspaceSelected,
  showWorkspaceModalDelete,
  hideWorkspaceModalDelete,
  getData,
  setVisibilityCreateModal,
  showEditModal,
  moreStatusChange,
  setPage
} from 'Screens/Workspaces/actions/Actions';
import { MODAL_WORKSPACE_CREATE_EDIT } from 'store/modals/modals';
import { selectPage, selectRowsPerPage } from 'store/Filters/selectors';
import { setSelectedEntity, resetFilters } from 'store/Filters/actions';
import Table from './Table';
import CreateActions from './CreateActions';
import ExtraPreviousActions from './ExtraPreviousActions';
import NoWorkspaces from './NoWorkspaces/index';
import { Trash, Edit, Wrapper } from './styled';
import GridView from './GridView';

class Workspace extends Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  componentDidMount () {
    this.props.getData();
  }

  componentWillUnmount () {
    const { clearSelectedEntity, resetFilters } = this.props;
    resetFilters();
    clearSelectedEntity();
  }

  render () {
    const {
      workspaceList,
      redirect,
      showDeleteConfirmation,
      workspacesSelected,
      showModalCreate,
      moreOptionsOpened,
      currentWorkspace,
      showGridView,
      page,
      rowsPerPage,
      setPage,
      workspaceCount
    } = this.props;

    if (redirect) {
      this.props.stopRedirect();
      return <Redirect to={ `/manage/${currentWorkspace}` } />;
    }

    let message = '';

    if (showDeleteConfirmation) {
      if (workspacesSelected.length === 1) { message = 'A workspace will be deleted. This operation cannot be undone. Are you sure you want to proceed?'; } else { message = `${workspacesSelected.length} workspaces will be deleted. This operation cannot be undone. Are you sure you want to proceed?`; }
    }

    const contextMenuMasiveUpdate = (
      <ContextMenuWorkspaceMassiveUpdate trigger="massive_update_workspaces_table_nav" onShow={ () => { this.props.moreStatusChange(true); } } />
    );

    const showButton = (workspacesSelected.length > 0);
    const handleOpen = () => {
      this.props.openModal();
      this.props.showEditModal();
    };
    const isCurrentWorkspaceSelected = some(workspacesSelected, { name: currentWorkspace });

    const deleteButton = showButton && !isCurrentWorkspaceSelected ? <IconButton icon={ <Trash /> } title="Delete" onClick={ this.props.showWorkspaceModalDelete } /> : null;
    const editButton = showButton ? <IconButton icon={ <Edit /> } title="Edit" onClick={ handleOpen } /> : null;
    const hasWorkspaces = workspaceList.length > 0;

    const moreOptionsButton = showButton
      ? (
        <ContextMenuButton
          opened={ moreOptionsOpened }
          menu={ contextMenuMasiveUpdate }
          trigger="massive_update_workspaces_table_nav"
          display={ workspacesSelected.length > 0 }
          icon={ <IconButton icon={ <MoreIcon /> } title="More" /> }
        />
        )
      : null;

    return (
      <Wrapper>
        <ActionBar
          showDeleteConfirmation={ showDeleteConfirmation }
          messageDeleteConfirmation={ message }
          handleSubmitDelete={ this.props.deleteWorkspaceSelected }
          handleCloseDelete={ this.props.hideWorkspaceModalDelete }
          createActions={ <CreateActions setVisibilityCreateModal={ this.props.setVisibilityCreateModal } showModalCreate={ showModalCreate } /> }
          deleteButton={ deleteButton }
          editButton={ editButton }
          moreOptionsButton={ moreOptionsButton }
          extraPreviousActions={ hasWorkspaces ? <ExtraPreviousActions /> : null }
          routeList={ this.props.routeList }
          entity="workspace"
          count={ workspacesSelected.length }
          page={ page }
          rowsPerPage={ rowsPerPage }
          totalRows={ workspaceCount }
          onPrev={ () => setPage(page - 1) }
          onNext={ () => setPage(page + 1) }
        />
        { hasWorkspaces && showGridView && <GridView /> }
        { hasWorkspaces && !showGridView && <Table /> }
        { !hasWorkspaces && <NoWorkspaces /> }
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  currentWorkspace: selectCurrentWorkspace(state),
  moreOptionsOpened: selectMoreOptionsOpened(state),
  redirect: selectRedirect(state),
  routeList: selectRoutes(state),
  showDeleteConfirmation: selectShowDeleteConfirmation(state),
  showModalCreate: selectShowModalCreate(state),
  workspaceList: selectWorkspaceList(state),
  workspaceCount: selectWorkspacesCount(state),
  workspacesSelected: selectWorkspacesSelected(state),
  showGridView: selectShowGridView(state),
  page: selectPage('workspaces', state),
  rowsPerPage: selectRowsPerPage('workspaces', state)
});

const mapDispatchToProps = (dispatch) => ({
  clearSelectedEntity: () => {
    dispatch(setSelectedEntity(''));
  },
  resetFilters: () => {
    dispatch(resetFilters('workspaces'));
  },
  setPage: (page) => {
    dispatch(setPage(page));
  },
  getData: () => {
    dispatch(getData());
  },
  stopRedirect: () => {
    dispatch(stopRedirect());
  },
  deleteWorkspaceSelected: () => {
    dispatch(deleteWorkspaceSelected());
  },
  showWorkspaceModalDelete: () => {
    dispatch(showWorkspaceModalDelete());
  },
  hideWorkspaceModalDelete: () => {
    dispatch(hideWorkspaceModalDelete());
  },
  setVisibilityCreateModal: (value) => {
    dispatch(setVisibilityCreateModal(value));
  },
  showEditModal: () => {
    dispatch(showEditModal());
  },
  moreStatusChange: (value) => {
    dispatch(moreStatusChange(value));
  },
  openModal: () => {
    dispatch(openModal(MODAL_WORKSPACE_CREATE_EDIT));
  }
});

Workspace.propTypes = {
  currentWorkspace: PropTypes.string.isRequired,
  deleteWorkspaceSelected: PropTypes.func.isRequired,
  getData: PropTypes.func.isRequired,
  hideWorkspaceModalDelete: PropTypes.func.isRequired,
  moreOptionsOpened: PropTypes.bool.isRequired,
  moreStatusChange: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  redirect: PropTypes.bool.isRequired,
  routeList: PropTypes.arrayOf(PropTypes.object).isRequired,
  setVisibilityCreateModal: PropTypes.func.isRequired,
  showDeleteConfirmation: PropTypes.bool.isRequired,
  showEditModal: PropTypes.func.isRequired,
  showGridView: PropTypes.bool.isRequired,
  showModalCreate: PropTypes.bool.isRequired,
  showWorkspaceModalDelete: PropTypes.func.isRequired,
  stopRedirect: PropTypes.func.isRequired,
  workspaceList: PropTypes.arrayOf(PropTypes.object).isRequired,
  workspacesSelected: PropTypes.arrayOf(PropTypes.object).isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  workspaceCount: PropTypes.number.isRequired,
  clearSelectedEntity: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Workspace);
