/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FiTrash2 as Trash } from 'react-icons/fi';
import PropTypes from 'prop-types';
import {
  BsCloudUpload as Upload
} from 'react-icons/bs';

import {
  Wrapper,
  FilesContainer,
  FilesItem,
  FileName,
  FileSize,
  Icon,
  DragableZoneActive,
  DragableZoneActiveContent,
  Text
} from './styled';

const dropRef = React.createRef();

function handleDrag (e) {
  e.preventDefault();
  e.stopPropagation();
}

const parseFileSize = (size) => {
  if (size < 314572) { // 314572 Bytes == 0.3 MB
    return `${(size / 1024).toFixed(1)} KB`;
  }
  return `${(size / 1048576).toFixed(1)} MB`;
};

class FilesList extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      dragging: false
    };
    this.handleDragIn = this.handleDragIn.bind(this);
    this.handleDragOut = this.handleDragOut.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount () {
    const div = dropRef.current;
    this.dragCounter = 0;
    div.addEventListener('dragenter', this.handleDragIn);
    div.addEventListener('dragleave', this.handleDragOut);
    div.addEventListener('dragover', handleDrag);
    div.addEventListener('drop', this.handleDrop);
  }

  componentWillUnmount () {
    this.dragCounter = 0;
    const div = dropRef.current;
    div.removeEventListener('dragenter', this.handleDragIn);
    div.removeEventListener('dragleave', this.handleDragOut);
    div.removeEventListener('dragover', handleDrag);
    div.removeEventListener('drop', this.handleDrop);
  }

  onChange (e) {
    this.props.handleDrop(e.target.files);
  }

  handleDragIn (e) {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter += 1;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ dragging: true });
    }
  }

  handleDragOut (e) {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter -= 1;
    if (this.dragCounter === 0) {
      this.setState({ dragging: false });
    }
  }

  handleDrop (e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragging: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.props.handleDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  }

  render () {
    const { files } = this.props;
    return (
      <Wrapper ref={ dropRef }>
        <FilesContainer>
          {
            files.map((file) => (
              <FilesItem key={ file.name }>
                <FileName>{file.name}</FileName>
                <Icon onClick={ () => { this.props.removeFile(file); } }><Trash color="#7a7e8f" /></Icon>
                <FileSize>{parseFileSize(file.size)}</FileSize>
              </FilesItem>
            ))
          }
        </FilesContainer>

        {this.state.dragging && (
          <DragableZoneActive onManage>
            <DragableZoneActiveContent>
              <Upload style={ { color: '#0082ed', width: '41px' } } />
              <Text>Drop your files here</Text>
            </DragableZoneActiveContent>
          </DragableZoneActive>
        )
        }
        {!this.state.dragging && <input multiple style={ { opacity: 0, position: 'absolute', zIndex: -1 } } id="upload" type="file" onChange={ this.onChange } /> }

      </Wrapper>
    );
  }
}

FilesList.propTypes = {
  files: PropTypes.array.isRequired,
  removeFile: PropTypes.func.isRequired
};

const mapDispatchToProps = () => ({

});

const mapStateToProps = () => ({

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FilesList));
