import styled from 'styled-components';
import colors from 'Styles/colors';
import {
  FiChevronDown as ArrowDown,
  FiChevronUp as ArrowUp
} from 'react-icons/fi';
import { IoMdCheckmarkCircle as CheckMark } from 'react-icons/io';
import { AiOutlineClose as Close } from 'react-icons/ai';

import { ReactComponent as GeneratingImage } from 'Images/icon_report_generating.svg';

export const Wrapper = styled.div`
  position: absolute;
  background-color: white;
  width: 362px;
  right: 28px;
  bottom: 31px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.24);
  z-index: 2000;
`;
Wrapper.displayName = 'Wrapper';

export const Header = styled.div`
  background-color: ${colors.blueGrey};
  height: 45px;
  padding-top: 10px;
`;
Header.displayName = 'Header';

export const HeaderMessage = styled.div`
  padding-left: 24px;
  color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 264px;
  overflow: hidden;
  display: inline-block;
  text-align: left;
  float: left;
`;
HeaderMessage.displayName = 'HeaderMessage';

export const CustomArrowDown = styled(ArrowDown)`
  width: 25px;
  height: 25px;
  margin-right: 4px;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: ${colors.grey27} !important;
    border-radius: 50%;
  }
`;
CustomArrowDown.displayName = 'CustomArrowDown';

export const CustomArrowUp = styled(ArrowUp)`
  width: 25px;
  height: 25px;
  margin-right: 4px;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: ${colors.grey27} !important;
    border-radius: 50%;
  }
`;
CustomArrowUp.displayName = 'CustomArrowUp';

export const CustomClose = styled(Close)`
  width: 25px;
  height: 25px;
  margin-right: 4px;
  color: white;
  cursor: pointer;
  margin-left: 10px;
  padding: 3px;

  &:hover {
    background-color: ${colors.grey27} !important;
    border-radius: 50%;
  }
`;
CustomClose.displayName = 'CustomClose';

export const Body = styled.div`
  border-bottom: 1px solid #eee;
  max-height: 150px;
  overflow-y: scroll;
  padding: 10px 20px;
`;
Body.displayName = 'Body';

export const FileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  text-align: left;
  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;
FileWrapper.displayName = 'FileWrapper';

export const DocumentIcon = styled.img`
  width: unset;
`;
DocumentIcon.displayName = 'DocumentIcon';

export const FileName = styled.div`
  width: 230px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${colors.dark2};
  font-size: 15px;
  display: inline-block;
  margin-left: 20px;
  vertical-align: bottom;
  margin-bottom: -2px;
`;
FileName.displayName = 'FileName';

export const CustomCheckMark = styled(CheckMark)`
  width: 20px;
  height: 20px;
  color: ${colors.green1};
  margin: auto 0px auto auto;
  cursor: default;
`;
CustomCheckMark.displayName = 'CustomCheckMark';

export const ProcessingIcon = styled(GeneratingImage)`
    cursor: default;
    margin: auto 0px auto auto !important;
    width: 20px;
`;
ProcessingIcon.displayName = 'ProcessingIcon';
