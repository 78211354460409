import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createVulnerability, resetState } from 'Screens/ManageEditCreate/actions/Actions';
import {
  selectNameField,
  selectDescriptionField,
  selectSeverityField,
  selectTargetsField,
  selectRequestField,
  selectResponseField
} from 'store/ManageEditCreate/selectors';
import { Cancel } from 'Common/Components/Button/styled';
import isEmpty from 'lodash/isEmpty';
import { PropTypes } from 'prop-types';
import {
  Wrapper, Title, ModalIcon, ButtonWrapper, CreateButton, NextIcon
} from './styled';

const ManualCreationModal = ({ handleClose, activeTab, setActiveTab }) => {
  const dispatch = useDispatch();

  const name = useSelector(selectNameField);
  const description = useSelector(selectDescriptionField);
  const severity = useSelector(selectSeverityField);
  const targets = useSelector(selectTargetsField);
  const request = useSelector(selectRequestField);
  const response = useSelector(selectResponseField);
  const hasTargets = !isEmpty(targets);

  const validTargets = () => {
    const isVulnWeb = request !== '' && response !== '';
    if (isVulnWeb) return hasTargets && targets.every((t) => (t.type === 'Service'));
    return hasTargets;
  };

  const onHandleClose = () => {
    handleClose();
    setActiveTab('general');
    dispatch(resetState());
  };

  const handleCreateVuln = () => {
    if (validTargets()) {
      dispatch(createVulnerability());
      if (hasTargets) onHandleClose();
    }
  };

  const text = 'Create';
  const action = activeTab === 'assets' ? handleCreateVuln : () => setActiveTab('assets');
  const mandatoryFieldsFilled = !isEmpty(name) && !isEmpty(description) && !isEmpty(severity);
  const enabled = activeTab === 'assets' ? validTargets() : mandatoryFieldsFilled;

  return (
    <Wrapper>
      <Title children="Create New Vulnerability" />
      <ButtonWrapper>
        <Cancel onClick={ onHandleClose } children="Cancel" />
        <CreateButton
          disabled={ !enabled }
          onClick={ enabled ? action : undefined }
        >
          <span children={ text } />
          <NextIcon disabled={ !enabled } />
        </CreateButton>
        <ModalIcon />
      </ButtonWrapper>

    </Wrapper>
  );
};

ManualCreationModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired
};

export default ManualCreationModal;
