import styled from 'styled-components';

const Wrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 26px;
  }
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
