import { ReactComponent as _DeleteEvidenceIcon } from 'Images/icon-trash-red.svg';
import { ReactComponent as ClipboardIcon } from 'Images/copy.svg';
import { ReactComponent as _ImagePlaceholder } from 'Images/unknown_filetype.svg';

import styled from 'styled-components';

export const EvidenceImg = styled.img``;

export const ImagePlaceholder = styled(_ImagePlaceholder)`
margin: 16px 28px;
`;

export const FileName = styled.div`
  width: 140px;
  margin: auto;
  font-size: 13px;
  word-break: break-all;
  max-height: 57px;
  overflow: hidden;
`;

export const Options = styled.div`
  background-color: #ffffff;
  position: absolute;
  right: 7px;
  bottom: 7px;
  padding: 3px;
  border-radius: 4px;
  border: solid 1px #d9e4ef;
  display: none;

  & svg {
    margin: 0 5px;
  }
`;

export const AttachmentWrapper = styled.div``;

export const ImageWrapper = styled.div`
  width: 140px;
  height: 134px;
  border-radius: 3px;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px #dbdbdb;
  background-color: #ffffff;
  padding: 7px;
  margin: 5px 22px;
  cursor: pointer;
  position: relative;

  &:hover{
    background-color: #eeeeee;

    & ${Options}{
      display:block;
    }
  }
`;

export const DeleteEvidenceIcon = styled(_DeleteEvidenceIcon)`
& path{
  fill: #000000;
  stroke: #90a9c0;
}
`;

export const Clipboard = styled(ClipboardIcon)``;
