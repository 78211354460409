import get from 'lodash/get';
import api from 'services/api';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import * as types from 'store/HostDetail/types';
import { redirect } from 'store/Router/actions';
import { setOrderBy } from 'store/Filters/actions';
import { selectShowHostDetail } from './selectors';
import { selectQueryParam } from 'store/Filters/selectors';

const getVulnsSuccedCallback = (data) => ({
  type: types.GET_DATA_VULNS_SUCCESS,
  data: data && data.vulnerabilities.length > 0 ? data.vulnerabilities.map((x) => x.value) : []
});

const getVulnsFailureCallback = (error) => ({
  type: types.GET_DATA_VULNS_FAIL,
  error
});

export function resetHostDetail () {
  return (dispatch) => {
    dispatch({ type: types.RESET_STATE_HOST_DETAIL });
  };
}

export function getVulns (ip) {
  return async (dispatch, getState) => {
    dispatch({ type: types.GET_DATA_VULNS_START });
    const workspaceSelected = selectCurrentWorkspace(getState());
    const filters = [
      {
        name: 'target',
        op: '==',
        val: ip
      }
    ];
    const queryParam = selectQueryParam('vulnsAssets', getState());
    try {
      const response = await api.manage.newGetVulns(workspaceSelected, { ...queryParam, filters });
      return dispatch(getVulnsSuccedCallback(response));
    } catch (e) {
      return dispatch(getVulnsFailureCallback(e));
    }
  };
}

const getServicesSuccedCallback = (data) => ({
  type: types.GET_DATA_SERVICES_SUCCESS,
  data
});

const getServicesFailureCallback = (error) => ({
  type: types.GET_DATA_SERVICES_FAIL,
  error
});

export function getServices (hostId) {
  return async (dispatch, getState) => {
    dispatch({ type: types.GET_DATA_SERVICES_START });
    try {
      const workspace = selectCurrentWorkspace(getState());
      const response = await api.hostDetail.fetchServices(workspace, hostId);
      return dispatch(getServicesSuccedCallback(response));
    } catch (e) {
      return dispatch(getServicesFailureCallback(e));
    }
  };
}

export function getTools () {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.GET_DATA_TOOLS_START });
      const workspace = selectCurrentWorkspace(getState());
      const { host } = getState().hostDetail;
      const data = await api.hostDetail.fetchTools(workspace, host.id);
      return dispatch({ type: types.GET_DATA_TOOLS_SUCCESS, data: data.tools });
    } catch (e) {
      return dispatch({ type: types.GET_DATA_TOOLS_FAIL, e });
    }
  };
}

export function redirectToHostDetail (id) {
  return (dispatch, getState) => {
    const currentWorkspace = selectCurrentWorkspace(getState());
    dispatch(redirect(`/host/${currentWorkspace}/${id}`));
  };
}

export function redirectToHost () {
  return (dispatch, getState) => {
    const currentWorkspace = selectCurrentWorkspace(getState());
    dispatch(redirect(`/host/${currentWorkspace}`));
  };
}

export function showHostDetail (host) {
  return (dispatch) => {
    dispatch({ type: types.SHOW_HOST_DETAIL, host });
  };
}

export function showHostDetailById (id) {
  return async (dispatch, getState) => {
    if (id > 0) {
      const currentWorkspace = selectCurrentWorkspace(getState());
      try {
        const host = await api.hostDetail.fetchById(currentWorkspace, id);
        dispatch(showHostDetail(host));
      } catch (e) {
        dispatch(redirectToHost());
      }
    }
  };
}

export function hideHostDetail () {
  return (dispatch, getState) => {
    const showHostDetail = selectShowHostDetail(getState());
    if (showHostDetail) dispatch({ type: types.HIDE_HOST_DETAIL });
  };
}

export function resetAddEditService () {
  return async (dispatch) => dispatch({ type: types.HOST_ADD_EDIT_SERVICE_RESET });
}

export function addService (name, hostId, status, ports, protocol, version, description, owned) {
  return async (dispatch, getState) => {
    try {
      const { workspaceSelected } = getState().faraday;
      await api.hostDetail.addService(workspaceSelected, hostId, name, status, ports, protocol, version, description, owned);
      dispatch({ type: types.HOST_ADD_EDIT_SERVICE_SUCCESS });

      return dispatch(getServices(hostId));
    } catch (e) {
      const message = get(e, 'message', 'There was an error, please try again.');
      return dispatch({ type: types.HOST_ADD_EDIT_SERVICE_FAIL, message });
    }
  };
}

export function editService (name, hostId, serviceId, status, ports, protocol, version, description, owned) {
  return async (dispatch, getState) => {
    try {
      const { workspaceSelected } = getState().faraday;
      await api.hostDetail.editService(workspaceSelected, serviceId, name, status, ports, protocol, version, description, owned);
      dispatch({ type: types.HOST_ADD_EDIT_SERVICE_SUCCESS });

      return dispatch(getServices(hostId));
    } catch (e) {
      const message = get(e, 'message', 'There was an error, please try again.');
      return dispatch({ type: types.HOST_ADD_EDIT_SERVICE_FAIL, message });
    }
  };
}

export function selectService (selectedRow) {
  return (dispatch) => {
    dispatch({ type: types.SELECT_SERVICE, selectedRow });
  };
}

export function setOrderByVulnsAssets (sorting, ip) {
  return async (dispatch, getState) => {
    const workspaceSelected = selectCurrentWorkspace(getState());
    const filters = [
      {
        name: 'target',
        op: '==',
        val: ip
      }
    ];
    dispatch(setOrderBy('vulnsAssets', sorting));
    const queryParam = selectQueryParam('vulnsAssets', getState());
    const data = await api.manage.newGetVulns(workspaceSelected, { ...queryParam, filters });

    dispatch({
      type: types.GET_DATA_VULNS_SUCCESS,
      data: data && data.vulnerabilities.length > 0 ? data.vulnerabilities.map((x) => x.value) : []
    });
  };

  // return (dispatch) => {
  //   dispatch(setOrderBy('vulnsAssets', sorting));
  //   dispatch(getVulns(ip))
  // };
}
