import { Accept, Cancel } from 'Common/Components/Button/styled';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { setFieldsTool } from 'store/Agents/actions';
import { selectToolsVariables } from 'store/Agents/selectors';
import Card from '../Card';
import Toolbar from '../Toolbar/styled';
import WebSiteButton from '../WebsiteButton';
import {
  Wrapper, Title, Content, WrapperField, Label, Field, WrapperTitle, ButtonsContainer
} from './styled';

const ConfigureTool = ({ tool, onClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const props = useSelector((state) => ({
    variables: selectToolsVariables(state, tool.name)
  }));
  const [fields, setFields] = useState(props.variables);

  // environmentVariables=son las env var de la tool, estan definidas en selector.
  // variables=esta en el reducer y contiene las env var que defnio el usuario en el wizard.
  // TODO: unificar esto. emprolijar.
  const onChange = (e) => {
    // guardo el estado de los campos
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    const newFields = { ...fields };
    newFields[fieldName] = fieldValue;
    setFields(newFields);
  };

  const onSave = () => {
    // persisto en el reducecr los datos.
    dispatch(setFieldsTool(tool.name, fields));
    onClose();
  };

  return (
    <Wrapper>
      <WrapperTitle>
        <Title>{intl.formatMessage({ id: 'agents.createAgent.wizard.chooseTools.toolCollection.configureTool.title' })}</Title>
        <ButtonsContainer>
          <Cancel id="modal-agents-tools-configure-back" onClick={ onClose }>{intl.formatMessage({ id: 'agents.createAgent.wizard.chooseTools.toolCollection.configureTool.back' })}</Cancel>
          <Accept id="modal-agents-tools-configure-save" onClick={ onSave }>{intl.formatMessage({ id: 'agents.createAgent.wizard.chooseTools.toolCollection.configureTool.save' })}</Accept>
        </ButtonsContainer>
      </WrapperTitle>
      <Card key={ tool.title } title={ tool.title } description={ tool.description } image={ tool.image } toolbar={ <Toolbar><WebSiteButton url={ tool.website } /></Toolbar> } />
      <Content>
        {
          tool.environmentVariables.map((item) => (
            <WrapperField key={ item.name }>
              <Label>{ item.name }</Label>
              <Field id={ `modal-agents-tools-configure-${item.name}` } name={ item.name } onChange={ onChange } defaultValue={ props.variables[item.name] } />
            </WrapperField>
          ))
        }
      </Content>
    </Wrapper>
  );
};

export default ConfigureTool;
