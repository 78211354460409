import React from 'react';
import { useDispatch } from 'react-redux';
import AddButton from 'Common/Components/AddButton';
import { openModal } from 'store/modals/actions';
import { MODAL_WORKSPACE_CREATE_EDIT } from 'store/modals/modals';
import { Wrapper } from './styled';

const CreateActions = () => {
  const dispatch = useDispatch();
  const handleOpen = () => dispatch(openModal(MODAL_WORKSPACE_CREATE_EDIT));

  return (
    <Wrapper>
      <AddButton text="Add Workspace" onClick={ handleOpen } />
    </Wrapper>
  );
};

export default CreateActions;
