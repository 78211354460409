import {
  useState, useEffect, useRef, useCallback
} from 'react';
import get from 'lodash/get';

export const useResizeY = (elemRef, resizerRef, def = 0, min = 0, max = 0) => {
  const [isInitialValueSet, initialValueSet] = useState(false);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (!isInitialValueSet && elemRef.current) {
      const initialHeight = get(elemRef, 'current.clientHeight', 0);
      setHeight(() => {
        if (min && min > initialHeight) return min;
        if (def && def < initialHeight) return def;
        if (max && max < initialHeight) return max;
        return initialHeight;
      });
      initialValueSet(true);
    }
  }, [def, isInitialValueSet, max, min, elemRef]);

  const dragging = useRef(false);
  const previousClientY = useRef(0);

  const handleMouseDown = useCallback((e) => {
    document.body.style.userSelect = 'none';
    previousClientY.current = e.clientY;
    dragging.current = true;
  }, []);

  const handleMouseUp = useCallback(() => {
    document.body.style.userSelect = '';
    dragging.current = false;
  }, []);

  const handleMouseMove = useCallback((e) => {
    if (!dragging.current) return;
    setHeight((result) => {
      const change = e.clientY - previousClientY.current;
      previousClientY.current = e.clientY;
      const newHeight = result + change;
      if (min && newHeight < min) return min;
      if (max && newHeight > max) return max;
      return newHeight;
    });
  }, [min, max]);

  useEffect(() => {
    const resizerNodeRef = resizerRef.current;
    if (resizerNodeRef) {
      window.addEventListener('mouseup', handleMouseUp);
      resizerNodeRef.addEventListener('mousedown', handleMouseDown);
      window.addEventListener('mousemove', handleMouseMove);
    }
    return () => {
      if (resizerNodeRef) {
        window.removeEventListener('mouseup', handleMouseUp);
        resizerNodeRef.removeEventListener('mousedown', handleMouseDown);
        window.removeEventListener('mousemove', handleMouseMove);
      }
    };
  }, [resizerRef, handleMouseUp, handleMouseDown, handleMouseMove]);

  return height;
};

export const useResizeX = (elemRef, resizerRef, def = 0, min = 0, max = 0) => {
  const [isInitialValueSet, initialValueSet] = useState(false);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (!isInitialValueSet && elemRef.current) {
      const initialWidth = get(elemRef, 'current.clientWidth', 0);
      setWidth(() => {
        if (min && min > initialWidth) return min;
        if (def && def < initialWidth) return def;
        if (max && max < initialWidth) return max;
        return initialWidth;
      });
      initialValueSet(true);
    }
  }, [def, isInitialValueSet, max, min, elemRef]);

  const dragging = useRef(false);
  const previousClientX = useRef(0);

  const handleMouseDown = useCallback((e) => {
    previousClientX.current = e.clientX;
    dragging.current = true;
  }, []);

  const handleMouseUp = useCallback(() => {
    dragging.current = false;
  }, []);

  const handleMouseMove = useCallback((e) => {
    if (!dragging.current) return;
    setWidth((result) => {
      const change = e.clientX - previousClientX.current;
      previousClientX.current = e.clientX;
      const newWidth = result + change;
      if (min && newWidth < min) return min;
      if (max && newWidth > max) return max;
      return newWidth;
    });
  }, [min, max]);

  useEffect(() => {
    const resizerNodeRef = resizerRef.current;
    if (resizerNodeRef) {
      window.addEventListener('mouseup', handleMouseUp);
      resizerNodeRef.addEventListener('mousedown', handleMouseDown);
      window.addEventListener('mousemove', handleMouseMove);
    }
    return () => {
      if (resizerNodeRef) {
        window.removeEventListener('mouseup', handleMouseUp);
        resizerNodeRef.removeEventListener('mousedown', handleMouseDown);
        window.removeEventListener('mousemove', handleMouseMove);
      }
    };
  }, [resizerRef, handleMouseUp, handleMouseDown, handleMouseMove]);

  return width;
};
