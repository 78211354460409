import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import fileImg from 'Images/generic_file.svg';
import {
  Wrapper,
  SummaryContainer,
  Icon,
  File,
  Filenames,
  SubText,
  ErrorText
} from './styled';

class UploadSummary extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  render () {
    const { files, errorFilesList } = this.props;
    return (
      <Wrapper>
        <SummaryContainer>
          <Icon><File src={ fileImg } alt="File" /></Icon>
          <Filenames>
            { files.map((f) => (errorFilesList.indexOf(f.name) === -1 ? <div>{f.name}</div> : <ErrorText>{f.name}</ErrorText>)) }
          </Filenames>
          <SubText>
            Your files have been uploaded and are being processed. We&apos;ll notify you once this is finished.
          </SubText>
        </SummaryContainer>
      </Wrapper>

    );
  }
}

UploadSummary.propTypes = {
  files: PropTypes.array.isRequired,
  errorFilesList: PropTypes.array.isRequired
};

const mapDispatchToProps = () => ({

});

const mapStateToProps = () => ({

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UploadSummary));
