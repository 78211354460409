import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectCustomAttributes } from 'store/CustomAttributes/selectors';
import CustomField from 'Screens/Manage/components/VulnerabilityDetail/components/Tabs/components/CustomFieldsTab/components/CustomField/index';
import { Wrapper, NoFieldsText } from './styled';

const CustomFieldsTab = () => {
  const intl = useIntl();
  const customFields = useSelector(selectCustomAttributes);
  const hasCustomFields = customFields.length > 0;
  return (
    <Wrapper>
      {customFields.map((field) => <CustomField field={ field } key={ `Custom_Field_${field.id}` } />)}
      {!hasCustomFields && <NoFieldsText>{intl.formatMessage({ id: 'customAttributes.empty' })}</NoFieldsText>}
    </Wrapper>
  );
};

export default CustomFieldsTab;
