import React from 'react';
import SpinnerSrc from 'Images/Spinner.svg';
import { Wrapper, SpinnerIcon } from './styled';

const Spinner = ({ size }) => (
  <Wrapper>
    <SpinnerIcon size={ size } src={ SpinnerSrc } />
  </Wrapper>
);

export default Spinner;
