import styled from 'styled-components';
import colors from 'Styles/colors';

export const Title = styled.div`
  color: ${colors.blue6};
  font-weight: 600;
  font-size: 17px;
  margin: 13px 0;
`;

export const Text = styled.div`
  font-size: 13px;
  font-weight: 100;
  color: ${colors.blueGrey};
`;

export const Br = styled.span`
  color: ${colors.blue6};
  font-weight: 600;
`;

export const NextBtn = styled.button`
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  padding: 8px 20px;
  
  border: none;
  border-radius: 2px;
  background-color: ${colors.blueCerulean}
`;

export const PrevBtn = styled.button`
  color: #1c2243;
  font-weight: 500;
  font-size: 14px;
  padding: 8px 20px;

  border: #63758d solid 1px;
  border-radius: 2px;
  background-color: #ffffff
`;
