import React from 'react';
import { PropTypes } from 'prop-types';
import 'Common/styles/commonStyles.scss';
import { AiOutlineWarning as Warning } from 'react-icons/ai';
import { FaTimes as Close } from 'react-icons/fa';
import colors from 'Styles/colors';
import ModalWrapper from 'Common/Components/ModalWrapper';
import {
  Wrapper, Body, Header, Title, Button, Text
} from './styled';

const ErrorDialog = ({
  visible, resetError, isFetching, message
}) => {
  const handleClose = () => resetError();
  if (!visible) return null;
  return (
    <ModalWrapper>
      <Wrapper>
        <Header>
          <Warning color={ colors.redPink } className="warn-icon" style={ { cursor: 'default' } } />
          <Title children="Faraday" />
          <Close color="Close" onClick={ handleClose } />
        </Header>
        <Body>
          {isFetching
            ? (
            <div className="spinner-border" role="status" />)
            : (<Text>{message}</Text>)}
        </Body>
        <Button onClick={ handleClose } children="Ok" />
      </Wrapper>
    </ModalWrapper>
  );
};

ErrorDialog.propTypes = {
  visible: PropTypes.bool.isRequired,
  resetError: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired
};

export default ErrorDialog;
