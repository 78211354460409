/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { runUserAgentTool } from 'store/Agents/actions';
import { selectTriggerAgentTools, selectIsFetching, selectTriggerAgentWs } from 'store/Agents/selectors';
import { Cancel } from 'Common/Components/Button/styled';
import BlueButton from 'Common/Components/BlueButton';
import ToolParams from 'Screens/Automation/Agents/components/Grid/components/UserAgents/components/RunUserAgentModal/components/ToolParams';
import { closeModal } from 'store/modals/actions';
import { MODAL_RUN_USER_AGENT } from 'store/modals/modals';
import StandardDropdown from 'Common/Components/StandarDropdown';
import {
  Wrapper, Title, Subtitle, WrapperButtons, ModalWrapper
} from './styled';
import WorkspaceSelector from './components/WorkspaceSelector';

const RunUserAgentModal = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const tools = useSelector(selectTriggerAgentTools);
  const mappedTools = tools.map((t) => ({ name: t.name, desc: t.name }));
  const isFetching = useSelector(selectIsFetching);
  const workspaces = useSelector(selectTriggerAgentWs);

  const title = intl.formatMessage({ id: 'agents.triggerAgent.title' });
  const subtitle = intl.formatMessage({ id: 'agents.triggerAgent.subtitle' });
  const cancelLabel = intl.formatMessage({ id: 'agents.triggerAgent.cancel' });
  const runLabel = intl.formatMessage({ id: 'agents.triggerAgent.run' });

  const [currentTool, setCurrentTool] = useState(null);
  const [toolParams, setToolParams] = useState({});

  const onChangeTool = (field, value) => {
    const tool = tools.find((t) => t.name === value);
    if (tool) {
      setToolParams({});
      setCurrentTool(tool);
    }
  };

  const onChangeField = (field, value) => {
    const newParams = { ...toolParams, [field]: value };
    if (value === '') delete newParams[field];
    setToolParams(newParams);
  };

  const disableAccept = () => {
    if (isEmpty(currentTool)) return true;
    if (isEmpty(workspaces)) return true;

    const mandatoryParams = Object.keys(currentTool.parameters_metadata).filter((toolName) => currentTool.parameters_metadata[toolName].mandatory);
    return mandatoryParams.some((toolName) => isEmpty(get(toolParams, toolName, '')));
  };

  const runTool = () => dispatch(runUserAgentTool(currentTool.name, toolParams));
  const onClose = () => dispatch(closeModal(MODAL_RUN_USER_AGENT));

  if (isEmpty(tools)) return null;
  return (
    <ModalWrapper>
      <Wrapper>
        <Title children={ title } />
        <Subtitle children={ subtitle } />
        <StandardDropdown width="170px" field="toolParams" options={ mappedTools } updateValue={ onChangeTool } />
        <ToolParams currentTool={ currentTool } onChangeField={ onChangeField } />
        <WorkspaceSelector currentTool={ currentTool } onChangeField={ onChangeField } />
        <WrapperButtons>
          <Cancel onClick={ onClose } children={ cancelLabel } />
          <BlueButton onClick={ runTool } disabled={ disableAccept() } isLoading={ isFetching } label={ runLabel } />
        </WrapperButtons>
      </Wrapper>
    </ModalWrapper>
  );
};

export default RunUserAgentModal;
