import filter from 'lodash/filter';
import * as types from './types';

export const initialState = {
  isFetching: false,
  error: false,
  errorMessage: '',
  errors: {},
  token: '',
  tokenDuration: 60,
  userAgents: [],
  triggerAgent: {
    tools: [],
    workspaces: []
  },
  agentTypes: {},
  draftNewAgent: {
    name: '',
    server: '',
    tools: []
  }
};

function agents (state = initialState, action) {
  switch (action.type) {
    case types.AGENTS_FETCHING: {
      return {
        ...state,
        isFetching: true,
        error: false,
        errorMessage: '',
        errors: {}
      };
    }

    case types.AGENTS_GET_USER_AGENTS: {
      return {
        ...state,
        isFetching: false,
        error: false,
        userAgents: action.userAgents
      };
    }

    case types.AGENTS_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.errorMessage,
        errors: action.errors
      };
    }

    case types.AGENTS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: false,
        errorMessage: '',
        errors: {}
      };
    }

    case types.AGENTS_GET_TOKEN: {
      return {
        ...state,
        isFetching: false,
        error: false,
        token: action.token,
        tokenExpiresIn: action.tokenExpiresIn,
        tokenDuration: action.tokenDuration
      };
    }

    case types.AGENTS_GET_TOOLS: {
      return {
        ...state,
        isFetching: false,
        error: false,
        triggerAgent: {
          ...state.triggerAgent,
          agentId: action.id,
          tools: action.tools
        }
      };
    }

    case types.AGENTS_RUN_SET_WORKSPACES: {
      return {
        ...state,
        triggerAgent: {
          ...state.triggerAgent,
          workspaces: action.workspaces
        }
      };
    }

    case types.AGENTS_CREATE_WIZARD_RESET: {
      return {
        ...state,
        isFetching: false,
        error: false,
        draftNewAgent: initialState.draftNewAgent
      };
    }

    case types.AGENTS_CREATE_WIZARD_ADD_TOOL: {
      return {
        ...state,
        isFetching: false,
        error: false,
        draftNewAgent: {
          ...state.draftNewAgent,
          tools: [...state.draftNewAgent.tools, action.tool]
        }
      };
    }

    case types.AGENTS_CREATE_WIZARD_REMOVE_TOOL: {
      return {
        ...state,
        isFetching: false,
        error: false,
        draftNewAgent: {
          ...state.draftNewAgent,
          tools: filter(state.draftNewAgent.tools, (e) => (e.name !== action.tool))
        }
      };
    }

    case types.AGENTS_CREATE_WIZARD_SET_FIELDS_TOOL: {
      // se reemplaza el objeto con newitem.
      const newItem = [
        { name: action.toolName, variables: action.fields }
      ];
      const tools = state.draftNewAgent.tools.map((obj) => newItem.find((o) => o.name === obj.name) || obj); // busco el objeto y reemplazo.

      return {
        ...state,
        isFetching: false,
        error: false,
        draftNewAgent: {
          ...state.draftNewAgent,
          tools
        }
      };
    }

    case types.AGENTS_GET_MANIFEST: {
      return {
        ...state,
        agentTypes: action.data
      };
    }

    case types.SET_NEW_AGENT: {
      return {
        ...state,
        draftNewAgent: {
          ...action.data
        }
      };
    }

    case types.RESET_NEW_AGENT: {
      return {
        ...state,
        draftNewAgent: initialState.draftNewAgent
      };
    }

    default: {
      return state;
    }
  }
}

export default agents;
