import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
    width: ${(props) => (props.width ? `${props.width}` : '400px')};
    margin-top: 4px;
    margin-bottom: 20px;    
`;
Wrapper.displayName = 'Wrapper';

export const TextArea = styled.textarea`    
    border-radius: 3px;
    box-shadow: 0 1px 0 0 #afafaf;
    padding: 14px;
    background-color: ${colors.iceBlue};
    font-size: 14.5px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #1c2243;
    width: ${(props) => (props.width ? `${props.width}` : '392px')};
    height: ${(props) => (props.height ? `${props.height}` : '137px')};
    background-color: ${(props) => (props.value ? 'white' : colors.iceBlue)};
    border: 1px solid transparent;

    &::placeholder {
      color: #afb1bc;
      font-size: 13.5px;
      font-weight: 300;
    }

    &:hover{
      border-style: solid;
      border-color: #afb1bc;
      border-width: 1px 1px 0px 1px;
    }

    &:focus{
      background-color: white;

      &::placeholder {
        color: transparent;
      }
    }
`;
TextArea.displayName = 'TextArea';
