import { SET_MODE_HOST_CREATE_UPDATE } from 'store/HostEditCreate/types';

import {
  WORKSPACE_SELECTED_FOR_WORKING,
  GET_WORKSPACES_FOR_WORKING_STARTED,
  GET_WORKSPACES_FOR_WORKING_SUCCESS,
  GET_WORKSPACES_FOR_WORKING_FAILURE,
  STOP_REDIRECT,
  SET_COMMERCIAL_VIEW,
  WORKSPACE_SELECTED,
  WORKSPACE_SELECTED_ALL,
  SHOW_MODAL_DELETE_CONFIRMATION_WORKSPACE,
  HIDE_MODAL_DELETE_CONFIRMATION_WORKSPACE,
  DELETE_WORKSPACES,
  HIDE_MODAL_DELETE_CONFIRMATION_WORKSPACE_SUCCEED,
  GET_DATA_WORKSPACE_START,
  GET_DATA_WORKSPACE_FAIL,
  GET_DATA_WORKSPACE_SUCCESS,
  WORKSPACE_UPDATE_SUCCESS,
  SET_VISIBILITY_CREATE_MODAL_WORKSPACE,
  HIDE_MODAL_CREATE_WORKSPACE_SUCCEED,
  SET_WORKSPACE_FOR_EDIT,
  WORKSPACE_MASSIVE_UPDATE_FAIL,
  WORKSPACE_MASSIVE_UPDATE_SUCCESS,
  MORE_OPTIONS_STATUS_CHANGE_WORKSPACE,
  TOGGLE_INACTIVE_FLAG,
  GET_DATA_WORKSPACES_SUCCESS,
  WORKSPACE_SHOW_GRID_VIEW,
  WORKSPACE_ENABLE_DISABLE
} from '../../Screens/Workspaces/actions/Actions';

import {
  SET_MODE_MANAGE_CREATE_UPDATE
} from '../../Screens/ManageEditCreate/actions/Actions';

const initialState = {
  isFetching: false,
  workspaces: [],
  workspaceSelected: [],
  workspaceList: [],
  allSelected: false,
  error: false,
  errorMessage: '',
  redirect: false,
  mode: '',
  commercialView: '',
  showDeleteConfirmation: false,
  workspacesSelected: [],
  lastSelected: -1,
  lastWorkspaceSelected: {},
  lastIndexForRange: -1,
  showModalCreate: false,
  moreOptionsOpened: false,
  showInactive: false,
  workspaceCount: 0,
  showGridView: false
};

function faraday (state = initialState, action) {
  switch (action.type) {
    case GET_DATA_WORKSPACE_START: {
      return {
        ...state,
        isFetching: true
      };
    }

    case GET_DATA_WORKSPACE_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.data
      };
    }

    case GET_DATA_WORKSPACE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        workspaces: action.data
      };
    }

    case WORKSPACE_SELECTED_FOR_WORKING:
      return { ...state, workspaceSelected: action.workspace /* redirect: action.redirect */ };

    case STOP_REDIRECT:
      return { ...state, redirect: false };

    case GET_WORKSPACES_FOR_WORKING_STARTED:
      return { ...state, isFetching: true };

    case GET_WORKSPACES_FOR_WORKING_SUCCESS:
      return {
        ...state,
        workspaces: action.data,
        workspaceCount: action.data.length,
        isFetching: false
      };

    case GET_WORKSPACES_FOR_WORKING_FAILURE:
      return {
        ...state, error: true, errorMessage: action.data, isFetching: false
      };

    case SET_MODE_MANAGE_CREATE_UPDATE:
    case SET_MODE_HOST_CREATE_UPDATE: {
      return { ...state, mode: action.mode };
    }

    case SET_COMMERCIAL_VIEW: {
      return { ...state, commercialView: action.commercialView };
    }

    case WORKSPACE_SELECTED: {
      const {
        newRowsSelected, lastSelected, indexForRange, rowSelected
      } = action.vulnsSelectedInfo;

      return {
        ...state,
        lastWorkspaceSelected: rowSelected,
        workspacesSelected: newRowsSelected,
        lastSelected: lastSelected || -1,
        lastIndexForRange: indexForRange || -1
      };
    }

    case WORKSPACE_SELECTED_ALL: {
      return {
        ...state,
        allSelected: action.selected,
        workspaceList: action.selected ? state.workspaces.filter((ws) => !ws.readonly) : []
      };
    }

    case SHOW_MODAL_DELETE_CONFIRMATION_WORKSPACE: {
      return {
        ...state,
        showDeleteConfirmation: true
      };
    }

    case HIDE_MODAL_DELETE_CONFIRMATION_WORKSPACE: {
      return {
        ...state,
        showDeleteConfirmation: false
      };
    }

    case DELETE_WORKSPACES: {
      return {
        ...state,
        isFetching: true
      };
    }

    case HIDE_MODAL_DELETE_CONFIRMATION_WORKSPACE_SUCCEED: {
      return {
        ...state,
        showDeleteConfirmation: false,
        workspacesSelected: initialState.workspacesSelected,
        lastWorkspaceSelected: initialState.lastWorkspaceSelected
      };
    }

    case WORKSPACE_UPDATE_SUCCESS: {
      const { workspaceList } = state;
      const indexWorkspaces = workspaceList.findIndex((wspace) => wspace.id === action.ws.id);
      workspaceList[indexWorkspaces] = { ...action.ws };

      return {
        ...state,
        workspaceList,
        isFetching: false
      };
    }

    case SET_VISIBILITY_CREATE_MODAL_WORKSPACE: {
      return {
        ...state,
        showModalCreate: action.value
      };
    }

    case HIDE_MODAL_CREATE_WORKSPACE_SUCCEED: {
      return {
        ...state,
        showModalCreate: false
      };
    }

    case SET_WORKSPACE_FOR_EDIT: {
      return {
        ...state,
        showModalCreate: true
      };
    }

    case WORKSPACE_MASSIVE_UPDATE_FAIL: {
      return {
        ...state,
        error: true,
        errorMessage: action.error
      };
    }

    case WORKSPACE_MASSIVE_UPDATE_SUCCESS: {
      return {
        ...state,
        workspaces: [...action.workspaces]
      };
    }

    case MORE_OPTIONS_STATUS_CHANGE_WORKSPACE: {
      return {
        ...state,
        moreOptionsOpened: action.value
      };
    }

    case TOGGLE_INACTIVE_FLAG: {
      return {
        ...state,
        showInactive: !state.showInactive
      };
    }

    case GET_DATA_WORKSPACES_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        workspaceList: action.data,
        workspacesSelected: initialState.workspacesSelected
      };
    }

    case WORKSPACE_SHOW_GRID_VIEW: {
      return {
        ...state,
        showGridView: action.showGridView
      };
    }

    case WORKSPACE_ENABLE_DISABLE: {
      let readonly = state.readonly;

      if (action.ws === state.workspaceSelected) readonly = !readonly;

      return {
        ...state,
        isFetching: true,
        readonly
      };
    }

    default:
      return state;
  }
}

export default faraday;
