/* eslint-disable react/jsx-key */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Document from 'Images/document.png';
import { hideUploadContextMenu, setVisibilityBody } from './actions';
import {
  Wrapper, Header, HeaderMessage, CustomArrowDown,
  CustomClose, Body, FileWrapper, DocumentIcon,
  FileName, CustomArrowUp,
  CustomCheckMark, ProcessingIcon
} from './styled';

const FileUploadContext = () => {
  const props = useSelector((state) => ({
    showBody: state.fileUploaderContext.showBody,
    commandsInProcess: state.fileUploaderContext.commandsInProcess,
    show: state.fileUploaderContext.commandsInProcess.filter((command) => command.fileType === 'file').length > 0
  }));

  const dispatch = useDispatch();

  const validateVisibility = () => {
    const filesNotProcessed = props.commandsInProcess.filter((file) => !file.finished);
    if (filesNotProcessed.length === 0) dispatch(hideUploadContextMenu());
  };

  useEffect(() => {
    validateVisibility();
    // eslint-disable-next-line
  }, []);

  const getHeaderMessage = () => {
    const inProgressFiles = props.commandsInProcess.filter((file) => !file.finished);

    if (inProgressFiles.length > 0) return `Uploading ${inProgressFiles.length} files`;
    return `${props.commandsInProcess.length} files uploaded`;
  };

  if (props.show) {
    return (
      <Wrapper>
        <Header>
          <HeaderMessage>{getHeaderMessage()}</HeaderMessage>
          {props.showBody ? <CustomArrowDown onClick={ () => dispatch(setVisibilityBody(false)) } /> : <CustomArrowUp onClick={ () => dispatch(setVisibilityBody(true)) } />}
          <CustomClose onClick={ () => dispatch(hideUploadContextMenu()) } />
        </Header>
        {props.showBody
          ? (
          <Body>
            {props.commandsInProcess.map((file) => (
              <FileWrapper>
                <DocumentIcon src={ Document } />
                <FileName>{file.name}</FileName>
                {file.finished ? <CustomCheckMark /> : <ProcessingIcon /> }
              </FileWrapper>
            ))}
          </Body>
            )
          : null }
      </Wrapper>
    );
  }
  return null;
};

export default FileUploadContext;
