import React from 'react';
import Spinner from 'Common/Components/Spinner';
import { Wrapper } from './styled';

const Loader = ({ isFetching }) => (isFetching
  ? (
  <Wrapper>
    <Spinner size={ 60 } loading={ isFetching } />
  </Wrapper>
    )
  : null);

export default Loader;
