import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tour from 'reactour';
import selectTourFinished from 'store/Preferences/Tour/selectors';
import setTourStatus from 'store/Preferences/Tour/actions';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { redirect } from 'store/Router/actions';
import { setSideBarOpen } from 'store/SideBar/actions';
import { NextBtn, PrevBtn } from './styled';
import { manageTourConfig, dashboardTourConfig } from './tourConfig';

export const DashboardTour = () => {
  const [isTourOpen, setTour] = useState(false);
  const dispatch = useDispatch();
  const tourFinished = useSelector((state) => selectTourFinished(state));
  const workspaceSelected = useSelector((state) => selectCurrentWorkspace(state));

  useEffect(() => {
    setTour(!tourFinished);
    if (!tourFinished) dispatch(setSideBarOpen(!tourFinished));
  }, [dispatch, tourFinished]);

  dashboardTourConfig[2].action = () => dispatch(redirect(`/manage/${workspaceSelected}`));

  return (
    <Tour
      onRequestClose={ () => { setTour(false); } }
      steps={ dashboardTourConfig }
      isOpen={ isTourOpen }
      lastStepNextButton={ <NextBtn>Done!</NextBtn> }
      nextButton={ (
        <NextBtn>
          Next tip
          {'>'}
        </NextBtn>
        ) }
      prevButton={ <PrevBtn onClick={ () => setTour(false) }>Exit Tour</PrevBtn> }
      showNavigation={ false }
      showNumber={ false }
      onBeforeClose={ () => dispatch(setTourStatus(true)) }
      showCloseButton={ false }
      startAt={ 1 }
    />
  );
};

export const ManageTour = () => {
  const [isTourOpen, setTour] = useState(false);
  const dispatch = useDispatch();
  const tourFinished = useSelector((state) => selectTourFinished(state));

  useEffect(() => {
    setTour(!tourFinished);
    if (!tourFinished) dispatch(setSideBarOpen(!tourFinished));
  }, [dispatch, tourFinished]);

  return (
    <Tour
      onRequestClose={ () => { setTour(false); } }
      steps={ manageTourConfig }
      isOpen={ isTourOpen }
      lastStepNextButton={ <NextBtn>Done!</NextBtn> }
      nextButton={ (
        <NextBtn>
          Next tip
          {'>'}
        </NextBtn>
          ) }
      prevButton={ <PrevBtn onClick={ () => setTour(false) }>Exit Tour</PrevBtn> }
      showNavigation={ false }
      showNumber={ false }
      onBeforeClose={ () => dispatch(setTourStatus(true)) }
      showCloseButton={ false }
      startAt={ 1 }
    />
  );
};
