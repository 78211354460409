/* eslint-disable react/no-children-prop */
import React from 'react';
import { useIntl } from 'react-intl';
import ExpandableWrapper from '../ExpandableWrapper';
import AddTagButton from './components/AddTagButton';
import { Subtitle, Wrapper } from './styled';

const Tags = () => {
  const intl = useIntl();
  const noTagsLabel = intl.formatMessage({ id: 'manage.detail.tab.general.tags.noTagsSubtitle' });

  return (
    <ExpandableWrapper id="tags" title={ intl.formatMessage({ id: 'manage.detail.tab.general.tags' }) }>
      <Wrapper>
        <Subtitle children={ noTagsLabel } />
        <AddTagButton />
      </Wrapper>
    </ExpandableWrapper>
  );
};

export default Tags;
