import api from 'services/api';
import axios, { CancelToken } from 'axios';
import { selectWorkspaceActivity } from 'store/Preferences/workspace/selectors';
import { updatePreferences } from 'store/Preferences/actions';

import { WORKSPACE_SELECTED_FOR_WORKING } from 'Screens/Faraday/actions/Actions';
import { getWorkspaces } from 'Screens/Workspaces/actions/Actions';

import { SUMMARY } from 'Screens/Wizard/constants';
import { CURRENT_API_VERSION } from 'services/api/connector';
import { redirect } from 'store/Router/actions';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';

export const ADD_WORKSPACE_REQUEST = 'ADD_WORKSPACE_REQUEST';
export const ADD_WORKSPACE_SUCCESS = 'ADD_WORKSPACE_SUCCESS';
export const ADD_WORKSPACE_FAIL = 'ADD_WORKSPACE_FAIL';
export const RESET_STATE_WIZARD = 'RESET_STATE_WIZARD';
export const RESET_INITIAL_STEP_WIZARD = 'RESET_INITIAL_STEP_WIZARD';
export const SET_STEP_WIZARD = 'SET_STEP_WIZARD';
export const SET_WORKSPACE_NAME_WIZARD = 'SET_WORKSPACE_NAME_WIZARD';
export const UPLOAD_REPORT_ERROR_WIZARD = 'UPLOAD_REPORT_ERROR_WIZARD';
export const UPLOAD_REPORT_WIZARD_UPDATE_PERCENT = 'UPLOAD_REPORT_WIZARD_UPDATE_PERCENT';
export const SET_WIZARD_COMMAND_ID = 'SET_WIZARD_COMMAND_ID';
export const RESET_FILE_STATE_WIZARD = 'RESET_FILE_STATE_WIZARD';
export const CANCEL_REQUEST_WIZARD = 'CANCEL_REQUEST_WIZARD';
export const SET_USER_HAS_BEEN_IN_WIZARD = 'SET_USER_HAS_BEEN_IN_WIZARD';
export const SET_SUMMARY_VALUES_IN_WIZARD = 'SET_SUMMARY_VALUES_IN_WIZARD';
export const RESET_STATE_SUMMARY_WIZARD = 'RESET_STATE_SUMMARY_WIZARD';
export const HIDE_ERROR_MODAL_WIZARD = 'HIDE_ERROR_MODAL_WIZARD';

let gcancelTokenSource;

export function resetInitialStep () {
  return (dispatch) => {
    dispatch({
      type: RESET_INITIAL_STEP_WIZARD
    });
  };
}

export function resetState () {
  return (dispatch) => {
    dispatch({ type: RESET_STATE_WIZARD });
  };
}

export function resetSummary () {
  return (dispatch) => {
    dispatch({ type: RESET_STATE_SUMMARY_WIZARD });
  };
}

export function addWorkspace (wsName) {
  return async (dispatch, getState) => {
    let workspaceActivity = selectWorkspaceActivity(getState());

    const workspaceData = {
      _id: wsName,
      customer: '',
      name: wsName,
      type: 'Workspace',
      users: ['faraday'],
      public: false,
      children: [],
      description: '',
      duration: { start_date: '', end_date: '' },
      scope: []
    };

    workspaceActivity = workspaceActivity.filter((workspace) => workspace !== wsName);
    workspaceActivity.unshift(wsName);

    if (workspaceActivity.length > 3) { workspaceActivity = workspaceActivity.slice(0, 3); }

    try {
      dispatch({ type: ADD_WORKSPACE_REQUEST });
      const data = await api.workspace.addWokspace(workspaceData);
      dispatch({ type: ADD_WORKSPACE_SUCCESS, data });
      dispatch(updatePreferences({ workspaceActivity }));
    } catch (e) {
      dispatch({ type: ADD_WORKSPACE_FAIL, data: e.message || (e.messages ? 'Invalid workspace name' : 'An error has occurred') });
    }
  };
}

export function selectWorkspace (ws) {
  return async (dispatch, getState) => {
    const state = getState();
    let workspaceActivity = selectWorkspaceActivity(state);
    if (workspaceActivity.includes(ws)) {
      const otherWorkspaces = workspaceActivity.filter((w) => w !== ws);
      workspaceActivity = [ws, ...otherWorkspaces];
    } else {
      workspaceActivity.unshift(ws);
      if (workspaceActivity.length > 3) {
        workspaceActivity = workspaceActivity.slice(0, 3);
      }
    }
    dispatch(updatePreferences({ workspaceActivity }));
  };
}

export function toggleAutoSelectWs (value) {
  return async (dispatch) => {
    dispatch(updatePreferences({ autoSelectWs: value }));
  };
}

export function setStep (step) {
  return (dispatch) => {
    dispatch({
      type: SET_STEP_WIZARD, step
    });
  };
}

export function setWorkspaceName (ws) {
  return (dispatch) => {
    dispatch({
      type: SET_WORKSPACE_NAME_WIZARD, ws
    });
  };
}

export function uploadFile (file) {
  return async (dispatch, getState) => {
    try {
      const ws = selectCurrentWorkspace(getState());
      const response = await api.faraday.getSession();

      const cancelTokenSource = CancelToken.source();
      gcancelTokenSource = cancelTokenSource;

      const fd = new FormData();
      fd.append('csrf_token', response.csrf_token);
      fd.append('file', file);

      const res = await axios.post(`/_api/${CURRENT_API_VERSION}/ws/${ws}/upload_report`, fd, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: async (progressEvent) => {
          dispatch({ type: UPLOAD_REPORT_WIZARD_UPDATE_PERCENT, progressEvent });
        },
        cancelToken: cancelTokenSource.token
      });
      dispatch({ type: SET_WIZARD_COMMAND_ID, commandId: res.data.command_id });

      const intervalId = setInterval(async () => {
        const responseReport = await api.workspace.getReportStatus(ws, res.data.command_id);

        /* THIS CAN BE DONE USING WEBSOCKETS */
        if (responseReport.duration !== 'In progress' && responseReport.params !== 'In progress') {
          clearInterval(intervalId);

          const filename = responseReport.params.replace(/^.*[\\/]/, '');
          const responseStats = await api.workspace.getWsStats(ws);
          const data = {
            filename,
            vulnCount: responseStats.stats.total_vulns,
            hostsCount: responseStats.stats.hosts,
            servicesCount: responseStats.stats.services
          };

          dispatch({ type: SET_SUMMARY_VALUES_IN_WIZARD, data });
          dispatch({ type: SET_STEP_WIZARD, step: SUMMARY });
          dispatch(getWorkspaces());
        }
      }, 2000);
    } catch (e) {
      if (!e.message || e.message !== 'cancel') dispatch({ type: UPLOAD_REPORT_ERROR_WIZARD, error: e.response && e.response.data.message ? e.response.data.message : 'Couldn\'t upload your file. Please try again.' });
      else dispatch({ type: CANCEL_REQUEST_WIZARD });
    }
  };
}

export function resetFileState () {
  return (dispatch) => {
    dispatch({ type: RESET_FILE_STATE_WIZARD });
  };
}

export function createManually () {
  return async (dispatch, getState) => {
    const ws = selectCurrentWorkspace(getState());
    dispatch({ type: WORKSPACE_SELECTED_FOR_WORKING, workspace: ws, redirect: true });
    dispatch(redirect(`/feed/${ws}`));
  };
}

export function cancelRequest () {
  return async () => {
    if (gcancelTokenSource) {
      gcancelTokenSource.cancel('cancel');
    }
  };
}

export function setUserHasBeenInWizard (value) {
  return (dispatch) => {
    dispatch({ type: SET_USER_HAS_BEEN_IN_WIZARD, value });
  };
}

export function hideWizardErrorModal () {
  return (dispatch) => {
    dispatch({ type: HIDE_ERROR_MODAL_WIZARD });
  };
}
