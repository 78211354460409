import api from 'services/api';
import { updateHostListRow, appendHostListRow, getData } from 'store/Host/actions';
import { showHostDetail } from 'store/HostDetail/actions';
import * as types from 'store/HostEditCreate/types';

export function resetState () {
  return (dispatch) => {
    dispatch({
      type: types.RESET_STATE_HOST_CREATE_UPDATE
    });
  };
}

export function setField (fieldName, value) {
  return (dispatch) => {
    dispatch({
      type: types.SET_FIELD_NAME_HOST_CREATE_UPDATE, fieldName, value
    });
  };
}

export function removeHostname (index) {
  return (dispatch) => {
    dispatch({
      type: types.REMOVE_HOST_NAME_HOST_CREATE_UPDATE, index
    });
  };
}

export function addHostname (value) {
  return (dispatch, getState) => {
    const { hostnames } = getState().hostEditCreate;

    if (hostnames.filter((hname) => hname === value).length === 0) dispatch({ type: types.ADD_HOST_NAME_HOST_CREATE_UPDATE, value });
  };
}

export function setHostName (value) {
  return (dispatch) => {
    dispatch({
      type: types.SET_HOST_NAME_HOST_CREATE_UPDATE, value
    });
  };
}

export function createUpdateHost () {
  return async (dispatch, getState) => {
    const { hostEditCreate } = getState();
    const { workspaceSelected } = getState().faraday;

    const host = {
      id: hostEditCreate.id,
      ip: hostEditCreate.ip,
      owned: hostEditCreate.owned,
      os: hostEditCreate.os,
      mac: hostEditCreate.mac,
      description: hostEditCreate.description,
      hostnames: hostEditCreate.hostnames,
      owner: hostEditCreate.owner,
      default_gateway: hostEditCreate.default_gateway
    };

    try {
      if (hostEditCreate.id) {
        const response = await api.host.updateHost(workspaceSelected, host);
        // if the update was succesful, updates the host list and host detail on the store.
        dispatch(updateHostListRow(response));
        dispatch(showHostDetail(response));
      } else {
        const response = await api.host.createHost(workspaceSelected, host);
        // if the creation was succesful, append the new host to the host list.
        dispatch(appendHostListRow(response));
        dispatch(getData());
      }

      dispatch({ type: types.HOST_CREATE_UPDATE_SUCCESS });
    } catch (e) {
      dispatch({ type: types.HOST_CREATE_UPDATE_FAIL, error: e.message });
    }
  };
}
