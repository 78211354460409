import React from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { MODAL_CUSTOM_ATTRIBUTES } from 'store/modals/modals';
import { closeModal } from 'store/modals/actions';
import {
  Wrapper, Head, Description, Title, CloseIconWrapper, CloseIcon
} from './styled';

const Header = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const title = intl.formatMessage({ id: 'customAttributes.modal.title' });
  const description = intl.formatMessage({ id: 'customAttributes.modal.description' });

  const onClose = () => dispatch(closeModal(MODAL_CUSTOM_ATTRIBUTES));

  return (
    <Wrapper>
      <Head>
        <Title>{ title }</Title>
        <CloseIconWrapper onClick={ onClose }>
          <CloseIcon />
        </CloseIconWrapper>
      </Head>
      <Description>{ description }</Description>
    </Wrapper>
  );
};

export default Header;
