import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomReactTable from 'Common/Components/CustomReactTable/styled';
import { FormattedMessage } from 'react-intl';
import { setOrderByVulnsAssets } from 'store/HostDetail/actions';
import { selectVulns, selectIsFetching, selectFields, selectHostDetail } from 'store/HostDetail/selectors';
import getColumns from './Columns';
import { Wrapper } from './styled';

const Vulnerabilities = () => {
  const isFetching = useSelector(selectIsFetching);
  const vulns = useSelector(selectVulns);
  const fields = useSelector(selectFields);
  const dispatch = useDispatch();
  const data = useSelector(selectHostDetail);

  const customStyle = { height: 'calc(100vh - 306px)' };

  const fetchData = (state) => {
    const { sorted } = state;
    if (!isFetching) {
      dispatch(setOrderByVulnsAssets(sorted, data.ip));
    }
  };

  const mockData = [...Array(15).keys()];

  return (
    <Wrapper>
      <CustomReactTable
        data={ isFetching ? mockData : vulns }
        columns={ getColumns(fields, isFetching) }
        pageSize={ isFetching ? mockData.length : vulns.length }
        minRows={ 0 }
        showPagination={ false }
        noDataText={ !isFetching && <FormattedMessage id="app.nodata" /> }
        style={ customStyle }
        onFetchData={ fetchData }
        manual
      />
    </Wrapper>
  );
};

export default Vulnerabilities;

// {"offset":0,"limit":50,"order_by":[{"field":"confirmed","direction":"desc"},{"field":"severity","direction":"desc"}]}

// {"offset":0,"limit":50,"order_by":[{"field":"severity","direction":"desc"}]}
