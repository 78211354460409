import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { selectIsOpen } from 'store/SideBar/selector';
import { selectLocation } from 'store/Router/selectors';
import useMouseHover from 'Hooks/useMouseHover';
import { redirect } from 'store/Router/actions';
import SideBarSubMenu from '../SideBarSubMenu';
import SubPage from '../SubPage';
import {
  Wrapper, Text, Icon, ItemWrapper
} from './styled';
import get from 'lodash/get';

const SideBarItem = ({ showFF, icon, name, subMenu }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectIsOpen);
  const location = useSelector(selectLocation);
  const [showMenu, setShowMenu] = useState(false);
  const onHide = () => setShowMenu(false);
  const onShow = () => setShowMenu(true);
  const [mouseEnterDebounced, mouseLeaveDebounced] = useMouseHover(onShow, onHide);

  const hasSelectedItem = subMenu.some(({ url }) => url === location.pathname);
  const hasOneChild = subMenu.length === 1;

  const selectFirst = () => {
    const url = get(subMenu, '[0].url', '');
    if (url) dispatch(redirect(url));
    else {
      const FF = get(subMenu, '[0].FF', {});
      showFF(FF);
    }
  };

  const canShowMenu = (((isOpen && !hasOneChild && !hasSelectedItem) || !isOpen) && showMenu);
  const canShowSubPages = (isOpen && hasSelectedItem && !hasOneChild);

  return (
    <Wrapper
      onMouseEnter={ mouseEnterDebounced }
      onMouseLeave={ mouseLeaveDebounced }
    >
      <ItemWrapper selected={ hasSelectedItem || canShowMenu } onClick={ selectFirst } data-tour={ name }>
        <Icon>{icon}</Icon>
        {isOpen && <Text selected={ hasSelectedItem }>{name}</Text>}
      </ItemWrapper>
      { canShowMenu && <SideBarSubMenu showFF={ showFF } items={ subMenu } name={ name } /> }
      { canShowSubPages && subMenu.map(({ FF, name, url, hidden }) => <SubPage showFF={ showFF } FF={ FF } name={ name } url={ url } key={ name } hidden={ hidden } />) }
    </Wrapper>
  );
};

SideBarItem.propTypes = {
  icon: PropTypes.element.isRequired,
  name: PropTypes.string.isRequired,
  subMenu: PropTypes.array.isRequired
};

export default SideBarItem;
