import React from 'react';
import faraday from 'Images/faraday-logo.svg';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import {
  Wrapper, PrimaryTitle, TertiaryTitle, Text, Row, Button, Logo, Container
} from '../../styled';

const About = ({ onClose }) => {
  const intl = useIntl();

  return (
    <Wrapper maxWidth="480px">
      <Logo src={ faraday } alt="Faraday" />
      <PrimaryTitle>
        {intl.formatMessage({ id: 'user.about.title' })}
      </PrimaryTitle>

      <Row>
        <Text>
          {intl.formatMessage({ id: 'user.about.text' })}
        </Text>
      </Row>

      <Container>
        <Row>
          <TertiaryTitle href="https://medium.com/faraday" target="_blank">
            {intl.formatMessage({ id: 'user.about.tertiary-title.new' })}
          </TertiaryTitle>
        </Row>

        <Row>
          <TertiaryTitle href="http://github.com/infobyte/faraday/blob/master/AUTHORS" target="_blank">
            {intl.formatMessage({ id: 'user.about.tertiary-title.credits' })}
          </TertiaryTitle>
        </Row>

        <Row>
          <TertiaryTitle href="https://docs.faradaysec.com" target="_blank">
            {intl.formatMessage({ id: 'user.about.tertiary-title.documentation' })}
          </TertiaryTitle>
        </Row>

      </Container>
      <Button onClick={ onClose }>{intl.formatMessage({ id: 'user.buttton' })}</Button>
    </Wrapper>
  );
};

About.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default About;
