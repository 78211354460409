import fetchApi from 'services/api/connector';

export const newGetVulns = (ws, queryParam) => fetchApi(`ws/${ws}/vulns/filter?q=${encodeURI(JSON.stringify(queryParam))}`);

export const getVulns = (workspace, queryParam) => fetchApi(`ws/${workspace}/vulns/filter?q=${queryParam}`);

export const updateVuln = (ws, vuln) => fetchApi(`ws/${ws}/vulns/${vuln._id}`, { method: 'PATCH', data: vuln, version: 'v3' });

export const exportCSV = (ws, queryParam) => fetchApi(`ws/${ws}/vulns/filter?export_csv=true&q=${encodeURI(JSON.stringify(queryParam))}`);

export const fetchManifest = () => fetchApi('agents/get_manifests');

export const getSearchFilter = () => fetchApi('searchfilter');

export const getTemplates = () => fetchApi('vulnerability_template');

export const fetchById = (ws, id) => fetchApi(`ws/${ws}/vulns/${id}`);

export const createVuln = (ws, data) => fetchApi(`ws/${ws}/vulns`, { method: 'POST', data, version: 'v3' });

export const deleteVulns = (ws, ids) => fetchApi(`ws/${ws}/vulns`, { method: 'DELETE', data: { ids }, version: 'v3' });

export const updateVulns = (ws, data) => fetchApi(`ws/${ws}/vulns`, { method: 'PATCH', data, version: 'v3' });
