import React from 'react';
import MarkdownEditor from 'Common/Components/v2/MarkdownEditor';
import { Wrapper, Title } from './styled';

const MarkdownInput = ({ title, value, onBlur, isRequired }) => {
  return (
    <Wrapper>
      <Title children={ title } isRequired={ isRequired } />
      <MarkdownEditor
        value={ value }
        onBlur={ onBlur }
      />
    </Wrapper>
  );
};

export default MarkdownInput;
