import styled from 'styled-components';
import { ReactComponent as CloudIcon } from 'Images/icon_upload.svg';

export const Wrapper = styled.div`
    
`;
Wrapper.displayName = 'Wrapper';

export const DrageableZone = styled.div`
    padding-top: 33px;
    background-color: #f6f6f8;    
    width: ${(props) => (props.onManage ? '100%' : '340px')};
    height: 205px;
    text-align: ${(props) => (props.onManage ? 'center' : 'unset')};
`;
DrageableZone.displayName = 'DrageableZone';

export const DragableZoneActive = styled.div`
    width: ${(props) => (props.onManage ? '452px' : 'unset')};
    height: ${(props) => (props.onManage ? '425px' : 'unset')};
    border: 1px solid #0082ed;
    background-color: aliceblue;
    position: absolute;
    top: 0;
    bottom: 0;
    left: ${(props) => (props.onManage ? '-2px' : '0')};
    right: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
`;
DragableZoneActive.displayName = 'DragableZoneActive';

export const DragableZoneActiveContent = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    color: grey;
    font-size: 36px;
`;
DragableZoneActiveContent.displayName = 'DragableZoneActiveContent';

export const Text = styled.div`
    color: #0082ed;
    font-size: 14.5px;
    line-height: 21px;
    user-select: none;
`;
Text.displayName = 'Text';

export const TextInactive = styled.div`
    color: #7a7e8f;
    margin: 16px auto 0px auto;
    font-size: 14.5px;
    line-height: 21px;
    font-weight: 600;
    text-align: center;
    user-select: none;
`;
Text.displayName = 'Text';

export const SubTextInactive = styled.div`
    color: #7a7e8f;
    font-size: 14.5px;
    line-height: 21px;
    font-weight: 400;
    margin: auto;
    text-align: center;
    user-select: none;
`;
Text.displayName = 'Text';

export const DragableZoneInActiveContent = styled.div`

`;
DragableZoneInActiveContent.displayName = 'DragableZoneInActiveContent';

export const Button = styled.button`
  width: 89px;
  height: 34px;
  border-radius: 2px;
  background-color: #0082ed;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.21;
  letter-spacing: normal;
  color: #ffffff;
  margin-top: ${(props) => (props.top ? `${props.top}` : '9px')};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  user-select: none;
`;
Button.displayName = 'Button';

export const FileSelectorButton = styled.label`
    display: block;
    width: 89px;
    height: 34px;
    border-radius: 2px;
    background-color: #0082ed;
    border: none;
    color: white;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.21;
    letter-spacing: normal;
    color: #ffffff;
    margin: ${(props) => (props.top ? `${props.top} auto auto auto` : '9px auto auto auto')};
    opacity: ${(props) => (props.disabled ? '0.5' : '1')};
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    padding-top: 2px;
    text-align: center;
    user-select: none;
`;
FileSelectorButton.displayName = 'FileSelectorButton';

export const DropInput = styled.input`
    opacity: 0;
    position: absolute;
    z-index: -1;
`;
DropInput.displayName = 'DropInput';

export const Cloud = styled(CloudIcon)`
    display: block;
    cursor: default;
    width: 41px;
    height: 34px;
    margin: auto;
    & path{
        fill: ${({ color }) => (color || '#7a7e8f')};
    }
`;
