import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { updateVuln } from 'store/Manage/actions';
import {
  selectCurrentExternalId, selectVulnDetail,
  selectReadOnly, selectPermissions
} from 'store/Manage/selectors';
import MarkdownEditor from 'Common/Components/v2/MarkdownEditor';
import ExpandableWrapper from '../ExpandableWrapper';

const ExternalId = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const externalId = useSelector(selectCurrentExternalId);
  const currentVuln = useSelector(selectVulnDetail);

  const updateValue = (field, value) => dispatch(updateVuln(currentVuln, field, value));
  const readOnly = useSelector(selectReadOnly);
  const permissions = useSelector(selectPermissions);
  const disabled = readOnly || !permissions.update.allowed;

  return (
    <ExpandableWrapper id="externalID" title={ intl.formatMessage({ id: 'manage.detail.tab.general.externalID' }) }>
      <MarkdownEditor
        value={ externalId || '' }
        onBlur={ (value) => updateValue('external_id', value) }
        disabled={ disabled }
      />
    </ExpandableWrapper>
  );
};

export default ExternalId;
