import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex: 0 0 46px;
  width: 100%;
  background-color: ${colors.grey19};
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 2px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.03);
  align-items: center;
  flex-direction: row;
  & > *:not(:last-child) {
    /* margin-right: auto; */
  }
`;
Wrapper.displayName = 'Wrapper';

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 22px;
  flex: 1 1 auto;
  overflow: hidden;
  `;
FieldContainer.displayName = 'FieldContainer';

export const FieldName = styled.div`
  color: ${colors.grey12};
  flex: 0 1 0;
  font-size: 13.5px;
  font-weight: 500;
`;
FieldName.displayName = 'FieldName';

export const FieldValue = styled.div`
  flex: 1 1 0;
  padding-left: 5px;
  color: ${colors.white1};
  font-size: 13.5px;
  font-weight: 300;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: sub;
`;
FieldValue.displayName = 'FieldValue';
