import { updatePreferences } from 'store/Preferences/actions';
import { selectActiveWorkspaces } from 'store/Workspace/selectors';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { redirect } from 'store/Router/actions';
import { selectWorkspaceActivity } from 'store/Preferences/workspace/selectors';

export const WORKSPACE_SELECTED_FOR_WORKING = 'WORKSPACE_SELECTED_FOR_WORKING';
export const CURRENT_WORKSPACE_EDITED_UPDATE = 'CURRENT_WORKSPACE_EDITED_UPDATE';
export const GET_WORKSPACES_FOR_WORKING_STARTED = 'GET_WORKSPACES_FOR_WORKING_STARTED';
export const GET_WORKSPACES_FOR_WORKING_SUCCESS = 'GET_WORKSPACES_FOR_WORKING_SUCCESS';
export const GET_WORKSPACES_FOR_WORKING_FAILURE = 'GET_WORKSPACES_FOR_WORKING_FAILURE';
export const STOP_REDIRECT = 'STOP_REDIRECT';
export const SAVE_PREFERENCES_SUCCESS = 'SAVE_PREFERENCES_SUCCESS';
export const SAVE_PREFERENCES_FAIL = 'SAVE_PREFERENCES_FAIL';
export const SET_CURRENT_WORKSPACE_AS_INVALID = 'SET_CURRENT_WORKSPACE_AS_INVALID';
export const SET_SHOW_WORKSPACE_VALIDATOR = 'SET_SHOW_WORKSPACE_VALIDATOR';
export const SET_URL_RETURN = 'SET_URL_RETURN';

export function setUrlReturn (urlReturn) {
  return (dispatch) => {
    dispatch({ type: SET_URL_RETURN, urlReturn });
  };
}

export function updateCurrentWorkspaceOnEdit (newWsName, oldWsName) {
  return (dispatch) => {
    dispatch({
      type: CURRENT_WORKSPACE_EDITED_UPDATE, newWsName, oldWsName, redirect: false
    });
  };
}

export function stopRedirect () {
  return (dispatch) => {
    dispatch({ type: STOP_REDIRECT });
  };
}

// Summary: select a workspace for user to work
export function workspaceSelectedForWorking (workspace) {
  return async (dispatch, getState) => {
    const workspaces = selectActiveWorkspaces(getState());
    if (find(workspaces, { name: workspace })) await dispatch({ type: WORKSPACE_SELECTED_FOR_WORKING, workspace, redirect: false });
  };
}

export function saveWorkspaceHistory (ws) {
  return async (dispatch, getState) => {
    const workspaceActivity = selectWorkspaceActivity(getState());
    let newWsActivity = [];

    if (isEmpty(workspaceActivity)) newWsActivity = [ws];

    else {
      const filteredWs = workspaceActivity.filter((workspace) => workspace !== ws);
      newWsActivity = [ws, ...filteredWs];
      if (newWsActivity.length > 3) newWsActivity = [...newWsActivity.slice(0, 3)];
    }

    dispatch(updatePreferences({ workspaceActivity: newWsActivity }));
    dispatch({ type: WORKSPACE_SELECTED_FOR_WORKING, workspace: ws, redirect: true });
  };
}

export function setCurrentWorkspaceAsInvalid () {
  return (dispatch) => {
    dispatch({ type: SET_CURRENT_WORKSPACE_AS_INVALID });
  };
}

export function setShowWorkspaceValidator (show) {
  return (dispatch) => {
    dispatch({ type: SET_SHOW_WORKSPACE_VALIDATOR, show });
  };
}

export function checkWorkspace (ws) {
  return async (dispatch, getState) => {
    const workspaces = selectActiveWorkspaces(getState());
    const currentWorkspace = selectCurrentWorkspace(getState());

    if (ws && ws !== currentWorkspace) {
      if (workspaces.find((w) => w.name === ws)) {
        dispatch(workspaceSelectedForWorking(ws));
      } else {
        dispatch(redirect('/'));
      }
    }
  };
}
