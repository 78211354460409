import React from 'react';
import PropType from 'prop-types';
import {
  Input, Title, Wrapper, Asterisk, Texts, ErrorMessage, Warning
} from 'Common/Components/StandardTextField/styled';

const StandardTextField = ({
  value, name, disabled, onChange, placeholder, title, mandatory, error, errorMessage, className, boldTitle, type, min, max, step
}) => (
  <Wrapper className={ className }>
    <Texts>
      {title && <Title boldTitle={ boldTitle }>{title}</Title>}
      {mandatory && <Asterisk>*</Asterisk>}
    </Texts>
    <Input
      name={ name }
      value={ value }
      disabled={ disabled }
      onChange={ (e) => onChange(e.target.value) }
      isEmpty={ value.length === 0 }
      placeholder={ placeholder }
      error={ error }
      type={ type }
      min={ min }
      max={ max }
      step={ step }
    />
    {error && <Warning />}
    {error && <ErrorMessage>{ errorMessage }</ErrorMessage>}
  </Wrapper>
);

StandardTextField.defaultProps = {
  disabled: false,
  placeholder: '',
  mandatory: false,
  error: false,
  className: '',
  boldTitle: false,
  type: 'text'
};

StandardTextField.propTypes = {
  value: PropType.string.isRequired,
  name: PropType.string.isRequired,
  disabled: PropType.bool,
  onChange: PropType.func.isRequired,
  placeholder: PropType.string,
  mandatory: PropType.bool,
  error: PropType.bool,
  className: PropType.string,
  boldTitle: PropType.bool,
  type: PropType.string
};

export default StandardTextField;
