import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getVulnsCountByStatus } from 'Screens/Dashboard/actions/Actions';
import { Col, Row } from 'Common/Components/Grid/index';
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer
} from 'recharts';
import { DASHBOARD_FILTERS } from 'store/Filters/constants';
import { setFilter } from 'store/Filters/actions';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import colors from 'Styles/colors';
import { redirect } from 'store/Router/actions';
import { Wrapper, Title, Empty } from './styled';

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload, percent, value
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={ cx } y={ cy } dy={ 8 } textAnchor="middle" fill={ fill }>{payload.name}</text>
      <Sector
        cx={ cx }
        cy={ cy }
        innerRadius={ innerRadius }
        outerRadius={ outerRadius }
        startAngle={ startAngle }
        endAngle={ endAngle }
        fill={ fill }
      />
      <Sector
        cx={ cx }
        cy={ cy }
        startAngle={ startAngle }
        endAngle={ endAngle }
        innerRadius={ outerRadius + 6 }
        outerRadius={ outerRadius + 10 }
        fill={ fill }
      />
      <path d={ `M${sx},${sy}L${mx},${my}L${ex},${ey}` } stroke={ fill } fill="none" />
      <circle cx={ ex } cy={ ey } r={ 2 } fill={ fill } stroke="none" />
      <text x={ ex + (cos >= 0 ? 1 : -1) * 12 } y={ ey } textAnchor={ textAnchor } fill="#333">{value}</text>
      <text x={ ex + (cos >= 0 ? 1 : -1) * 12 } y={ ey } dy={ 18 } textAnchor={ textAnchor } fill="#999">
        {`${(percent * 100).toFixed(1)}%`}
      </text>
    </g>
  );
};

const StatusChart = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const dispatch = useDispatch();
  const props = useSelector((state) => ({
    vulnCountByStatus: state.dashboard.vulnCountByStatus,
    workspaceSelected: selectCurrentWorkspace(state)
  }));

  const data = [];
  if (props.vulnCountByStatus.open > 0) data.push({ id: 'open', name: 'Open', value: props.vulnCountByStatus.open });
  if (props.vulnCountByStatus.closed > 0) data.push({ id: 'closed', name: 'Closed', value: props.vulnCountByStatus.closed });
  if (props.vulnCountByStatus.reopen > 0) data.push({ id: 're-opened', name: 'Re-opened', value: props.vulnCountByStatus.reopen });
  if (props.vulnCountByStatus.riskAccepted > 0) data.push({ id: 'risk-accepted', name: 'Risk Accepted', value: props.vulnCountByStatus.riskAccepted });

  const COLORS = [colors.warmPurple, colors.grey2, colors.redPink, colors.apple];

  useEffect(() => {
    dispatch(getVulnsCountByStatus());
  }, [dispatch, props.workspaceSelected]);

  const onPieEnter = (_data, index) => {
    setActiveIndex(index);
  };

  const onClick = (e) => {
    dispatch(setFilter('vulns', DASHBOARD_FILTERS.vulnerabilitiesByStatus(e.id)));
    dispatch(redirect(`/manage/${props.workspaceSelected}`));
  };

  return (
    <Wrapper style={ { width: '100', height: 250 } }>
      <Row>
        <Col><Title>Vulnerabilities by status</Title></Col>
      </Row>
      { data.length > 0
        ? (
        <ResponsiveContainer width="100%">
          <PieChart>
            <Pie
              activeIndex={ activeIndex }
              activeShape={ renderActiveShape }
              data={ data }
              cx="50%"
              cy="50%"
              innerRadius={ 55 }
              outerRadius={ 75 }
              fill="#8884d8"
              dataKey="value"
              onMouseEnter={ onPieEnter }
              onClick={ onClick }
            >
              {
                data.map((entry, index) => <Cell key={ `cell-${index}` } fill={ COLORS[index % COLORS.length] } />)
              }
            </Pie>
          </PieChart>
        </ResponsiveContainer>
          )
        : <Empty>No vulnerabilities has been found yet.</Empty> }
    </Wrapper>
  );
};

export default StatusChart;
