import get from 'lodash/get';

export const selectIsSaving = (state) => get(state, 'workspaceEditCreate.isSaving', false);
export const selectWorkspaceSaved = (state) => get(state, 'workspaceEditCreate.workspaceSaved', false);
export const selectWsEditCreateName = (state) => get(state, 'workspaceEditCreate.name', '');
export const selectWsEditCreateDescription = (state) => get(state, 'workspaceEditCreate.description', '');
export const selectWsEditCreateUsers = (state) => get(state, 'workspaceEditCreate.users', []);
export const selectWsEditCreateStart = (state) => get(state, 'workspaceEditCreate.start', '');
export const selectWsEditCreateEnd = (state) => get(state, 'workspaceEditCreate.end', '');
export const selectWsEditCreateLocale = (state) => get(state, 'locale', '');
export const selectWsEditCreateErrorMessage = (state) => get(state, 'workspaceEditCreate.errorMessage', '');
export const selectWsEditCreateId = (state) => get(state, 'workspaceEditCreate.id');
export const selectCurrentWsEdition = (state) => get(state, 'workspaceEditCreate.currentWorkspaceEdition', '');
export const selectIsFetching = (state) => get(state, 'workspaceEditCreate.isFetching', false);
