import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  flex: 1 1 0px;
`;
Wrapper.displayName = 'Wrapper';

export const ColumnsAddedList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0;
`;
ColumnsAddedList.displayName = 'ColumnsAddedList';

export const AddedColumn = styled.li`
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items:center;
  border-bottom: 1px dashed #bbc8d6;
  font-size: 13.5px;
  color: #1c2243;
  height: 32px;
  width:95%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  & p>img {
    display: none;
    height: 15px;
    width:auto;
    align-self: center;
    margin-right: 5px;
  }
  & :hover>img {display: block;}

  & p {
    display: flex;
    flex: 1;
  }

  &:hover{
    background-color: #edf2f7;
  }

`;
AddedColumn.displayName = 'AddedColumn';

export const Icon = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 5px;
`;
Icon.displayName = 'Icon';

export const FieldTitleLocked = styled.p`
  opacity: 0.75;
`;
FieldTitleLocked.displayName = 'FieldTitleLocked';

export const FieldTitle = styled.p`
`;
FieldTitle.displayName = 'FieldTitle';
