import React from 'react';
import { useSelector } from 'react-redux';
import { selectToolsSelected } from 'store/Agents/selectors';
import Modal from 'Common/Components/Modal';
import useModal from 'Hooks/useModal';
import Header from '../Header';
import ToolCollection from './components/ToolCollection';
import {
  Wrapper, Title, TextItem, Content, Add, ContentWrapper
} from '../styled';

import AddTools from './styled';
import Toolbar from './components/Toolbar/styled';
import EditButton from './components/EditButton';
import ConfigureButton from './components/ConfigureButton';
import DeleteButton from './components/DeleteButton';
import Card from './components/Card';

const Tools = ({ nextStep, prevStep, onClose }) => {
  const [ModalBrowseTools, showModalBrowseTools, toggleModalBrowseTools] = useModal(Modal);
  const tools = useSelector(selectToolsSelected);
  return (
    <Wrapper>
      <Header back={ prevStep } cancel={ onClose } next={ nextStep } nextDisable={ tools.length === 0 } />
      <ContentWrapper>
        <Title>Selected tools</Title>
        <TextItem>The following tools will process the output data</TextItem>
        <AddTools id="modal-agents-add-tools" onClick={ () => toggleModalBrowseTools(true) }>
          <Add />
          Add tools from our collection
        </AddTools>
        { tools.length > 0 && (
        <Content>
          {
        tools.map((tool) => {
          const toolbar = (
            <Toolbar>
              <EditButton tool={ tool } />
              <ConfigureButton tool={ tool } />
              <DeleteButton tool={ tool.name } />
            </Toolbar>
          );
          return <Card key={ tool.title } title={ tool.title } description={ tool.description } image={ tool.image } toolbar={ toolbar } />;
        })
      }
        </Content>
        )}
        {showModalBrowseTools && <ModalBrowseTools><ToolCollection onClose={ () => toggleModalBrowseTools(false) } /></ModalBrowseTools>}
      </ContentWrapper>
    </Wrapper>
  );
};

export default Tools;
