import React from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CustomCheckbox from 'Common/Components/CustomCheckbox';
import { toggleInactive } from 'Screens/Workspaces/actions/Actions';
import { selectWorkspacesSelected } from 'store/Workspace/selectors';
import { Wrapper, Text } from './styled';

const ShowInactive = ({ pToggleInactive }) => {
  const showInactive = useSelector((state) => (state.workspace.showInactive));
  const workspacesSelected = useSelector((state) => selectWorkspacesSelected(state));

  return (
    <Wrapper ws={ workspacesSelected.length > 0 }>
      <CustomCheckbox label="" handleCheckboxChange={ () => { pToggleInactive(); } } key="showInactive" isChecked={ showInactive } id="test_id_show_inactive" />
      <Text>Show Inactive</Text>
    </Wrapper>
  );
};

ShowInactive.propTypes = {
  pToggleInactive: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  pToggleInactive: () => {
    dispatch(toggleInactive());
  }
});

export default connect(undefined, mapDispatchToProps)(ShowInactive);
