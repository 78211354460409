import React from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setExpandedField } from 'store/Preferences/manage/actions';
import { selectIsFieldExpanded } from 'store/Preferences/manage/selectors';
import {
  Wrapper, Title, ChildWrapper,
  Header, ExpandedIcon, CollapsedIcon, Counter
} from './styled';

const ExpandableWrapper = ({
  children, title, count, id
}) => {
  const dispatch = useDispatch();
  const isExpanded = useSelector((state) => selectIsFieldExpanded(state, id));
  const handleClick = () => dispatch(setExpandedField(id, !isExpanded));

  const showCount = count >= 0;

  return (
    <Wrapper>
      <Header onClick={ handleClick }>
        <Title children={ title } />
        <Counter show={ showCount } children={ count } />
        { isExpanded ? <ExpandedIcon /> : <CollapsedIcon /> }
      </Header>
      <ChildWrapper $isExpanded={ isExpanded } children={ children } />
    </Wrapper>
  );
};

ExpandableWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  count: PropTypes.number,
  title: PropTypes.string,
  children: PropTypes.node.isRequired
};

ExpandableWrapper.defaultProps = {
  count: -1,
  title: ''
};

export default ExpandableWrapper;
