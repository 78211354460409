import styled from 'styled-components';
import { ButtonWithoutBackground, ButtonsContainer } from 'Common/Components/MainContainer/styled';

export const Wrapper = styled.div`
    
`;
Wrapper.displayName = 'Wrapper';

export const BlueText = styled.span`
    color: #419bf9;
    font-weight: 300;
`;
BlueText.displayName = 'BlueText';

export const SummaryContainer = styled.div`
    margin-top: 35px;
`;
SummaryContainer.displayName = 'SummaryContainer';

export const SummaryItem = styled.div`
    font-size: 14.5px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-bottom: 14px;
`;
SummaryItem.displayName = 'SummaryItem';

export const ButtonWithoutBackgroundRight = styled(ButtonWithoutBackground)`
    margin-right: 8px;
    float: right;
`;
ButtonWithoutBackgroundRight.displayName = 'ButtonWithoutBackgroundRight';

export const ButtonsContainerCustom = styled(ButtonsContainer)`
    margin-right: 8px;
    float: right;
    margin-top: 22px;
`;
ButtonsContainerCustom.displayName = 'ButtonsContainerCustom';
