import React from 'react';
import AddButton from 'Common/Components/AddButton';
import { useIntl } from 'react-intl';
import Wrapper from './styled';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/modals/actions';
import { MODAL_UPGRADE_LICENSE_VULN_DETAIL } from 'store/modals/modals';

const AddTagButton = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const addLabel = intl.formatMessage({ id: 'manage.detail.tab.general.tags.add' });
  const onClick = () => dispatch(openModal(MODAL_UPGRADE_LICENSE_VULN_DETAIL));
  return (
    <Wrapper>
      <AddButton text={ addLabel } onClick={ onClick } />
    </Wrapper>
  );
};

export default AddTagButton;
