import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  toggleModalEditCreate
} from 'store/Host/actions';
import { Wrapper, HostIp, Edit } from './styled';

const Ip = () => {
  const dispatch = useDispatch();
  const props = useSelector((state) => ({
    host: state.hostDetail.host
  }));

  return (
    <Wrapper>
      <HostIp>{props.host.ip}</HostIp>
      <Edit onClick={ () => dispatch(toggleModalEditCreate(true, props.host)) } />
    </Wrapper>
  );
};

Ip.propTypes = {
  host: PropTypes.instanceOf(Object).isRequired
};

export default Ip;
