import styled from 'styled-components';
import { Row, Col } from 'Common/Components/Grid';

export const StyledRow = styled(Row)`
  flex-direction: ${(props) => (props.direction ? `${props.direction}` : 'auto')};
  ${({ marginBottom }) => marginBottom && `
    & > *:not(:last-child) {
      margin-bottom: 14px;
    }
  `}
  ${({ marginRight }) => marginRight && `
    & > *:not(:last-child) {
      margin-right: 14px;
    }
  `}
`;
StyledRow.displayName = 'StyledRow';

export const StyledCol = styled(Col)`
  ${({ marginBottom }) => marginBottom && `
    & > *:not(:last-child) {
      margin-bottom: 14px;
    }
  `}
  ${({ marginRight }) => marginRight && `
    & > *:not(:last-child) {
      margin-right: 14px;
    }
  `}
`;
StyledCol.displayName = 'StyledCol';
