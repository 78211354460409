import styled from 'styled-components';

export const Wrapper = styled.div`
  
`;
Wrapper.displayName = 'Wrapper';

export const DataContainer = styled.div`
    width: 406px;
    max-height: 350px;
    border-radius: 2px;
    box-shadow: 2px 2px 30px 0 rgba(0, 0, 0, 0.14);
    background-color: #ffffff;
    overflow-y: auto;
    padding: 18px 0;
    position: absolute;
    z-index: 10;
`;
DataContainer.displayName = 'DataContainer';

export const DataItem = styled.div`
    font-size: 13px;
    color: #1c2243;
    cursor: pointer;
    padding: 7px 5px 7px 20px;
    border-radius: 3px;
`;
DataItem.displayName = 'DataItem';

export const RedAster = styled.span`
    color: #ea3158;
    bottom: 12px;
    position: relative;
    left: 2px;
`;
RedAster.displayName = 'RedAster';
