import {
  HIDE_FILE_UPLOAD_CONTEXT,
  SET_BODY_VISIBILITY_FILE_UPLOAD_CONTEXT,
  ADD_COMMAND_IDS,
  UPDATE_COMMAND_LIST_STATUS
} from 'Common/Components/FileUploadContext/actions';

const initialState = {
  showBody: true,
  commandsInProcess: []
};

function fileUploaderContext (state = initialState, action) {
  switch (action.type) {
    case HIDE_FILE_UPLOAD_CONTEXT: {
      return {
        ...initialState
      };
    }

    case SET_BODY_VISIBILITY_FILE_UPLOAD_CONTEXT: {
      return {
        ...state,
        showBody: action.value
      };
    }

    case ADD_COMMAND_IDS: {
      const { commandsInProcess } = state;
      const newCommands = action.commandIds.concat(commandsInProcess);

      return {
        ...state,
        commandsInProcess: newCommands
      };
    }

    case UPDATE_COMMAND_LIST_STATUS: {
      return {
        ...state,
        commandsInProcess: action.data
      };
    }

    default: {
      return state;
    }
  }
}

export default fileUploaderContext;
