import {
  SET_WORKSPACE_FOR_EDIT,
  CLAN_DATES_WS_CREATION,
  RESET_STATE_WORKSPACE_CREATE_UPDATE,
  WORKSPACE_CREATE_UPDATE_START,
  WORKSPACE_CREATE_UPDATE_FAIL,
  WORKSPACE_CREATE_UPDATE_SUCCESS
} from 'Screens/Workspaces/actions/Actions';

import {
  SET_REDIRECT_HOST_CREATE_UPDATE,
  SET_FIELD_NAME_HOST_CREATE_UPDATE,
  SELECT_CHECK_BOX_HOST_CREATE_UPDATE
} from 'store/HostEditCreate/types';

const initialState = {
  _id: undefined,
  oldName: '',
  name: '',
  description: '',
  start: '',
  end: '',
  scope: '',
  scopeList: [],
  isFetching: false,
  errorMessage: '',
  redirect: false,
  users: [],
  currentWorkspaceEdition: {},
  workspaceSaved: false
};

function workspaceEditCreate (state = initialState, action) {
  switch (action.type) {
    case RESET_STATE_WORKSPACE_CREATE_UPDATE: {
      return {
        ...initialState
      };
    }

    case SET_REDIRECT_HOST_CREATE_UPDATE: {
      return {
        ...state,
        redirect: true,
        importSuccess: false
      };
    }

    case SET_FIELD_NAME_HOST_CREATE_UPDATE: {
      return {
        ...state,
        [action.fieldName]: action.value
      };
    }

    case SELECT_CHECK_BOX_HOST_CREATE_UPDATE: {
      return {
        ...state,
        [action.checkName]: !state[action.checkName]
      };
    }

    case WORKSPACE_CREATE_UPDATE_START: {
      return {
        ...state,
        isFetching: true
      };
    }

    case WORKSPACE_CREATE_UPDATE_FAIL: {
      return {
        ...state,
        isFetching: false,
        errorMessage: action.error,
        workspaceSaved: false
      };
    }

    case WORKSPACE_CREATE_UPDATE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        workspaceSaved: true
      };
    }

    case SET_WORKSPACE_FOR_EDIT: {
      return {
        ...state,
        ...action.workspace,
        start: action.workspace.duration.start_date ? new Date(action.workspace.duration.start_date) : '',
        end: action.workspace.duration.end_date ? new Date(action.workspace.duration.end_date) : '',
        currentWorkspaceEdition: action.workspace,
        oldName: action.workspace.name
      };
    }

    case CLAN_DATES_WS_CREATION: {
      return {
        ...state,
        start: '',
        end: ''
      };
    }

    default:
      return state;
  }
}

export default workspaceEditCreate;
