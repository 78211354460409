import api from 'services/api';
import { showModalImportDashboard } from 'Screens/Dashboard/actions/Actions';
import { selectWorkspacesSelected } from 'store/Workspace/selectors';
import {
  updateCurrentWorkspaceOnEdit as setFaradayWorkspace, workspaceSelectedForWorking as setWorkspaceOnDelete
} from 'Screens/Faraday/actions/Actions';
import { workspaceIsInWorkspaceActivity, removeWorkspaceFromWorkspaceActivity } from 'Screens/Faraday/functions';
import { CURRENT_API_VERSION } from 'services/api/connector';

import { updatePreferences } from 'store/Preferences/actions';
import { selectWorkspaceActivity } from 'store/Preferences/workspace/selectors';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { selectCurrentWsEdition } from 'store/WorkspaceEditCreate/selectors';
import { redirect } from 'store/Router/actions';

import {
  setPageNumber, clearFilters,
  setFilter, setOrderBy
} from 'store/Filters/actions';
import { selectQueryParam } from 'store/Filters/selectors';

export const GET_DATA_WORKSPACE_START = 'GET_DATA_WORKSPACE_START';
export const GET_DATA_WORKSPACE_FAIL = 'GET_DATA_WORKSPACE_FAIL';
export const GET_DATA_WORKSPACE_SUCCESS = 'GET_DATA_WORKSPACE_SUCCESS';
export const WORKSPACE_SELECTED_FOR_WORKING = 'WORKSPACE_SELECTED_FOR_WORKING';
export const GET_WORKSPACES_FOR_WORKING_STARTED = 'GET_WORKSPACES_FOR_WORKING_STARTED';
export const GET_WORKSPACES_FOR_WORKING_SUCCESS = 'GET_WORKSPACES_FOR_WORKING_SUCCESS';
export const GET_WORKSPACES_FOR_WORKING_FAILURE = 'GET_WORKSPACES_FOR_WORKING_FAILURE';
export const WORKSPACE_CREATE_UPDATE_START = 'WORKSPACE_CREATE_UPDATE_START';
export const WORKSPACE_CREATE_UPDATE_SUCCESS = 'WORKSPACE_CREATE_UPDATE_SUCCESS';
export const WORKSPACE_ENABLE_DISABLE = 'WORKSPACE_ENABLE_DISABLE';
export const STOP_REDIRECT = 'STOP_REDIRECT';
export const WORKSPACE_SELECTED_ALL = 'WORKSPACE_SELECTED_ALL';
export const WORKSPACE_SELECTED = 'WORKSPACE_SELECTED';
export const SET_COMMERCIAL_VIEW = 'SET_COMMERCIAL_VIEW';
export const SHOW_MODAL_DELETE_CONFIRMATION_WORKSPACE = 'SHOW_MODAL_DELETE_CONFIRMATION_WORKSPACE';
export const HIDE_MODAL_DELETE_CONFIRMATION_WORKSPACE = 'HIDE_MODAL_DELETE_CONFIRMATION_WORKSPACE';
export const DELETE_WORKSPACES = 'DELETE_WORKSPACES';
export const HIDE_MODAL_DELETE_CONFIRMATION_WORKSPACE_SUCCEED = 'HIDE_MODAL_DELETE_CONFIRMATION_WORKSPACE_SUCCEED';
export const WORKSPACE_UPDATE_SUCCESS = 'WORKSPACE_UPDATE_SUCCESS';
export const SET_VISIBILITY_CREATE_MODAL_WORKSPACE = 'SET_VISIBILITY_CREATE_MODAL_WORKSPACE';
export const HIDE_MODAL_CREATE_WORKSPACE_SUCCEED = 'HIDE_MODAL_CREATE_WORKSPACE_SUCCEED';
export const WORKSPACE_CREATION_ERROR = 'WORKSPACE_CREATION_ERROR';
export const SET_WORKSPACE_FOR_EDIT = 'SET_WORKSPACE_FOR_EDIT';
export const WORKSPACE_MASSIVE_UPDATE_FAIL = 'WORKSPACE_MASSIVE_UPDATE_FAIL';
export const WORKSPACE_MASSIVE_UPDATE_SUCCESS = 'WORKSPACE_MASSIVE_UPDATE_SUCCESS';
export const MORE_OPTIONS_STATUS_CHANGE_WORKSPACE = 'MORE_OPTIONS_STATUS_CHANGE_WORKSPACE';
export const TOGGLE_INACTIVE_FLAG = 'TOGGLE_INACTIVE_FLAG';
export const CLAN_DATES_WS_CREATION = 'CLAN_DATES_WS_CREATION';
export const GET_DATA_WORKSPACES_SUCCESS = 'GET_DATA_WORKSPACES_SUCCESS';
export const WORKSPACE_SHOW_GRID_VIEW = 'WORKSPACE_SHOW_GRID_VIEW';

// from old WorkspaceCreateEdit Actions.js
export const RESET_STATE_WORKSPACE_CREATE_UPDATE = 'RESET_STATE_WORKSPACE_CREATE_UPDATE';
export const WORKSPACE_CREATE_UPDATE_FAIL = 'WORKSPACE_CREATE_UPDATE_FAIL';

export function resetCreationState () {
  return (dispatch) => {
    dispatch({
      type: RESET_STATE_WORKSPACE_CREATE_UPDATE
    });
  };
}

export function getWorkspaces () {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_WORKSPACES_FOR_WORKING_STARTED });
      const data = await api.workspace.fetchWorkspaces({});
      dispatch({ type: GET_WORKSPACES_FOR_WORKING_SUCCESS, data });
    } catch (e) {
      dispatch({ type: GET_WORKSPACES_FOR_WORKING_FAILURE, data: e.message || 'Failed to obtain workspaces.' });
    }
  };
}

// Summary: select a workspace for user to work
export function workspaceSelectedForWorking (workspace) {
  return (dispatch) => {
    dispatch({ type: WORKSPACE_SELECTED_FOR_WORKING, workspace, redirect: true });
  };
}

export function stopRedirect () {
  return (dispatch) => {
    dispatch({ type: STOP_REDIRECT });
  };
}

const getWorkspaceRowsFailureCallback = (error) => ({
  type: GET_DATA_WORKSPACE_FAIL,
  error
});

const getWorkspaceRowsSuccedCallback = (data) => ({
  type: GET_DATA_WORKSPACES_SUCCESS,
  data
});

export function getData () {
  return async (dispatch, getState) => {
    dispatch({ type: GET_DATA_WORKSPACE_START });
    const state = getState();
    const params = selectQueryParam('workspaces', state);
    try {
      const response = await api.workspace.fetchWorkspaces(params);
      return dispatch(getWorkspaceRowsSuccedCallback(response));
    } catch (e) {
      return dispatch(getWorkspaceRowsFailureCallback(e));
    }
  };
}

export function setCommercialView (commercialView) {
  return (dispatch) => {
    dispatch({ type: SET_COMMERCIAL_VIEW, commercialView });
  };
}

export function enableDisable (ws, value) {
  return async (dispatch, getState) => {
    try {
      const currentWorkspace = selectCurrentWorkspace(getState());
      if (currentWorkspace !== ws) {
        dispatch({ type: WORKSPACE_ENABLE_DISABLE });

        const { workspaceList } = getState().workspace;

        if (value) {
          await api.workspace.updateWorkspace(ws, { active: false });
        } else {
          await api.workspace.updateWorkspace(ws, { active: true });
        }

        const newWs = workspaceList.filter((workspace) => workspace.name === ws)[0];
        newWs.active = !value;

        dispatch({ type: WORKSPACE_UPDATE_SUCCESS, ws: newWs });
        dispatch(getWorkspaces());
        dispatch(getData());
      }
    } catch (e) {
      dispatch({ type: WORKSPACE_CREATE_UPDATE_FAIL, data: 'An error has occurred.' });
    }
  };
}

export function changeReadOnly (ws, readonly) {
  return async (dispatch) => {
    try {
      dispatch({ type: WORKSPACE_ENABLE_DISABLE, ws });
      await api.workspace.updateWorkspace(ws, { readonly });
      const newWs = await api.workspace.getWsStats(ws);

      if (newWs) dispatch({ type: WORKSPACE_UPDATE_SUCCESS, ws: newWs });

      dispatch(getWorkspaces());
      dispatch(getData());
    } catch (e) {
      dispatch({ type: WORKSPACE_CREATE_UPDATE_FAIL, error: 'An error has occurred' });
    }
  };
}

// Summary: Add workspace selected to state. // se usa para la tabla
export function selectWorkspace (vulnsSelectedInfo) {
  return (dispatch) => {
    dispatch({
      type: WORKSPACE_SELECTED, vulnsSelectedInfo
    });
  };
}

// Summary: Select all workspaces on the table.
export function selectAll (selected) {
  return (dispatch) => {
    dispatch({ type: WORKSPACE_SELECTED_ALL, selected });
  };
}

// Summary: delete workspace/s from database
export function deleteWorkspaceSelected () {
  return async (dispatch, getState) => {
    const state = getState();
    // TODO: Use selector
    const currentWorkspace = selectCurrentWorkspace(state);
    const workspacesSelected = selectWorkspacesSelected(state);
    let wsActivity = selectWorkspaceActivity(state);
    let workspaceCount = workspacesSelected.length;
    // let workspaceSuccedCount = 0;
    const isDeletingCurrentWorkspace = workspacesSelected.some((wsToDelete) => wsToDelete.name === currentWorkspace);
    dispatch({ type: DELETE_WORKSPACES });

    // TODO: Modify backend, so it should receive an array and return message
    // when some workspaces could not be deleted. Then, add request.
    workspacesSelected.forEach((workspace) => {
      const workspaceToRemove = workspace.name;
      const url = `/_api/${CURRENT_API_VERSION}/ws/${workspaceToRemove}`;

      fetch(url, {
        method: 'DELETE',
        body: {},
        headers: {
          'Content-type': 'application/json'
        }
      }).then(() => {
        workspaceCount -= 1;
        if (workspaceCount === 0) {
          dispatch({ type: HIDE_MODAL_DELETE_CONFIRMATION_WORKSPACE_SUCCEED });
          dispatch(getWorkspaces());
          dispatch(getData());
        }
        if (workspaceIsInWorkspaceActivity(wsActivity, workspaceToRemove)) {
          wsActivity = removeWorkspaceFromWorkspaceActivity(wsActivity, workspaceToRemove);
          dispatch(updatePreferences({ workspaceActivity: wsActivity }));
        }
      }).catch(() => {
        workspaceCount -= 1;

        if (workspaceCount === 0) {
          dispatch({ type: HIDE_MODAL_DELETE_CONFIRMATION_WORKSPACE_SUCCEED });
          dispatch({ type: GET_WORKSPACES_FOR_WORKING_STARTED });
          dispatch(getWorkspaces());
          dispatch(getData());
        }
      });
    });

    if (isDeletingCurrentWorkspace) {
      dispatch(setWorkspaceOnDelete(null));
    }
  };
}

// Summary: Show confirmation modal when user delete workspace/s
export function showWorkspaceModalDelete () {
  return (dispatch) => {
    dispatch({ type: SHOW_MODAL_DELETE_CONFIRMATION_WORKSPACE });
  };
}

// Summary: Hide confirmation modal when user delete workspace/s
export function hideWorkspaceModalDelete () {
  return (dispatch) => {
    dispatch({ type: HIDE_MODAL_DELETE_CONFIRMATION_WORKSPACE });
  };
}

export function createUpdateWorkspace (workspaceEditCreate) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const wsToEdit = selectCurrentWsEdition(state);
      const isCreatingNewWorkspace = !workspaceEditCreate.id;
      const currentWorkspace = selectCurrentWorkspace(getState());
      let wsActivity = selectWorkspaceActivity(state);
      let workspace = {};
      dispatch({ type: WORKSPACE_CREATE_UPDATE_START });

      // eslint-disable-next-line no-useless-escape
      if (/^[a-z0-9][a-z0-9\_\$\(\)\+\-]*$/.test(workspaceEditCreate.name)) {
        if (isCreatingNewWorkspace) {
          workspace = {
            name: workspaceEditCreate.name,
            description: workspaceEditCreate.description,
            duration: {
              start_date: workspaceEditCreate.start ? new Date(workspaceEditCreate.start).getTime() : '',
              end_date: workspaceEditCreate.end ? new Date(workspaceEditCreate.end).getTime() : ''
            }
          };
        } else {
          workspace = {
            name: workspaceEditCreate.name,
            description: workspaceEditCreate.description,
            duration: {
              start_date: workspaceEditCreate.start ? new Date(workspaceEditCreate.start).getTime() : '',
              end_date: workspaceEditCreate.end ? new Date(workspaceEditCreate.end).getTime() : ''
            },
            id: workspaceEditCreate.id,
            active: workspaceEditCreate.active,
            customer: workspaceEditCreate.customer,
            public: workspaceEditCreate.public,
            readonly: workspaceEditCreate.readonly,
            scope: workspaceEditCreate.scope
          };
        }

        if (isCreatingNewWorkspace) {
          await api.workspace.createWorkspace(workspace);
        } else {
          await api.workspace.updateWorkspace(wsToEdit.name, workspace);
        }

        dispatch({ type: WORKSPACE_CREATE_UPDATE_SUCCESS });

        if (!isCreatingNewWorkspace && workspaceIsInWorkspaceActivity(wsActivity, currentWorkspace)) {
          wsActivity = removeWorkspaceFromWorkspaceActivity(wsActivity, currentWorkspace);
          dispatch(updatePreferences({ workspaceActivity: wsActivity }));
        }

        if (wsToEdit.name === currentWorkspace && wsToEdit.name !== workspaceEditCreate.name) {
          dispatch(setFaradayWorkspace(workspaceEditCreate.name, currentWorkspace));
        }

        dispatch({ type: RESET_STATE_WORKSPACE_CREATE_UPDATE });
        dispatch({ type: HIDE_MODAL_CREATE_WORKSPACE_SUCCEED });
        dispatch(getWorkspaces());
        // Remove when add pagination and filter functionality
        return dispatch(getData());
      }
      return dispatch({ type: WORKSPACE_CREATE_UPDATE_FAIL, error: 'Workspace name can not contain white spaces, "/" or uppercase letters.' });
    } catch (e) {
      return dispatch({ type: WORKSPACE_CREATE_UPDATE_FAIL, error: e.message ? e.message : 'An error has occurred.' });
    }
  };
}

export function setVisibilityCreateModal (value) {
  return (dispatch) => {
    dispatch({ type: SET_VISIBILITY_CREATE_MODAL_WORKSPACE, value });

    if (!value) dispatch({ type: RESET_STATE_WORKSPACE_CREATE_UPDATE, value });
  };
}

export function resetErrorMessage () {
  return (dispatch) => dispatch({ type: WORKSPACE_CREATE_UPDATE_FAIL, error: '' });
}

export function showEditModal () {
  return (dispatch, getState) => {
    const { workspacesSelected } = getState().workspace;
    dispatch({ type: SET_WORKSPACE_FOR_EDIT, workspace: workspacesSelected[0] });
  };
}

export function massiveUpdateWorkspacesActive () {
  return async (dispatch, getState) => {
    const { workspacesSelected, workspaces } = getState().workspace;
    const currentWorkspace = selectCurrentWorkspace(getState());

    try {
      const workspacesUpdated = workspaces;

      workspacesSelected.forEach(async (ws) => {
        if (currentWorkspace !== ws.name) {
          const index = workspacesUpdated.findIndex((workspace) => workspace.id === ws.id);
          const wsCopy = ws;
          wsCopy.active = !wsCopy.active;
          workspacesUpdated[index] = wsCopy;

          if (wsCopy.active) {
            await api.workspace.updateWorkspace(wsCopy.name, { active: true });
          } else {
            await api.workspace.updateWorkspace(wsCopy.name, { active: false });
          }
        }
      });

      return dispatch({ type: WORKSPACE_MASSIVE_UPDATE_SUCCESS, workspaces: workspacesUpdated });
    } catch (e) {
      return dispatch({ type: WORKSPACE_MASSIVE_UPDATE_FAIL, error: e.message ? e.message : 'An error has occurred.' });
    }
  };
}

export function moreStatusChange (value) {
  return (dispatch) => {
    dispatch({ type: MORE_OPTIONS_STATUS_CHANGE_WORKSPACE, value });
  };
}
export function toggleInactive () {
  return (dispatch) => {
    dispatch({ type: TOGGLE_INACTIVE_FLAG });
  };
}

export function cleanDates () {
  return (dispatch) => {
    dispatch({ type: CLAN_DATES_WS_CREATION });
  };
}

export function workspaceSelectedFromTable (workspaceName, vulns) {
  return async (dispatch) => {
    dispatch({ type: WORKSPACE_SELECTED_FOR_WORKING, workspace: workspaceName });
    dispatch(redirect(`/feed/${workspaceName}`));

    if (vulns === 0) dispatch(showModalImportDashboard(true));
  };
}

export function setPage (page) {
  return (dispatch) => {
    dispatch(setPageNumber('workspaces', page));
    dispatch(getData());
  };
}

export function setWorkspaceFilter (string) {
  return (dispatch) => {
    const newFilter = { name: 'name', op: 'ilike', val: `%${string}%` };
    if (string) dispatch(setFilter('workspaces', newFilter));
    else dispatch(clearFilters('workspaces'));
    dispatch(getData());
  };
}

export function setOrderByWorkspaces (sorting) {
  return (dispatch) => {
    dispatch(setOrderBy('workspaces', sorting));
    dispatch(getData());
  };
}

export function toogleGridView (showGridView) {
  return (dispatch) => {
    dispatch({
      type: WORKSPACE_SHOW_GRID_VIEW, showGridView
    });
  };
}
