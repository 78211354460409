import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  border: solid 1px #dbdbdb;
  border-radius: 3px;
  box-shadow: 2px 2px 5px 0 rgba(97, 97, 97, 0.07);
`;
Wrapper.displayName = 'Wrapper';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 17px;
  padding-bottom: 0;
  width: 100%;
  border-left: #dbdbdb solid 1px;
`;
Content.displayName = 'Content';

export const Title = styled.div`
  font-size: 13.5px;
  font-weight: 600;
  color: ${colors.black};
  padding-bottom: 5px;
`;
Title.displayName = 'Title';

export const Description = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  line-height: 1.28;
  color: ${colors.grey19};
  padding-bottom: 3px;
`;
Description.displayName = 'Description';

export const Logo = styled.img`
  width: 99px;
  height: 99px;
`;
Logo.displayName = 'Logo';
