import styled from 'styled-components';
import { ReactComponent as EditIcon } from 'Images/icon-action-bar-edit.svg';
import { ReactComponent as TrashIcon } from 'Images/icon-action-bar-trash.svg';

export const Wrapper = styled.div`
  height: 100%;
`;

export const Edit = styled(EditIcon).attrs({
  id: 'qa-edit-ws'
})`
    height: 17px;
  `;

Edit.displayName = 'Edit';

export const Trash = styled(TrashIcon).attrs({
  id: 'qa-delete-ws'
})`
    height: 17px;
  `;

Trash.displayName = 'Trash';
