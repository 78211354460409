import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { CUSTOM_ATTRIBUTE_TYPES as types } from 'Screens/Manage/components/CustomAttributesModal/constants';
import TYPE_TO_CONTENT from 'Screens/Manage/components/VulnerabilityDetail/components/Tabs/components/CustomFieldsTab/contents';
import { setVulnCustomAttribute } from 'store/CustomAttributes/actions';
import { setCustomField } from 'Screens/ManageEditCreate/actions/Actions';

const CustomFieldContent = ({
  field, onCancel, vuln, initialValue, onSaveCallback
}) => {
  const dispatch = useDispatch();
  const onSave = (vuln, fieldName, fieldValue) => {
    if (vuln) {
      dispatch(setVulnCustomAttribute(vuln, fieldName, fieldValue));
    } else {
      dispatch(setCustomField(fieldName, fieldValue));
    }
    if (onSaveCallback) onSaveCallback();
  };
  const Content = TYPE_TO_CONTENT[field.field_type];
  return (
    <Content vuln={ vuln } field={ field } onCancel={ onCancel } onSave={ onSave } initialValue={ initialValue } />
  );
};

CustomFieldContent.propTypes = {
  field: PropTypes.shape({
    field_type: PropTypes.oneOf([types.STRING, types.INTEGER,
      types.LIST, types.MARKDOWN, types.CHOICE]).isRequired
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSaveCallback: PropTypes.func.isRequired,
  vuln: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  initialValue: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)
  ]).isRequired
};

export default CustomFieldContent;
