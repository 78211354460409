import colors from 'Styles/colors';

const severityMapColors = {
  critical: colors.purple3,
  high: colors.redPink,
  info: colors.grey2,
  low: colors.green1,
  med: colors.orange1,
  unclasified: colors.grey4
};

export default severityMapColors;
