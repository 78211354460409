import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUnFinishedCommands } from 'store/FileUploaderContext/selector';
import { updateCommandsStatus } from 'Common/Components/FileUploadContext/actions';

const CommandValidator = () => {
  const dispatch = useDispatch();
  const [previousTimeout, setPreviousTimeout] = useState(null);
  const commands = useSelector((state) => [...getUnFinishedCommands(state)]);

  useEffect(() => {
    if (commands.length > 0 && !previousTimeout) {
      const timeout = setTimeout(() => {
        setPreviousTimeout(null);
        dispatch(updateCommandsStatus());
      }, 3000);
      setPreviousTimeout(timeout);
    }
  }, [commands, dispatch, previousTimeout]);

  return null;
};

export default CommandValidator;
