import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectCurrentlySelectedWorkspace } from 'store/Workspace/selectors';
import {
  PreviewWrapper,
  StyledReactMarkdown,
  PlainText
} from './styled';

const Preview = ({ plainText, isEmpty, text }) => {
  if (plainText) return <PlainText isEmpty={ isEmpty } children={ text } />;
  return <StyledReactMarkdown isEmpty={ isEmpty } children={ text } />;
};

const MDPreview = ({
  plainText, placeholder, disabled, setEditing, markdown
}) => {
  const selectedWorkspace = useSelector(selectCurrentlySelectedWorkspace);

  const parseMD = (textBlock) => {
    // eslint-disable-next-line no-useless-escape
    const regex = /\(evidence\:(\w+)\:(\d+)\:([a-zA-Z0-9\s_\\.\-\(:]+\.\w+)\)/g;

    return textBlock.replace(regex, (match) => {
      const filename = match.split(':')[3].slice(0, -1);
      const content = match.replace(regex, `![$1 With Id $2 Evidence ](/_api/v3/ws/${selectedWorkspace}/vulns/$2/attachment/${filename})`);
      return content;
    });
  };

  const text = () => {
    if (disabled) return 'This field is disabled';
    if (markdown) {
      if (plainText) return markdown;
      return parseMD(markdown);
    }
    return placeholder;
  };

  return (
    <PreviewWrapper disabled={ disabled } onClick={ () => !disabled && setEditing(true) }>
      <Preview plainText={ plainText } isEmpty={ !markdown } text={ text() } />
    </PreviewWrapper>
  );
};

MDPreview.propTypes = {
  setEditing: PropTypes.func.isRequired,
  markdown: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  plainText: PropTypes.bool
};

MDPreview.defaultProps = {
  markdown: '',
  placeholder: '',
  disabled: false,
  plainText: false
};

export default MDPreview;
