export const UPDATE_VULNS_LIST = 'UPDATE_VULNS_LIST';
export const SET_VULNS_FILTER_ERROR = 'SET_VULNS_FILTER_ERROR';
export const SEARCH_VULNS_START = 'SEARCH_VULNS_START';
export const SEARCH_VULNS_FAIL = 'SEARCH_VULNS_FAIL';
export const VULN_SELECTED = 'VULN_SELECTED';
export const VULN_UNSELECTED = 'VULN_UNSELECTED';
export const SHOW_MODAL_DELETE_CONFIRMATION_VULNS = 'SHOW_MODAL_DELETE_CONFIRMATION_VULNS';
export const HIDE_MODAL_DELETE_CONFIRMATION_VULNS = 'HIDE_MODAL_DELETE_CONFIRMATION_VULNS';
export const DELETE_VULNS = 'DELETE_VULNS';
export const HIDE_MODAL_DELETE_CONFIRMATION_VULNS_SUCCEED = 'HIDE_MODAL_DELETE_CONFIRMATION_VULNS_SUCCEED';
export const RESET_STATE_MANAGE = 'RESET_STATE_MANAGE';
export const EXPORT_CSV_MANAGE_SUCCESS = 'EXPORT_CSV_MANAGE_SUCCESS';
export const EXPORT_CSV_MANAGE_FAILURE = 'EXPORT_CSV_MANAGE_FAILURE';
export const CONFIRMATION_CHANGE_START = 'CONFIRMATION_CHANGE_START';
export const CONFIRMATION_CHANGE_SUCCESS = 'CONFIRMATION_CHANGE_SUCCESS';
export const CONFIRMATION_CHANGE_FAIL = 'CONFIRMATION_CHANGE_FAIL';
export const GET_GROUPED_DATA_START = 'GET_GROUPED_DATA_START';
export const GET_GROUPED_DATA_SUCCESS = 'GET_GROUPED_DATA_SUCCESS';
export const RESET_ERROR_VALUE = 'RESET_ERROR_VALUE';
export const SHOW_MANAGE_LEFT_FILTERS = 'SHOW_MANAGE_LEFT_FILTERS';
export const SHOW_VULNERABILITY_DETAIL = 'SHOW_VULNERABILITY_DETAIL';
export const HIDE_VULNERABILITY_DETAIL = 'HIDE_VULNERABILITY_DETAIL';
export const SET_VULNERABILITY_DETAIL_TAB = 'SET_VULNERABILITY_DETAIL_TAB';
export const GET_EVIDENCE_MANAGE_SUCCESS = 'GET_EVIDENCE_MANAGE_SUCCESS';
export const GET_EVIDENCE_MANAGE_ERROR = 'GET_EVIDENCE_MANAGE_ERROR';
export const ATACHEMMENT_ERROR_MANAGE = 'ATACHEMMENT_ERROR_MANAGE';
export const HIDE_ERROR_MESSAGE_ATTACHEMENT = 'HIDE_ERROR_MESSAGE_ATTACHEMENT';
export const UPDATE_VULN_MANAGE_PREVIEW_SUCCESS = 'UPDATE_VULN_MANAGE_PREVIEW_SUCCESS';
export const UPDATE_VULN_MANAGE_PREVIEW_FAILURE = 'UPDATE_VULN_MANAGE_PREVIEW_FAILURE';
export const SELECT_GROUP_MANAGE = 'SELECT_GROUP_MANAGE';
export const SELECT_VULN_GROUP_MANAGE = 'SELECT_VULN_GROUP_MANAGE';
export const MANAGE_SET_BULK_UPDATE_FIELD = 'MANAGE_SET_BULK_UPDATE_FIELD';
export const MANAGE_SET_BULK_UPDATE_VALUE = 'MANAGE_SET_BULK_UPDATE_VALUE';
export const MANAGE_ADD_BULK_UPDATE_VALUE = 'MANAGE_ADD_BULK_UPDATE_VALUE';
export const MANAGE_REMOVE_BULK_UPDATE_VALUE = 'MANAGE_REMOVE_BULK_UPDATE_VALUE';
export const MANAGE_BULK_UPDATE_FINISHED = 'MANAGE_BULK_UPDATE_FINISHED';
export const MANAGE_BULK_SAVE_TEMPLATE_FINISHED = 'MANAGE_BULK_SAVE_TEMPLATE_FINISHED';
export const GET_TEMPLATES_BULK_START = 'GET_TEMPLATES_BULK_START';
export const GET_TEMPLATES_BULK_SUCCESS = 'GET_TEMPLATES_BULK_SUCCESS';
export const GET_TEMPLATES_BULK_FAIL = 'GET_TEMPLATES_BULK_FAIL';
export const GET_VULNS_SUCCESS_MANAGE = 'GET_VULNS_SUCCESS_MANAGE';
export const ADD_MOCK_VULN = 'ADD_MOCK_VULN';
export const REMOVE_MOCK_VULN = 'REMOVE_MOCK_VULN';
export const MANAGE_VULN_ERROR = 'MANAGE_VULN_ERROR';
export const REFRESH_VULNS = 'REFRESH_VULNS';
export const READ_ONLY_ERROR = 'READ_ONLY_ERROR';
export const NEW_PIVOT = 'NEW_PIVOT';
export const SELECT_ALL_VULN = 'SELECT_ALL_VULN';
export const UNSELECT_ALL_VULN = 'UNSELECT_ALL_VULN';
export const SHOW_CONTEXT_MENU = 'SHOW_CONTEXT_MENU';
export const SAVE_TEMPLATE_MANAGE = 'SAVE_TEMPLATE_MANAGE';
export const CLOSE_TEMPLATE_MODAL_MESSAGE = 'CLOSE_TEMPLATE_MODAL_MESSAGE';
