import React from 'react';

let _event;

function useDoubleClick (callback, singleClickCallback) {
  const [elem, setElem] = React.useState(null);
  const countRef = React.useRef(0);
  const timerRef = React.useRef(null);
  const inputCallbackRef = React.useRef(null);
  const callbackRef = React.useCallback((node) => {
    setElem(node);
    callbackRef.current = node;
  }, []);

  React.useEffect(() => {
    inputCallbackRef.current = callback;
  });

  React.useEffect(() => {
    function handler (e) {
      e.stopPropagation();
      e.preventDefault();
      // If we dont use global variable, and instead we use useEffect, _event will be null because timeOut its too short
      _event = e;

      const isDoubleClick = countRef.current + 1 === 2;
      const timerIsPresent = timerRef.current;

      if (timerIsPresent && isDoubleClick) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
        countRef.current = 0;
        if (inputCallbackRef.current) {
          inputCallbackRef.current();
        }
      }
      if (!timerIsPresent) {
        countRef.current += 1;
        const timer = setTimeout(() => {
          clearTimeout(timerRef.current);
          timerRef.current = null;
          countRef.current = 0;

          if (singleClickCallback) {
            singleClickCallback(_event);
          }
        }, 200);
        timerRef.current = timer;
      }
    }
    if (elem) {
      elem.addEventListener('click', handler);
    }
    return () => {
      if (elem) {
        elem.removeEventListener('click', handler);
      }
    };
  }, [elem, singleClickCallback]);

  return [callbackRef, elem];
}

export default useDoubleClick;
