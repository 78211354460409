import React from 'react';
import { Wrapper, Text, Link } from './styled';

const URL = 'https://docs.faradaysec.com/Workspaces/';

const NoWorkspaces = () => (
  <Wrapper>
    <Text>
      You don&apos;t have any workspaces yet, create one to get started or
      <Link href={ URL }> check our documentation</Link>
      .
    </Text>
  </Wrapper>
);

export default NoWorkspaces;
