import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-top: 20px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  overflow: scroll;
`;
Wrapper.displayName = 'Wrapper ';

export default Wrapper;
