import React from 'react';
import PropTypes from 'prop-types';
import Button from 'Common/Components/BlueButton/styled';

const BlueButton = ({
  onClick, label, disabled, className, isLoading, id, children
}) => (
  <Button id={ id } className={ className } onClick={ onClick } disabled={ disabled } type="button" isLoading={ isLoading }>{ label || children }</Button>
);

BlueButton.defaultProps = {
  disabled: false,
  isLoading: false,
  className: '',
  id: ''
};

BlueButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string
};

export default BlueButton;
