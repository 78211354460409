/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import MainContainer from 'Common/Components/MainContainer';
import StepFileSelector from 'Screens/Wizard/components/StepFileSelector';
import StepInitialMode from 'Screens/Wizard/components/StepInitialMode';
import StepSummary from 'Screens/Wizard/components/StepSummary';
import StepWorkspaceName from 'Screens/Wizard/components/StepWorkspaceName';
import StepWorkspaceSelection from 'Screens/Wizard/components/StepWorkspaceSelection';
import {
  FILE_SELECTOR, INITIAL_MODE, SUMMARY, WORKSPACE_NAME, WORKSPACE_SELECTION
} from 'Screens/Wizard/constants';
import {
  resetInitialStep, setStep, setWorkspaceName, setUserHasBeenInWizard, hideWizardErrorModal
} from 'Screens/Wizard/actions/Actions';
import { connect } from 'react-redux';
import ErrorDialog from 'Common/Components/ErrorDialog';

class Main extends Component {
  constructor (props) {
    super(props);
    this.setStep = this.setStep.bind(this);
  }

  componentDidMount () {
    const { userHasBeenInWizard, workspaces } = this.props;

    if (userHasBeenInWizard) {
      if (workspaces.length > 0) this.props.setStep(WORKSPACE_SELECTION);
      else this.props.setStep(WORKSPACE_NAME);
    } else this.props.setUserHasBeenInWizard(true);
  }

  setStep (step) {
    this.props.setStep(step);
  }

  setWorkspaceName (workspaceName) {
    this.props.setWorkspaceName({ workspaceName });
  }

  render () {
    const {
      step, width, height, error, errorMessage, hideErrorModal
    } = this.props;

    let child;

    switch (step) {
      case WORKSPACE_NAME:
        child = <StepWorkspaceName onStep={ this.setStep } setWorkspaceName={ this.setWorkspaceName } />;
        break;
      case FILE_SELECTOR:
        child = <StepFileSelector onStep={ this.setStep } />;
        break;
      case INITIAL_MODE:
        child = <StepInitialMode onStep={ this.setStep } />;
        break;
      case SUMMARY:
        child = <StepSummary onStep={ this.setStep } />;
        break;
      case WORKSPACE_SELECTION:
        child = <StepWorkspaceSelection onStep={ this.setStep } />;
        break;
      default:
        child = <StepWorkspaceName onStep={ this.setStep } />;
        break;
    }

    return (
      <MainContainer width={ width } height={ height }>
        {child}
        <ErrorDialog visible={ error } message={ errorMessage } resetError={ hideErrorModal } />
      </MainContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  step: state.wizard.step,
  width: state.wizard.width,
  height: state.wizard.height,
  workspaceName: state.wizard.workspaceName,
  userHasBeenInWizard: state.wizard.userHasBeenInWizard,
  workspaces: state.faraday.workspaces,
  error: state.wizard.error,
  errorMessage: state.wizard.errorMessage
});

const mapDispatchToProps = (dispatch) => ({
  resetInitialStep: () => {
    dispatch(resetInitialStep());
  },
  setStep: (step, width, height) => {
    dispatch(setStep(step, width, height));
  },
  setWorkspaceName: (ws) => {
    dispatch(setWorkspaceName(ws));
  },
  setUserHasBeenInWizard: (value) => {
    dispatch(setUserHasBeenInWizard(value));
  },
  hideErrorModal: () => {
    dispatch(hideWizardErrorModal());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
