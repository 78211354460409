import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  grid-row: 1/2;
  padding: 20px 19px 30px 20px;
`;
Header.displayName = 'Header';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;
Wrapper.displayName = 'Wrapper';
