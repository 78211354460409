import fetchApi from 'services/api/connector';

export const addWokspace = (data) => fetchApi('ws', { method: 'POST', data });

export const getReportStatus = (ws, commandId) => fetchApi(`ws/${ws}/commands/${commandId}`);

export const getWsStats = (ws) => fetchApi(`ws/${ws}`);

export const createWorkspace = (data) => fetchApi('ws', { method: 'POST', data });

export const updateWorkspace = (ws, data) => fetchApi(`ws/${ws}`, { method: 'PATCH', data });

export const fetchWorkspaces = (queryParam) => fetchApi(`ws/filter?q=${escape(JSON.stringify(queryParam))}&histogram=true`);
