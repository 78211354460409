import get from 'lodash/get';
import initialState from './initialState';

export const selectMapGroupByIndex = (state) => get(state, 'host.mapGroupByIndex', initialState.mapGroupByIndex);
export const selectHostList = (state) => get(state, 'host.hostList', initialState.hostList);
export const selectHostCount = (state) => get(state, 'host.hostCount', initialState.hostCount);
export const selectHostsSelected = (state) => get(state, 'host.hostsSelected', initialState.hostsSelected);
export const selectIsFetching = (state) => get(state, 'host.isFetching', initialState.isFetching);
export const selectShowDeleteConfirmation = (state) => get(state, 'host.showDeleteConfirmation', initialState.showDeleteConfirmation);
export const selectShowModalEditCreate = (state) => get(state, 'host.showModalEditCreate', initialState.showModalEditCreate);
export const selectAddColumnsOpened = (state) => get(state, 'host.addColumnsOpened', initialState.addColumnsOpened);
export const selectShowHostLeftFilters = (state) => get(state, 'host.showLeftFilters', false);
export const selectLastSelected = (state) => get(state, 'host.lastSelected', -1);
export const allHostsAreSelected = (state) => {
  const hosts = selectHostList(state);
  const hostList = selectHostsSelected(state);
  return hosts.every((testHost) => hostList.some((host) => host._id === testHost._id));
};
