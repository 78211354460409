import api from 'services/api';
import { closeModal } from 'store/modals/actions';
import { MODAL_RUN_USER_AGENT } from 'store/modals/modals';
import { selectActiveWorkspaces } from 'store/Workspace/selectors';
import * as types from './types';
import { selectTriggerAgentId, selectTriggerAgentWs } from './selectors';

function fetching () {
  return async (dispatch) => {
    dispatch({ type: types.AGENTS_FETCHING });
  };
}

function somethingWentWrong (errors) {
  return async (dispatch) => {
    dispatch({ type: types.AGENTS_FAIL, errorMessage: 'There was an error, please try again.', errors });
  };
}

export function getUserAgents () {
  return async (dispatch) => {
    try {
      dispatch(fetching());
      const userAgents = await api.agents.fetchUserAgents();
      dispatch({ type: types.AGENTS_GET_USER_AGENTS, userAgents });
    } catch (e) {
      dispatch(somethingWentWrong());
    }
  };
}

export function removeAgent (agent) {
  return async (dispatch) => {
    try {
      dispatch(fetching());
      await api.agents.removeAgent(agent.id);
      dispatch(getUserAgents());
    } catch (e) {
      dispatch(somethingWentWrong());
    }
  };
}

export function getToken () {
  return async (dispatch) => {
    try {
      dispatch(fetching());
      const responseSession = await api.faraday.getSession();

      const fd = new FormData();
      fd.set('csrf_token', responseSession.csrf_token);

      const responseToken = await api.agents.fetchToken(fd);
      dispatch({
        type: types.AGENTS_GET_TOKEN, token: responseToken.token, tokenExpiresIn: responseToken.expires_in, tokenDuration: responseToken.total_duration
      });
    } catch (e) {
      dispatch(somethingWentWrong(e));
    }
  };
}

export function getAgentTools (id) {
  return async (dispatch) => {
    try {
      dispatch(fetching());
      const response = await api.agents.fetchAgentTools(id);
      dispatch({ type: types.AGENTS_GET_TOOLS, id, tools: response.executors });
    } catch (e) {
      dispatch(somethingWentWrong());
    }
  };
}

export function runUserAgentTool (toolName, toolParams) {
  return async (dispatch, getState) => {
    try {
      const agentId = selectTriggerAgentId(getState());
      const workspaces = selectTriggerAgentWs(getState()).map((ws) => ws.name);
      dispatch(fetching());
      const payload = {
        workspaces_names: workspaces,
        executor_data: {
          agent_id: agentId,
          executor: toolName,
          args: toolParams
        }
      };
      await api.agents.runUserAgentTool(agentId, payload);

      dispatch(getUserAgents());
      dispatch(closeModal(MODAL_RUN_USER_AGENT));
      dispatch({ type: types.AGENTS_SUCCESS });
    } catch (e) {
      dispatch(somethingWentWrong(e));
    }
  };
}

export function resetWizard () {
  return async (dispatch) => {
    dispatch({ type: types.AGENTS_CREATE_WIZARD_RESET });
  };
}

export function addTool (toolName) {
  return async (dispatch) => {
    dispatch({ type: types.AGENTS_CREATE_WIZARD_ADD_TOOL, tool: { name: toolName, variables: [] } });
  };
}

export function removeTool (tool) {
  return async (dispatch) => {
    dispatch({ type: types.AGENTS_CREATE_WIZARD_REMOVE_TOOL, tool });
  };
}

export function setFieldsTool (toolName, fields) {
  return async (dispatch) => {
    dispatch({ type: types.AGENTS_CREATE_WIZARD_SET_FIELDS_TOOL, toolName, fields });
  };
}

export function getManifest () {
  return async (dispatch) => {
    try {
      dispatch(fetching());
      const data = await api.manage.fetchManifest();
      dispatch({ type: types.AGENTS_GET_MANIFEST, data });
    } catch (e) {
      dispatch(somethingWentWrong(e));
    }
  };
}

export const setAgent = (data) => (dispatch) => {
  dispatch({ type: types.SET_NEW_AGENT, data });
};

export function clearAgent () {
  return (dispatch) => {
    dispatch({ type: types.RESET_NEW_AGENT });
  };
}

export function setTriggerAgentWs (workspaces) {
  return (dispatch) => {
    dispatch({ type: types.AGENTS_RUN_SET_WORKSPACES, workspaces });
  };
}

export function addTriggerAgentWs (ws) {
  return (dispatch, getState) => {
    const addedWorkspaces = selectTriggerAgentWs(getState());
    const allWorkspaces = selectActiveWorkspaces(getState());

    const newWs = allWorkspaces.find((workspace) => workspace.id === ws.id);
    if (newWs) {
      const newWorkspaces = [
        ...addedWorkspaces.filter((workspace) => workspace.id !== ws.id),
        newWs
      ];

      dispatch(setTriggerAgentWs(newWorkspaces));
    }
  };
}

export function removeTriggerAgentWs (ws) {
  return (dispatch, getState) => {
    const addedWorkspaces = selectTriggerAgentWs(getState());
    const newWorkspaces = addedWorkspaces.filter((w) => w.id !== ws.id);
    dispatch(setTriggerAgentWs(newWorkspaces));
  };
}
