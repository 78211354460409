import React from 'react';
import checkBox from 'Images/Checkbox_empty.svg';
import matchField from '../../helper';
import { ToAddColumn, Wrapper } from './styled';
import { Icon } from '../../styled';

const NotIncludedList = ({ fields, setFieldVisibility }) => {
  const columnsNotIncluded = Object.keys(fields).filter((x) => fields[x].visible === false).sort();
  return (
    <Wrapper>
      {columnsNotIncluded.map((field) => (
        <ToAddColumn onClick={ (e) => { e.stopPropagation(); setFieldVisibility(field, true); } } key={ `context_menu_add_remove_column_${field}` } aria-label="Add Column" role="button" onKeyDown={ () => {} } type="button" tabIndex="0">
          <p>{matchField(field)}</p>
          <Icon src={ checkBox } />
        </ToAddColumn>
      ))}
    </Wrapper>
  );
};

export default NotIncludedList;
