/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomReactTable from 'Common/Components/CustomReactTable/styled';
import handleLeftClick from 'Common/Components/CustomReactTable/leftClickHelper';
import {
  ContextMenuTrigger
} from 'react-contextmenu';
import { connect } from 'react-redux';
import {
  vulnTemplateSelected, getData, sortData, showVulnTemplateModalEdit
} from 'Screens/KnowledgeBase/actions/Actions';
import { selectKnowledgeBaseTableProps } from 'store/KnowledgeBase/selectors';
import getColumns from './Columns';

class Table extends Component {
  constructor (props) {
    super(props);
    this.table = React.createRef();
    this.tableBody = React.createRef();
    this.fetchData = this.fetchData.bind(this);
    this.getTrProps = this.getTrProps.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick (e, rowInfo) {
    const {
      data, templatesSelected, lastTemplateSelected, lastIndexForRange, lastSelected
    } = this.props;
    const selected = templatesSelected.filter((x) => x.id === rowInfo.original.id).length > 0;

    const leftClickResponse = handleLeftClick(e, rowInfo, '', selected, data, templatesSelected, lastTemplateSelected, lastIndexForRange, lastSelected);
    if (leftClickResponse) this.props.vulnTemplateSelected(leftClickResponse);
  }

  getTrProps (state, rowInfo) {
    if (rowInfo && rowInfo.row && !rowInfo.original.count) {
      const selected = this.props.templatesSelected.filter((x) => x.id === rowInfo.original.id).length > 0;

      return {
        onClick: (e) => {
          this.handleClick(e, rowInfo);
          e.stopPropagation();
          return true;
        },
        onDoubleClick: (e) => {
          e.stopPropagation();
          if (rowInfo.original._id || rowInfo.original.id) {
            this.props.vulnTemplateSelected({ newRowsSelected: [rowInfo.original] });
            this.props.showVulnTemplateModalEdit();
          }
        },
        onContextMenu: () => {
          if (!selected) this.props.vulnTemplateSelected({ newRowsSelected: [rowInfo.original] });
          return false;
        },
        style: {
          background: selected ? '#e2f0fb' : 'transparent'
        }
      };
    }

    return {};
  }

  fetchData (state) {
    const {
      isFetching
    } = this.props;

    if (!isFetching) {
      this.props.sortData(state.sorted);

      if (this.tableBody.scrollTo) {
        this.tableBody.scrollTo(0, 0);
      }
    }
  }

  render () {
    const {
      data,
      isFetching
    } = this.props;

    const mockData = [...Array(13).keys()];

    return (
      <div className="h-100 w-100" ref={ (element) => { this.table = element; } }>
        <ContextMenuTrigger id="massive_update_kb_table" holdToDisplay={ 1000000 }>
          <CustomReactTable
            data={ isFetching ? mockData : data }
            columns={ getColumns(isFetching) }
            minRows={ 0 }
            page={ 1 }
            manual
            onFetchData={ this.fetchData }
            getTrProps={ this.getTrProps }
            showPagination={ false }
          />
        </ContextMenuTrigger>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...selectKnowledgeBaseTableProps(state)
});

const mapDispatchToProps = (dispatch) => ({
  vulnTemplateSelected: (selectionInfo) => {
    dispatch(vulnTemplateSelected(selectionInfo));
  },
  showVulnTemplateModalEdit: () => {
    dispatch(showVulnTemplateModalEdit());
  },
  getData: () => {
    dispatch(getData());
  },
  sortData: (sorting) => {
    dispatch(sortData(sorting));
  }
});

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFetching: PropTypes.bool.isRequired,
  lastIndexForRange: PropTypes.number.isRequired,
  lastSelected: PropTypes.number.isRequired,
  lastTemplateSelected: PropTypes.arrayOf(PropTypes.object).isRequired,
  showVulnTemplateModalEdit: PropTypes.func.isRequired,
  sortData: PropTypes.func.isRequired,
  templatesSelected: PropTypes.arrayOf(PropTypes.object).isRequired,
  vulnTemplateSelected: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
