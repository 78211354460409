import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  height: 45px;
  padding-bottom: 10px;
  background: #fff;
  position: fixed;
  left: 0;
  right: 0;
  top: 50%;
  background: transparent;
`;
Wrapper.displayName = 'Wrapper';
