import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as _modalIcon } from 'Images/new_vuln_modal_icon.svg';
import { ReactComponent as NextSVG } from 'Images/next_arrow.svg';
import { Accept } from 'Common/Components/Button/styled';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  width: 350px;
  height: 32px;
  font-size: 21.5px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.49;
  letter-spacing: normal;
  color: ${colors.blue9};
`;
Title.displayName = 'Title';

export const ModalIcon = styled(_modalIcon)`
  cursor: default;
`;

export const NextIcon = styled(NextSVG)`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;
NextIcon.displayName = 'NextIcon';

export const ButtonWrapper = styled.div``;

export const CreateButton = styled(Accept)`
  width: 194px;
  margin-left: 10px;
  margin-right: 34px;
  user-select: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  background-color: ${({ disabled }) => (disabled ? colors.blue12 : colors.blueCerulean)};
  opacity:1;

  & span {
    padding: 0 55px;
  }

  & svg {
    margin-bottom: 2px;
  }

`;
CreateButton.displayName = 'CreateButton';
