// import asyncRoute from '../asyncRoute';
import LogOutForced from 'Common/Components/LogOutForced/index';
import Wizard from 'Screens/Wizard/components/Main';
import ForgotPassword from 'Screens/ForgotPassword';
import ResetPassword from 'Screens/ResetPassword';
import Agents from 'Screens/Automation/Agents';
import Host from 'Screens/Host';
import Dashboard from 'Screens/Dashboard/components/Dashboard/index';
import NotFound from '../Screens/Errores/NotFoundContainer'; // 404
import Login from '../Screens/Login/components/Login';
import KnowledgeBase from '../Screens/KnowledgeBase/components';
import Manage from '../Screens/Manage';
import Workspace from '../Screens/Workspaces/components/WorkspaceContainer';

const ApplicationRoutes = {
  Routes: [
    {
      path: '/automation/agents', exact: true, component: Agents, isPrivate: true, requireWorkspace: false
    },
    {
      path: '/login', exact: true, component: Login, isPrivate: false
    },
    {
      path: '/forgotpass', exact: true, component: ForgotPassword, isPrivate: false
    },
    {
      path: '/resetpass/:hash', exact: true, component: ResetPassword, isPrivate: false
    },
    {
      path: '/', exact: true, component: Workspace, isPrivate: true
    },
    {
      path: '/knowledge_base', exact: true, component: KnowledgeBase, isPrivate: true, requireWorkspace: false
    },
    {
      path: '/manage/:ws/:id?', exact: false, component: Manage, isPrivate: true, requireWorkspace: true
    },
    {
      path: '/host/:ws/:id?', exact: true, component: Host, isPrivate: true, requireWorkspace: true
    },
    {
      path: '/workspaces', exact: true, component: Workspace, isPrivate: true
    },
    {
      path: '/feed/:ws', exact: true, component: Dashboard, isPrivate: true, requireWorkspace: true, edit: false
    },
    {
      path: '/wizard', exact: true, component: Wizard, isPrivate: true, requireWorkspace: false
    },
    {
      path: '/logout', exact: true, component: LogOutForced, isPrivate: true, requireWorkspace: false, edit: false
    },
    {
      path: '/404', exact: true, component: NotFound, isPrivate: false, requireWorkspace: false, edit: false
    },
    { component: NotFound }
  ]
};

export default ApplicationRoutes;
