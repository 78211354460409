import React from 'react';
import Vulnerabilities from 'Screens/Dashboard/components/ToolbarItems/Vulnerabilities';
import TopServices from 'Screens/Dashboard/components/ToolbarItems/TopServices';
import TopHosts from 'Screens/Dashboard/components/ToolbarItems/TopHosts';
import Summary from 'Screens/Dashboard/components/ToolbarItems/Summary';
import ActivityFeed from 'Screens/Dashboard/components/ToolbarItems/ActivityFeed';
import LatestVulns from 'Screens/Dashboard/components/ToolbarItems/LatestVulns';
import StatusChart from 'Screens/Dashboard/components/ToolbarItems/StatusChart';
import { DarkBox, Box } from 'Screens/Dashboard/components/ToolbarItems/Common/Box/styled';
import { StyledRow, StyledCol } from './styled';

const Layout = () => (
  <StyledRow direction="row" marginBottom marginRight>

    <StyledCol width="25%">
      <ActivityFeed />
    </StyledCol>

    <StyledCol marginBottom width="45%">
      <StyledCol>
        <DarkBox>
          <Vulnerabilities />
          <Summary />
        </DarkBox>
      </StyledCol>

      <StyledCol>
        <Box>
          <LatestVulns />
        </Box>
      </StyledCol>

      <StyledRow marginRight>
        <StyledCol width="calc(55% - 14px)">
          <Box>
            <TopHosts />
          </Box>
        </StyledCol>
        <StyledCol width="45%">
          <Box>
            <TopServices />
          </Box>
        </StyledCol>
      </StyledRow>
    </StyledCol>

    <StyledCol marginBottom width="30%">
      <StyledCol>
        <Box>
          <StatusChart />
        </Box>
      </StyledCol>
    </StyledCol>

  </StyledRow>
);

export default Layout;
