import api from 'services/api';
import { refreshDashboard } from 'Screens/Dashboard/actions/Actions';
import { getWorkspaces } from 'Screens/Workspaces/actions/Actions';
import { newGetVulns } from 'store/Manage/filterActions';

export const HIDE_FILE_UPLOAD_CONTEXT = 'HIDE_FILE_UPLOAD_CONTEXT';
export const SET_BODY_VISIBILITY_FILE_UPLOAD_CONTEXT = 'SET_BODY_VISIBILITY_FILE_UPLOAD_CONTEXT';
export const ADD_COMMAND_IDS = 'ADD_COMMAND_IDS';
export const UPDATE_COMMAND_LIST_STATUS = 'UPDATE_COMMAND_LIST_STATUS';

export function hideUploadContextMenu () {
  return (dispatch) => {
    dispatch({ type: HIDE_FILE_UPLOAD_CONTEXT });
  };
}

export function setVisibilityBody (value) {
  return (dispatch) => {
    dispatch({ type: SET_BODY_VISIBILITY_FILE_UPLOAD_CONTEXT, value });
  };
}

export function updateCommandsStatus () {
  return async (dispatch, getState) => {
    try {
      const { commandsInProcess } = getState().fileUploaderContext;
      const { workspaceSelected } = getState().faraday;
      let commandListUpdated = commandsInProcess;

      const promises = commandsInProcess.filter((com) => !com.finished).map((command) => api.workspace.getReportStatus(workspaceSelected, command.command_id));
      const results = await Promise.all(promises);

      commandListUpdated = commandListUpdated.map((command) => {
        const commandUpdated = command;

        if (!command.finished) {
          const commandStatus = results.filter((commandResponse) => commandResponse._id === command.command_id)[0];
          commandUpdated.finished = !!commandStatus.duration;
        }
        return commandUpdated;
      });

      if (promises.length === 0) {
        if (window.location.href.includes('/manage')) dispatch(newGetVulns());
        else if (window.location.href.includes('/feed')) dispatch(refreshDashboard());

        dispatch(getWorkspaces());
      }

      return dispatch({ type: UPDATE_COMMAND_LIST_STATUS, data: commandListUpdated });
    } catch (error) {
      return dispatch({ type: '', error });
    }
  };
}
