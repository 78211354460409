import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 34px;
  align-items: center;
  & > :not(:last-child) {
    margin-right: 5px;
  }
`;
Wrapper.displayName = 'Wrapper';

export const InputTitle = styled.input`
  padding-right: 10px;
  margin: auto 5px auto 0px;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 17px;
  font-weight: 600;
  color: #1C2243;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: transparent;

  &:focus {
    cursor: text;
    color: #264D6D;
  }

  &:not(:focus) {
    &:hover {
      color: #264D6D;
      background-color: #EDF2F7;
    }
  }

`;
InputTitle.displayName = 'InputTitle';

export const DuplicateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 25px;
  border-radius: 6px;
  background-color: #bec8d2;
  padding: 3px 8px;
  margin-right: 10px;
`;
DuplicateWrapper.displayName = 'DuplicateWrapper';
