import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentImpacts, selectVulnDetail } from 'store/Manage/selectors';
import { updateVuln } from 'store/Manage/actions';
import Impacts from 'Common/Components/Impacts';
import ExpandableWrapper from '../ExpandableWrapper';
import Wrapper from './styled';

const ImpactsTab = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const initialValue = useSelector(selectCurrentImpacts);
  const currentVuln = useSelector(selectVulnDetail);

  const [impacts, setImpacts] = useState(initialValue);

  useEffect(() => {
    setImpacts(initialValue);
  }, [initialValue]);

  const onSelectCheckbox = (impactChanged) => {
    const newImpacts = { ...impacts, [impactChanged]: !impacts[impactChanged] };
    setImpacts(newImpacts);
    dispatch(updateVuln(currentVuln, 'impact', newImpacts));
  };

  return (
    <ExpandableWrapper id="impact" title={ intl.formatMessage({ id: 'manage.detail.tab.general.impact' }) }>
      <Wrapper>
        <Impacts
          selectCheckbox={ onSelectCheckbox }
          hideTitle
          impacts={ impacts }
        />
      </Wrapper>
    </ExpandableWrapper>
  );
};

export default ImpactsTab;
