import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/modals/actions';
import {
  Wrapper, Content, Description, BoldDescription, FeatureWrapper, FeatureImage, CloseIcon, TitleWrapper, TitleBold
} from './styled';
import BlueButton from '../BlueButton';

export const URL_PRICING = 'https://faradaysec.com/pricing/';

const UpgradeLicense = ({ id, image, description }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const onClick = () => window.open(URL_PRICING);
  const close = () => dispatch(closeModal(id));

  if (!id || !image || !description) return null;
  return (
    <Wrapper>
      <CloseIcon onClick={ close } />
      <FeatureWrapper>
        <FeatureImage src={ image } />
      </FeatureWrapper>
      <Content>
        <TitleWrapper>
          <FormattedMessage id="upgradeLicense.titleForMoreFeatures" values={ { b: (chunks) => <TitleBold children={ chunks } /> } } />
        </TitleWrapper>
        <Description>
          <FormattedMessage
            id={ description }
            values={ {
              b: (chunks) => <BoldDescription children={ chunks } />,
              br: <br />
            } }
          />
        </Description>
        <BlueButton label={ intl.formatMessage({ id: 'upgradeLicense.upgradeNow' }) } onClick={ onClick } />
      </Content>
    </Wrapper>
  );
};

export default UpgradeLicense;
