import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;
Wrapper.displayName = 'Wrapper';

export const Tabs = styled.div`
  background: #ebeff3;
  width: 184px;
  display: inline-block;
  height: 100%;
`;
Tabs.displayName = 'Tabs';

export const TabGroup = styled.div`
`;
TabGroup.displayName = 'TabGroup';

export const Subtitle = styled.div`
  margin: 20px 0 10px 0;
  font-size: 11.5px;
  font-weight: normal;
  line-height: 1.22;
  text-align: left;
  color: #7a7e8f;
  padding-left: 20px;
`;
Subtitle.displayName = 'Subtitle';

export const Title = styled.div`
  font-size: 21.5px;
  font-weight: 500;
  line-height: 1.16;
  text-align: left;
  color: ${colors.softBlue};
  padding: 40px 20px 0px 20px;
`;
Title.displayName = 'Title';

export const Item = styled.div`
  display:flex;
  align-items: center;
  cursor: pointer;
  font-size: 13.5px;
  font-weight: ${(props) => (props.active ? '600' : 'normal')} ;
  line-height: 1.04;
  text-align: left;
  padding: 10px 20px;
  background: ${(props) => (props.active ? colors.grey12 : 'transparent')};
  
  & svg {
    margin-right: 6px;
  }
  `;
Item.displayName = 'Item';

export const TabContent = styled.div`
  padding: 35px;
  width: 100%;
  display: inline-block;
  overflow: auto;
`;
TabContent.displayName = 'TabContent';
