import styled from 'styled-components';
import { navMenuButton } from 'Styles/styles';
import { ReactComponent as EditIcon } from 'Images/icon-action-bar-edit.svg';
import { ReactComponent as TrashIcon } from 'Images/icon-action-bar-trash.svg';

export const Edit = styled(EditIcon)`
    ${navMenuButton};
`;

Edit.displayName = 'Edit';

export const Trash = styled(TrashIcon)`
 ${navMenuButton};
`;

Trash.displayName = 'Trash';

export const TableWrapper = styled.div`
    margin-top: 13px;
    margin-right: -8px;
    margin-left: -8px;
    width: 100%;
    height: ${(props) => props.height};
`;
TableWrapper.displayName = 'TableWrapper';

export const Wrapper = styled.div`
  height: 100%;
`;
Wrapper.displayName = 'Wrapper';
