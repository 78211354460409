import React, { useEffect } from 'react';
import { Col, Row } from 'Common/Components/Grid';
import { getActivityFeed } from 'Screens/Dashboard/actions/Actions';
import { selectActiveFeeds } from 'store/Dashboard/selectors';
import get from 'lodash/get';
import find from 'lodash/find';
import { DASHBOARD_FILTERS } from 'store/Filters/constants';
import { setFilter } from 'store/Filters/actions';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { redirect } from 'store/Router/actions';
import DateFormatter from 'Common/Components/DateFormatter';
import { useDispatch, useSelector } from 'react-redux';
import {
  Author, Vulnerabilities, HostsAndServices, StyledCol, Tag, Time, Title, Wrapper, Empty, Tool,
  WrapperActivity, ActivityDescription, ActivityFooter, AgentIcon, ReportIcon, Dark, Light
} from './styled';

const ActivityFeed = () => {
  const data = useSelector(selectActiveFeeds);
  const workspaceSelected = useSelector(selectCurrentWorkspace);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getActivityFeed());
  }, [dispatch, workspaceSelected]);

  const onClick = (command) => {
    dispatch(setFilter('vulns', DASHBOARD_FILTERS.commandIdFilter(command.toString())));
    dispatch(redirect(`/manage/${workspaceSelected}`));
  };

  let content = <div />;

  if (get(data, 'activities.length', 0) > 0) {
    const elements = data.activities.filter((x) => x.hosts_count > 0 || x.services_count > 0 || x.vulnerabilities_count > 0).slice(0, 5);

    content = elements.map((activity, index) => {
      const isAgent = activity.import_source === 'agent';
      const isReport = activity.import_source === 'report';
      let author = activity.creator;
      let fileName = activity.params;

      if (isAgent) {
        author = activity.command;
      } else if (isReport) {
        author = activity.creator;
        const index = activity.params.indexOf('_');
        fileName = activity.params.slice(index + 1);
      }

      const severity = find([
        { name: 'critical', value: activity.criticalIssue },
        { name: 'high', value: activity.highIssue },
        { name: 'med', value: activity.mediumIssue },
        { name: 'low', value: activity.lowIssue },
        { name: 'info', value: activity.infoIssue }
      ], (serverity) => serverity.value > 0);

      return (
        <WrapperActivity onClick={ () => onClick(activity._id) } key={ `dashboard_activity_feed_${index}` } severity={ get(severity, 'name', '') }>
          <ActivityDescription>
            <Vulnerabilities>
              { `${activity.vulnerabilities_count} Vulnerabilities.` }
            </Vulnerabilities>
            <HostsAndServices>
              { activity.hosts_count ? `${activity.hosts_count} Hosts,` : 'No Hosts,' }
              { activity.services_count ? ` ${activity.services_count} Services.` : ' no Services.' }
            </HostsAndServices>
          </ActivityDescription>
          { isAgent && (
          <Author>
            <Light>Triggered by: </Light>
            <Dark>{ author }</Dark>
          </Author>
          )}
          { isReport && (
          <Author>
            <Dark>{ author }</Dark>
            <Light>imported </Light>
            <Dark>{ fileName }</Dark>
          </Author>
          ) }
          <ActivityFooter>
            <Time><DateFormatter date={ activity.date } /></Time>
            <Tag>
              { isAgent && <AgentIcon /> }
              { isReport && <ReportIcon /> }
              <Tool>{ activity.tool }</Tool>
            </Tag>
          </ActivityFooter>
        </WrapperActivity>
      );
    });
  } else {
    content = <Empty>No feeds has been found yet.</Empty>;
  }

  return (
    <Wrapper>
      <Row>
        <Col><Title>Activity Feed</Title></Col>
      </Row>
      <StyledCol>
        {content}
      </StyledCol>
    </Wrapper>
  );
};

export default ActivityFeed;
