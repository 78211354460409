import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import StringField from 'Common/Components/StringField';
import {
  setField,
  addPolicy,
  removePolicy
} from 'Screens/KnowledgeBaseEditCreate/actions/Actions';
import {
  selectTemplateEditCreate,
  selectPermissions,
  selectReadonly
} from 'store/KnowledgeBase/selectors';
import SeverityDropdown from 'Common/Components/SeverityDropdown';
import EORDropdown from 'Common/Components/EORDropdown';
import CustomList from 'Common/Components/CustomList';
import Impacts from 'Common/Components/Impacts';
import Md from 'Common/Components/Md';
import {
  Wrapper, DropdownsContainer, RedDot, Black, Row, PolicyColumn
} from './styled';

const General = () => {
  const dispatch = useDispatch();
  const props = useSelector((state) => ({ ...selectReadonly(state), ...selectPermissions(state), ...selectTemplateEditCreate(state) }));

  const saveData = (field, value) => {
    dispatch(setField(field, value));
  };

  const impacts = {
    accountability: props.accountability,
    availability: props.availability,
    confidentiality: props.confidentiality,
    integrity: props.integrity
  };

  return (
    <Wrapper>
      <Row className="ml-0 mr-0">
        <StringField
          className="mr-17"
          width="405px"
          height="38px"
          placeholder="Vulnerability template name"
          defaultValue={ props.name }
          onChange={ (value) => saveData('name', value) }
          title={ (
            <RedDot>
              <Black>Name</Black>
              {' '}
              *
            </RedDot>
) }
        />
        <DropdownsContainer>
          <SeverityDropdown updateValue={ (field, value) => saveData('exploitation', value) } defaultValue={ props.exploitation } />
          <EORDropdown updateValue={ (field, value) => saveData('easeofresolution', value) } defaultValue={ props.easeofresolution } />
        </DropdownsContainer>
      </Row>

      <Row className="ml-0 mr-0 margin-top-26">
        <div className="margin-right-16 height-264 description">
          <Md title="Description" saveAction={ (value) => saveData('description', value) } value={ props.description } />
        </div>

        <div className="height-264 resolution">
          <Md title="Resolution" saveAction={ (value) => saveData('resolution', value) } value={ props.resolution } />
        </div>
      </Row>

      <Row className="ml-0 mr-0 margin-top-26">
        <PolicyColumn>
          <CustomList
            key="CL_POLICY_VIOLATIONS"
            title="Policy Violations"
            placeholder="Add a Policy Violation"
            addItem={ () => dispatch(addPolicy()) }
            removeItem={ (index) => dispatch(removePolicy(index)) }
            setItem={ (value) => saveData('policy', value) }
            listData={ props.policyviolations }
          />
        </PolicyColumn>
        <>
          <Impacts
            selectCheckbox={ (field) => saveData(field, !props[field]) }
            impacts={ impacts }
            margin={ false }
          />
        </>
      </Row>
    </Wrapper>
  );
};

export default withRouter(General);
