/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomTextArea from 'Common/Components/CustomTextArea';
import PropTypes from 'prop-types';
import { setField } from 'Screens/ManageEditCreate/actions/Actions';
import {
  selectManageEditCreate,
  selectReadonly,
  selectPermissions
} from 'store/ManageEditCreate/selectors';
import MarkdownInput from '../MarkdownInput';
import { Wrapper, Row, Data } from './styled';

class TechnicalDetails extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};

    this.setRequest = this.setRequest.bind(this);
    this.setResponse = this.setResponse.bind(this);
    this.saveData = this.saveData.bind(this);
  }

  setRequest (value) {
    this.props.setField('request', value);
  }

  setResponse (value) {
    this.props.setField('response', value);
  }

  saveData (value) {
    this.props.setField('data', value);
  }

  render () {
    return (
      <Wrapper>
        <Row className="ml-0 mr-0">
          <Data className="data">
            <MarkdownInput
              title="Data"
              value={ this.props.manageEditCreate.data }
              onBlur={ this.saveData }
            />
          </Data>
          <Wrapper>
            <CustomTextArea title="Request" updateValue={ this.setRequest } field="request" />
            <CustomTextArea title="Response" updateValue={ this.setResponse } field="response" />
          </Wrapper>
        </Row>
      </Wrapper>
    );
  }
}

TechnicalDetails.propTypes = {
  setField: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  setField: (fieldName, value) => {
    dispatch(setField(fieldName, value));
  }
});

const mapStateToProps = (state) => ({
  manageEditCreate: selectManageEditCreate(state),
  readonly: selectReadonly(state),
  permissions: selectPermissions(state)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TechnicalDetails));
