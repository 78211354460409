import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-right: 17px;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #0b2128;;
  user-select: none;
  margin-bottom: 12px;
  ${(props) => props.isRequired && `
    &:after {
      content: '*';
      color: ${colors.redPink};
    }
  `};
`;
Title.displayName = 'Title';
