import { Accept } from 'Common/Components/Button/styled';
import Password from 'Common/Components/Password/styled';
import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  border-bottom: 1px;
  border-bottom-style: solid;
  border-bottom-color: #e9e9e9;
  padding-bottom: 34px;
  flex: 1 1 0;
  text-align: initial;
  ${Accept} {
    margin: 10px 0;
  }
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  margin: 27px 0 19px 0;
  font-size: 14.5px;
  font-weight: 500;
  line-height: 0.97;
  text-align: left;
  color: ${colors.dark2};
`;
Title.displayName = 'Title';

export const Label = styled.div`
  font-size: 12.5px;
  line-height: 1.12;
  text-align: left;
  color: ${colors.grey17};
`;
Label.displayName = 'Label';

export const PasswordSection = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
PasswordSection.displayName = 'PasswordSection';

export const CustomPassword = styled(Password)`
  max-width: 264px;
  width:100%;
  margin-right: 21px;
`;
CustomPassword.displayName = 'CustomPassword';

export const Rule = styled.div`
  font-size: 10.5px;
  color: ${colors.red1};
  margin-top: 12px;
  text-align: justify;
`;
Rule.displayName = 'Rule';
