/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-empty */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import AddButton from 'Common/Components/AddButton';

class CreateActions extends React.Component {
  constructor (props) {
    super(props);
    this.state = { show: false };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidUpdate () {
    if (this.props.showModalCreate !== this.state.show) this.setState({ show: this.props.showModalCreate });
  }

  handleShow () {
    this.props.setVisibilityCreateModal(true);
  }

  handleClose () {
    this.props.setVisibilityCreateModal(false);
  }

  render () {
    return (
      <AddButton onClick={ this.handleShow } title={ <FormattedMessage id="host.create" /> } text={ <FormattedMessage id="host.create" /> } />
    );
  }
}

export default CreateActions;
