import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CustomFieldContainer from 'Screens/Manage/components/VulnerabilityDetail/components/Tabs/components/CustomFieldsTab/components/CustomFieldContainer/index';
import CustomFieldContent from 'Screens/Manage/components/VulnerabilityDetail/components/Tabs/components/CustomFieldsTab/components/CustomFieldContent/index';
import { CUSTOM_ATTRIBUTE_TYPES as types } from 'Screens/Manage/components/CustomAttributesModal/constants';
import { selectVulnDetail } from 'store/Manage/selectors';
import getCustomFieldValueFromVuln from 'Screens/Manage/components/VulnerabilityDetail/components/Tabs/components/CustomFieldsTab/functions';
import toString from 'lodash/toString';
import isInteger from 'lodash/isInteger';
import trim from 'lodash/trim';
import { selectField } from 'store/ManageEditCreate/selectors';

const CustomField = ({ field }) => {
  const [showContent, setShowContent] = useState(false);

  const editValue = useSelector((state) => selectField(state, field));
  const vuln = useSelector(selectVulnDetail);
  const initialValue = vuln ? getCustomFieldValueFromVuln(vuln, field) : editValue;
  const onShowContent = () => setShowContent(true);
  const onHideContent = () => setShowContent(false);

  const onSaveCallback = () => {
    onShowContent(!!initialValue || isInteger(initialValue));
  };

  useEffect(() => {
    setShowContent(!!(trim(toString(initialValue))) || isInteger(initialValue));
  }, [field, initialValue]);

  return (
    <>
      <CustomFieldContainer key={ field.field_name } field={ field } onShow={ onShowContent } showContent={ showContent }>
        <CustomFieldContent vuln={ vuln } field={ field } onCancel={ onHideContent } initialValue={ initialValue } onSaveCallback={ onSaveCallback } />
      </CustomFieldContainer>
    </>
  );
};

CustomField.propTypes = {
  field: PropTypes.shape({
    field_display_name: PropTypes.string,
    field_type: PropTypes.oneOf([types.STRING, types.INTEGER, types.LIST,
      types.MARKDOWN, types.CHOICE]).isRequired,
    field_metadata: PropTypes.string
  }).isRequired
};

export default CustomField;
