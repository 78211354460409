import colors from 'Styles/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.label`
  user-select: none;
  color: ${colors.dark2};
  font-size: 14px;
  font-weight: 500;
  opacity: 1;
`;
Title.displayName = 'Title';

export const Empty = styled.div`
  user-select: none;
  color: ${colors.grey5};
  display: inline;
  font-size: 13px;
  font-weight: 300;
  line-height: 2.62;
`;
Empty.displayName = 'Empty';
