/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { setStep, resetSummary } from 'Screens/Wizard/actions/Actions';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import {
  FILE_SELECTOR
} from 'Screens/Wizard/constants';
import {
  Title,
  SubTitle,
  Button
} from 'Common/Components/MainContainer/styled';
import { FormattedMessage } from 'react-intl';
import { redirect } from 'store/Router/actions';
import {
  Wrapper,
  BlueText,
  SummaryContainer,
  SummaryItem,
  ButtonWithoutBackgroundRight,
  ButtonsContainerCustom
} from './styled';

class StepSummary extends Component {
  constructor (props) {
    super(props);
    this.state = {};
    this.redirect = this.redirect.bind(this);
  }

  redirect () {
    this.props.redirect(`/feed/${this.props.workspaceName}`);
    this.props.resetSummary();
  }

  render () {
    const {
      filename,
      vulnCount,
      hostsCount,
      servicesCount
    } = this.props.summary;
    const { setStepWizard, workspaceName } = this.props;
    return (
      <Wrapper>
        <Title><FormattedMessage id="wizard.steps.summary.title" /></Title>
        <SubTitle>
          The file
          <BlueText>
            { ` ${filename} ` }
          </BlueText>
          has been fully processed.
        </SubTitle>

        <SummaryContainer>
          <SummaryItem>
            <BlueText>
              { ` ${vulnCount} ` }
            </BlueText>
            <FormattedMessage id="wizard.steps.summary.vulns" />
          </SummaryItem>

          <SummaryItem>
            <BlueText>
              { ` ${hostsCount} ` }
            </BlueText>
            <FormattedMessage id="wizard.steps.summary.assets" />
          </SummaryItem>

          <SummaryItem>
            <BlueText>
              { ` ${servicesCount} ` }
            </BlueText>
            <FormattedMessage id="wizard.steps.summary.services" />
          </SummaryItem>

          <SubTitle>{`Have been added to ${workspaceName}`}</SubTitle>
        </SummaryContainer>
        <ButtonsContainerCustom>
          <Button onClick={ () => this.redirect() }>Next</Button>
          <ButtonWithoutBackgroundRight onClick={ () => setStepWizard(FILE_SELECTOR) }>Upload another file</ButtonWithoutBackgroundRight>
        </ButtonsContainerCustom>
      </Wrapper>
    );
  }
}

StepSummary.propTypes = {
  summary: PropTypes.object.isRequired,
  workspaceName: PropTypes.string.isRequired,
  setStepWizard: PropTypes.func.isRequired,
  resetSummary: PropTypes.func.isRequired
};

StepSummary.defaultProps = {

};

const mapStateToProps = (state) => ({
  summary: state.wizard.summary,
  workspaceName: selectCurrentWorkspace(state)
});

const mapDispatchToProps = (dispatch) => ({
  setStepWizard: (step, width, height) => {
    dispatch(setStep(step, width, height));
  },

  resetSummary: () => {
    dispatch(resetSummary());
  },
  redirect: (pathname) => {
    dispatch(redirect(pathname));
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StepSummary));
