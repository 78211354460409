import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import StringField from 'Common/Components/StringField';
import CustomTextArea from 'Common/Components/CustomTextArea';
import { cleanDates, createUpdateWorkspace, resetCreationState } from 'Screens/Workspaces/actions/Actions';
import DateRangePicker from 'Common/Components/DateRangePicket';
import {
  selectWsEditCreateName, selectWsEditCreateDescription, selectWsEditCreateUsers,
  selectWsEditCreateStart, selectWsEditCreateEnd, selectWsEditCreateLocale, selectWsEditCreateErrorMessage,
  selectWsEditCreateId,
  selectWorkspaceSaved,
  selectIsFetching
} from 'store/WorkspaceEditCreate/selectors';
import BlueButton from 'Common/Components/BlueButton';
import ModalWrapper from 'Common/Components/ModalWrapper';
import {
  Modal, Title, CancelButton, Header, Body, FieldTitle, Field, FieldDescription, TextAreaContainer, FieldTitleDescription, ErrorMessage,
  TopRow
} from './styled';

const ModalCreation = ({ handleClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const name = useSelector((state) => (selectWsEditCreateName(state)));
  const description = useSelector((state) => (selectWsEditCreateDescription(state)));
  const users = useSelector((state) => (selectWsEditCreateUsers(state)));
  const start = useSelector((state) => (selectWsEditCreateStart(state)));
  const end = useSelector((state) => (selectWsEditCreateEnd(state)));
  const locale = useSelector((state) => (selectWsEditCreateLocale(state)));
  const errorMessage = useSelector((state) => (selectWsEditCreateErrorMessage(state)));
  const id = useSelector((state) => (selectWsEditCreateId(state)));
  const workspaceSaved = useSelector((state) => (selectWorkspaceSaved(state)));
  const isFetching = useSelector((state) => (selectIsFetching(state)));

  const [fields, setFields] = useState({
    name, description, users, start, end, locale, errorMessage, id
  });

  useEffect(() => () => dispatch(resetCreationState()), [dispatch]);

  useEffect(() => {
    if (workspaceSaved) {
      setFields({
        name, description, users, start, end, locale, errorMessage, id
      });
      handleClose();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceSaved]);

  const showError = () => errorMessage && errorMessage.length > 0;

  const handleChange = (field, value) => {
    setFields({ ...fields, [field]: value });
  };

  const onCloseCalendar = (startDate, endDate) => {
    setFields({
      ...fields,
      start: new Date(startDate),
      end: new Date(endDate)
    });
  };

  const onSubmit = () => {
    dispatch(createUpdateWorkspace(fields));
  };
  return (
    <ModalWrapper>
      <Modal>
        <Header>
          <Title>{id ? <FormattedMessage id="workspaces.modal.edit.title" /> : <FormattedMessage id="workspaces.modal.create.title" />}</Title>
          <CancelButton onClick={ handleClose } id="modal-ws-cancel-btn">
            <FormattedMessage id="cancel" />
          </CancelButton>
          <BlueButton id="modal-ws-accept-btn" onClick={ onSubmit } disabled={ fields.name.length === 0 } isLoading={ isFetching } label={ id ? intl.formatMessage({ id: 'edit' }) : intl.formatMessage({ id: 'create' }) } />
        </Header>
        <Body id="modalBody">
          <TopRow>
            <Field index={ 0 } id="nameSection">
              <FieldTitle children={ <FormattedMessage id="workspaces.modal.newWorkspace.name" /> } />
              <StringField id="modal-ws-name" width="100%" height="34px" placeholder={ intl.formatMessage({ id: 'workspaces.modal.newWorkspace.placeholder' }) } onChange={ (value) => handleChange('name', value) } defaultValue={ name } />
            </Field>
            <Field index={ 1 } id="dateSection">
              <FieldTitle children={ <FormattedMessage id="workspaces.modal.newWorkspace.StartAndEndDate" /> } />
              <DateRangePicker id="modal-ws-date" width="100%" height="34px" start={ start } end={ end } onClose={ onCloseCalendar } locale={ locale } cleanDates={ cleanDates } />
            </Field>
          </TopRow>

          <FieldDescription id="descriptionSection">
            <FieldTitleDescription><FormattedMessage id="workspaces.modal.newWorkspace.description" /></FieldTitleDescription>
            <TextAreaContainer><CustomTextArea id="modal-ws-description" width="440px" height="55px" title="" placeholder={ intl.formatMessage({ id: 'workspaces.modal.newWorkspace.usedFor' }) } updateValue={ (value) => handleChange('description', value) } field="" defaultValue={ description } /></TextAreaContainer>
          </FieldDescription>

          {showError() ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
        </Body>
      </Modal>
    </ModalWrapper>
  );
};

ModalCreation.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default ModalCreation;
