export const SET_HOSTS_FILTER_ERROR = 'SET_HOSTS_FILTER_ERROR';
export const GET_DATA_HOST_START = 'GET_DATA_HOST_START';
export const GET_DATA_HOST_FAIL = 'GET_DATA_HOST_FAIL';
export const GET_DATA_HOST_SUCCESS = 'GET_DATA_HOST_SUCCESS';
export const UPDATE_DATA_HOST_LIST = 'UPDATE_DATA_HOST_LIST';
export const APPEND_DATA_HOST_LIST = 'APPEND_DATA_HOST_LIST';
export const TOGGLE_MODAL_EDIT_CREATE_HOST = 'TOGGLE_MODAL_EDIT_CREATE_HOST';
export const GET_DATA_HOST_GROUP_BY_RESET = 'GET_DATA_HOST_GROUP_BY_RESET';
export const GET_DATA_HOST_GROUP_BY_EXPAND = 'GET_DATA_HOST_GROUP_BY_EXPAND';
export const GET_DATA_HOST_SEARCH = 'GET_DATA_HOST_SEARCH';
export const HOST_SELECTED = 'HOST_SELECTED';
export const HOST_UNSELECTED = 'HOST_UNSELECTED';
export const RESET_STATE_HOST = 'RESET_STATE_HOST';
export const SHOW_MODAL_DELETE_HOSTS = 'SHOW_MODAL_DELETE_HOSTS';
export const HIDE_MODAL_DELETE_HOSTS = 'HIDE_MODAL_DELETE_HOSTS';
export const DELETE_HOSTS_START = 'DELETE_HOSTS_START';
export const DELETE_HOSTS_SUCCESS = 'DELETE_HOSTS_SUCCESS';
export const DELETE_HOSTS_FAIL = 'DELETE_HOSTS_FAIL';
export const SHOW_HOST_LEFT_FILTERS = 'SHOW_HOST_LEFT_FILTERS';

export const NEW_PIVOT = 'NEW_PIVOT';
export const SELECT_ALL_HOST = 'SELECT_ALL_HOST';
export const UNSELECT_ALL_HOST = 'UNSELECT_ALL_HOST';
