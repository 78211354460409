import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectIsFetching, selectShowInactive, selectWorkspaceList, selectWorkspacesSelected
} from 'store/Workspace/selectors';
import some from 'lodash/some';
import Loader from 'Common/Components/CustomReactTable/components/Loader';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import WorkspaceItem from './components/WorkspaceItem';
import { Wrapper } from './styled';

const GridView = () => {
  const workspaceList = useSelector((state) => selectWorkspaceList(state));
  const workspacesSelected = useSelector((state) => selectWorkspacesSelected(state));
  const isFetching = useSelector((state) => selectIsFetching(state));
  const showInactive = useSelector((state) => selectShowInactive(state));
  const currentWorkspace = useSelector((state) => selectCurrentWorkspace(state));

  return (
    <Wrapper>
      { workspaceList.filter((ws) => ws.name === currentWorkspace).map((ws) => <WorkspaceItem key={ ws.id } ws={ ws } selected={ some(workspacesSelected, { name: ws.name }) } />) }
      { workspaceList.filter((ws) => ws.name !== currentWorkspace && ws.active).map((ws) => <WorkspaceItem key={ ws.id } ws={ ws } selected={ some(workspacesSelected, { name: ws.name }) } />) }
      { showInactive && workspaceList.filter((ws) => ws.name !== currentWorkspace && !ws.active).map((ws) => <WorkspaceItem key={ ws.id } ws={ ws } selected={ some(workspacesSelected, { name: ws.name }) } />) }
      <Loader isFetching={ isFetching } />
    </Wrapper>
  );
};

export default GridView;
