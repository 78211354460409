import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import MDPreview from './components/MDPreview';
import MDEditor from './components/MDEditor';

const MarkdownInput = ({
  placeholder, value, onBlur, markdown, plainText,
  setMarkdown, isEditing, setEditing, disabled
}) => {
  useEffect(() => {
    setMarkdown(value);
  }, [setMarkdown, value]);

  if (!isEditing) {
    return (
      <MDPreview
        plainText={ plainText }
        setEditing={ setEditing }
        markdown={ markdown }
        placeholder={ placeholder }
        disabled={ disabled }
      />
    );
  }

  return (
    <MDEditor
      onBlur={ onBlur }
      markdown={ markdown }
      setMarkdown={ setMarkdown }
      isEditing={ isEditing }
      setEditing={ setEditing }
      plainText={ plainText }
    />
  );
};

MarkdownInput.propTypes = {
  value: PropTypes.string,
  onBlur: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  markdown: PropTypes.string,
  setMarkdown: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  setEditing: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  plainText: PropTypes.bool
};

MarkdownInput.defaultProps = {
  value: '',
  placeholder: '',
  markdown: '',
  isEditing: false,
  disabled: false,
  plainText: false
};

export default MarkdownInput;
